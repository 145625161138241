import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonPage,
  IonItem,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Clock from '../../assets/Clock.svg';
import CreateAsset from '../../assets/CreateAsset.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import ScanButton from '../../components/ScanButton';
import { AppContext } from '../../providers/AppContext';


const MaintenanceHome: React.FC = () => {
  const { t } = useTranslation();

  const { state } = useContext(AppContext);

  const isMounted = useRef(true);

  const [data, setData] = useState<any>([]);

  const [isLoading, setIsLoading] = useState(true);

  const getData = async () => {
    isMounted.current = true; // Set isMounted to true
    const url = '/method/preflet_asset.api.get_assigned_maintenance?limit=5&start=0'

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      const res = await axios(config);
      return res?.data?.message?.maintenance_list || []
    } catch (err) {
      return []
    } finally {
      if (isMounted?.current)
        setIsLoading(false);
    }
  };


  useEffect(() => {

    (async () => {
      try {
        const data = await getData();
        setData(data);
      } catch (err) {
        console.log('Error occured when fetching maintenance');
      }
    })();

    // Cleanup function
    return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);


  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }




  return (
    <IonPage>
      <Header enableBackButton='' title="" />
      <IonContent fullscreen={true} >
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '10vw', marginRight: '10vw' }}>
          {state.permissions.maintenance_request_create &&
            <Link to="/createMainten1" style={{ textDecoration: 'none' }}>
              <section
                style={{
                  width: '24vw',
                  height: '7rem',
                  marginLeft: '5px',
                  marginRight: '5px',
                  textAlign: 'center',
                  boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
                }}
              >
                <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
                <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                  {t('Maintenance.Create')}
                </p>
              </section>
            </Link> || null}

          <Link to="/maintenanceHistory" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '24vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={Clock} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '15px', fontSize: '11px' }}>
                {t('Maintenance.History.History')}
              </p>
            </section>
          </Link>
        </div>

        <IonItem className="heading data-list" style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>{t('Maintenance.Recent')}</p>

        </IonItem>
        {isLoading &&
          [1, 2, 3, 4, 5].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>


        {data && data.length > 0 &&
          data.map((item: any, key: any) => {
            return (
              <div
                key={key}
                className="ion-margin data-list card-maintenance"
                style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px', padding: '0.5rem' }}
              >
                <div>
                  <h4
                    style={{
                      marginLeft: '0.4rem',
                      marginTop: '1.3rem',
                      color: 'rgba(29, 80, 141, 1)',
                      fontSize: 'large',
                    }}
                  >
                    {item.name}
                  </h4>
                  <p
                    style={{
                      marginLeft: '0.5rem',
                      color: 'rgba(146, 155, 171, 1)',
                      marginTop: '-0.5rem',
                      fontSize: '11px',
                      maxWidth: '200px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {t('Assets.Details.AID')} : {item.asset}
                  </p>
                  {item.target_date && (new Date(item.target_date).toLocaleDateString() < new Date().toLocaleDateString() ?
                    <div className='overdue'>overdue</div> : '')}
                </div>
                <div style={{ textAlign: 'right' }}>
                  <p style={{
                    color: 'rgba(146, 155, 171, 1)',
                    fontSize: '14px',
                  }}> {t(`Utils.${item.doctype}`)}</p>
                  <Link to={{ pathname: '/maintainanceDetail', state: item?.name, search: 'back=maintenanceHome' }}>
                    <p
                      style={{
                        color: 'rgba(29, 80, 141, 1)',
                        fontSize: '13px',
                        textTransform: 'capitalize',
                        textDecoration: 'underline'
                      }}
                    >
                      {t('Assets.Details.Details')}
                    </p>
                  </Link>
                  <p
                    style={{
                      color: 'rgba(146, 155, 171, 1)',
                      fontSize: '10px',
                    }}
                  >
                    {item.men_status}
                  </p>
                </div>
              </div>
            );
          })}
        {data && data.length > 0 ? <Link to="/maintenanceHistory" style={{ margin: 'auto', display: 'block' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}


        <IonToolbar></IonToolbar>
        {!isLoading && data.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}

        <ScanButton isAssetPage={false} />
      </IonContent>
    </IonPage>
  );
};

export default MaintenanceHome;
