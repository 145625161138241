import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonPage,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
  IonSearchbar,
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import { AppContext } from '../../providers/AppContext';

const ModelHistory: React.FC = () => {
  const isMounted = useRef(true);

  const { t } = useTranslation();
  const [data, setData] = useState<any>({
    dataSet: [],
  });

  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);
  const [searchText, setSearchText] = useState('');

  const getData = (context: any) => {
    isMounted.current = true; // Set isMounted to true
    let url = `/method/preflet_asset.apis.model937.routes.get_model937?limit=${state.maxLength}&start=${context.isFiltered ? 0 : currentPage * 5 + 0
      }`;

    // add search data if any
    if ('search' in context || searchText) {
      if (isMounted.current)
        setSearchText(context.search || '');
      if (context.search) url += `&search=${context.search || searchText}`;
    }

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res?.data && res.data?.message && res.data.message?.length > 0) {
          if (context.isFiltered) {
            const rawData = { dataSet: [], loading: false };
            rawData.dataSet = res.data.message;
            if (isMounted.current)
              setData(rawData);
          } else {
            const Data = { ...data };

            Data.dataSet.push(...res.data.message);
            if (isMounted.current)
              setData(Data);
          }
          if (isMounted.current)
            setDisableInfiniteScroll(res.data.message?.length < state.maxLength);
        } else {
          if (context.search) {
            if (isMounted.current)
              setData({ dataSet: [] });
          } else {
            const Data = { ...data };

            Data.dataSet.push(...res.data.message);
            if (isMounted.current)
              setData(Data);
          }
          if (isMounted.current)
            setDisableInfiniteScroll(true);
        }
      })
      .catch((err: any) => {
        console.log(err);
      })
      .finally(() => {
        if (isMounted.current) {

          setIsLoading(false);
          setCurrentPage(currentPage + 1);
        }
      });
  };


  useEffect(() => {
    getData({});
    // Cleanup function
    return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData({});
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  function searchNext($event: CustomEvent<void>) {
    getData({});

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const handleSearch = (value: any) => {
    getData({ search: value, isFiltered: true });
  }

  return (
    <IonPage>
      <Header enableBackButton='/model-home' title={t('Service.History')} />
      <IonContent>
        <IonSearchbar
          className="data-list"
          value={searchText}

          onIonChange={(e) => handleSearch(e.detail.value!)}
        ></IonSearchbar>
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>

        {data.dataSet?.map((item: any, key: any) => {
          return (
            <div
              key={key}
              className="ion-margin data-list"
              style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
            >
              <section style={{ display: 'flex' }}>
                <section style={{ flex: 'column' }}>
                  <h4
                    style={{
                      marginLeft: '0.4rem',
                      marginTop: '1.3rem',
                      color: 'rgba(29, 80, 141, 1)',
                      fontSize: 'large',
                    }}
                  >
                    {item.nome || item.name}
                  </h4>
                  <p
                    style={{
                      marginLeft: '0.5rem',
                      color: 'rgba(146, 155, 171, 1)',
                      marginTop: '-0.5rem',
                      fontSize: '11px',
                      maxWidth: '200px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {t('Assets.Details.AID')} : {item.asset}
                  </p>
                </section>
              </section>

              <section style={{ flex: 'column', paddingBottom: '10px' }}>
                <Link to={{ pathname: '/model-detail', state: item.name }}>
                  <p
                    style={{
                      color: 'rgba(29, 80, 141, 1)',
                      float: 'right',
                      fontSize: '13px',
                      flex: 'column',
                      marginTop: '-2.9rem',
                      paddingRight: '1rem',
                      textTransform: 'capitalize',
                      textDecoration: 'underline'
                    }}
                  >
                    {t('Assets.Details.Details')}
                  </p>
                </Link>
              </section>
            </div>
          );
        })}

        <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent
            loadingSpinner="crescent"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        {!isLoading && data.dataSet.length === 0 ? (
          <IonRow>
            <NoRecords width="60%" smallText={false} />
          </IonRow>
        ) : (
          <div></div>
        )}

      </IonContent>
    </IonPage>
  );
};

export default ModelHistory;
