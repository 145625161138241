import { withIonLifeCycle } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Image404 from '../assets/404.svg';
import Left from '../assets/left-dark.svg';

const NotFound = (props: any) => {
  const history = useHistory();

  const back = () => {
    history.goBack();
  };
  const { t } = useTranslation();

  return (
    <div>
      <img className="img-center" src={Image404} alt="Not Found" />
      <h1 className="text-center no-rec-head">{t('Utils.connectivity')}</h1>
      <div onClick={() => back()} className="flex justify-center pointer">
        <img style={{ marginRight: '0.5rem' }} src={Left} alt="Not Found" />
        <p className="return-home pointer">{t('Utils.connect_back')}</p>
      </div>
    </div>
  );
};

export default withIonLifeCycle(NotFound);
