import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Network } from '@capacitor/network';
import {
  IonContent,
  IonPage,
  useIonToast,
  useIonViewWillEnter,
  IonLoading,
  IonButton,
  IonIcon,
  IonSelect,
  IonSelectOption,
  useIonAlert,
} from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { format as ft } from 'date-fns';
import { close } from 'ionicons/icons';
import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Down from '../../assets/cheveron.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import { createSurveyFields } from '../../models/createSurveyFields';
import { AppContext } from '../../providers/AppContext';
import { constant, createFormJsonData, offlineSaveToTable, resetForm, setStorage } from '../../utils';


const CreateSurvey: React.FC = () => {
  const [showLoading, setShowLoading] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);
  const [presentAlert] = useIonAlert();


  // const [isScan, setIsScan] = useState(false);

  const [data, setData] = useState<any>(createSurveyFields);
  const [show, setShow] = useState<any>({
    'Survey.identification': true,
  });
  const { dispatch } = useContext(AppContext);

  const handleChange = (event: any) => {
    const formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    
    const change_form_object = formentry[f_index];
    
    if (f_index === 1) {
      
      if (event.target.value.length === 8 && event.nativeEvent.inputType === 'insertText') {
        
        event.target.value = event.target.value + '-';
      }
    }
    
    if (event.target.value.length < 14) {
      change_form_object.value = event.target.value;
    }
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: any) => {
    const formentry: any = [...data];
    
    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };

  const handleMultiSelectChange = (event: any) => {
    const formentry: any = [...data];
    
    const select = event.target.name;
    const f_index = formentry.findIndex((v: any) => v.field === select);
    
    formentry[f_index].value = event.detail.value;
    setData(formentry);
  };

  const handleChangeCheck = (event: React.ChangeEvent<{ id: any; checked: any }>) => {
    const formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    const change_form_object = formentry[f_index];
    if (event.target.checked) {
      change_form_object.value = 1;
    } else {
      change_form_object.value = 0;
    }
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const history = useHistory();

  const [present] = useIonToast();

  const { t } = useTranslation();

  const url = '/resource/Survey';

  useIonViewWillEnter(() => {
    // var formentry: any = [...data];
    // formentry[1].value = timeFormat('yyyyMMdd-mmHH');
    // setData(formentry);
  });

  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, []);

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.webPath;

      // Can be set to the src of an image now
      const formentry: any = [...data];
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      setData(formentry);
    } catch (e: any) {
      
      console.log('Error: ', e);
      
      if (e.toString().includes('cancelled')) {
        
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      
      else if (e.toString().includes('denied') || e.includes('access')) {
        
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const timeFormat = (stringFormat = 'yyyyMMdd-mmHH') => {
    return ft(new Date(), stringFormat);
  };

  const Submit = async (e: any) => {
    e.preventDefault();

    for(const item in data){
      if(data[item].field === 'si_survey_code' && !data[item].value){
       present({
          message: t('Utils.surveyCodeEmpty'),
          color: 'danger',
          duration: 2000,
        });
        return
      }
    }
    if (isOnline) {
      uploadFiles();
    } else {
      for (const item in data) {
        if (data[item].type === 'file') {
          try {
            if (data[item].src) {
              await setStorage(data[item].field + '-' + data[1].value, data[item].src);
            }
          } catch (e: any) {
            console.log(e);
            data[item].value = '';
          }
        }
      }

      for (const item in data) {
        if (data[item].type === 'select' && !data[item].multiple) {
          if (data[item].value === '< 1 year') {
            data[item].value = '&lt;1 year';
          } else if (data[item].value === '1 year < age < 10year') {
            data[item].value = '1 year&lt;age&lt;10year';
          } else if (data[item].value === '> 20year') {
            data[item].value = '&gt;20year';
          } else if (data[item].value === '10 year < age < 20year') {
            data[item].value = '10 year&lt;age&lt;20year';
          }
        } else if (data[item].type === 'select' && data[item].multiple) {
          
          if (data[item].value.length > 0) {
            const tempArr = data[item].value;
            data[item].value = [];
            
            for (const index in tempArr) {
              
              const temp = { option: tempArr[index] };
              
              data[item].value.push(temp);
            }
          }
        } else if (data[item].type === 'checkbox') {
          if (data[item].value === 'false') {
            data[item].value = '0';
          } else if (data[item].value === 'true') {
            data[item].value = '1';
          }
        }
      }

      const api_data = createFormJsonData(data);

      // save data in storage
      offlineSaveToTable(api_data, url, 'Survey');

      dispatch({ type: 'toggleOfflineSave', payload: {} });
      setData(resetForm(data));
      const formentry: any = [...data];
      formentry[1].value = timeFormat('yyyyMMdd-mmHH');
      setData(formentry);
      history.push({
        pathname: '/survey-home',
        state: '',
      });
    }
  };

  const uploadFiles = async () => {
    setShowLoading(true);
    const formData = new FormData();
    for (const item in data) {
      if (data[item].type === 'file') {
        try {
          
          if (data[item].src?.length > 0) {
            // find survey unique id
            const f_index = data.findIndex((v: any) => v.field === constant.SURVEY_UNIQUE_ID);
            
            const response = await fetch(data[item].src?.toString());
            const blob = await response.blob();
            
            formData.append(
              'file',
              new File([blob], `${new Date()?.getTime()}-${new Date().getTime()}.${data[item].format}`, {
                type: `image/${data[item].format}`,
              })
            );

            formData.append('is_private', '0');
            formData.append('folder', 'Home');
            formData.append('doctype', 'Survey');
            const config: any = {
              method: 'post',
              url: '/method/upload_file',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
              },
              data: formData,
            };
            const res = await axios(config);
            data[item].value = res.data.message.file_url;
            formData.delete('file');
            setData(data);
          }
        } catch (e: any) {
          data[item].value = '';
        }
      }
    }
    hitCreateSurvey(data);
  };

  const hitCreateSurvey = (api_data: any) => {
    for (const item in api_data) {
      if (api_data[item].type === 'select' && !api_data[item].multiple) {
        if (api_data[item].value === '< 1 year') {
          api_data[item].value = '&lt;1 year';
        } else if (api_data[item].value === '1 year < age < 10year') {
          api_data[item].value = '1 year&lt;age&lt;10year';
        } else if (api_data[item].value === '> 20year') {
          api_data[item].value = '&gt;20year';
        } else if (api_data[item].value === '10 year < age < 20year') {
          api_data[item].value = '10 year&lt;age&lt;20year';
        }
      } else if (api_data[item].type === 'select' && api_data[item].multiple) {
        if (api_data[item].value.length > 0) {
          const tempArr = api_data[item].value;
          api_data[item].value = [];
          for (const index in tempArr) {
            const temp = { option: tempArr[index] };
            api_data[item].value.push(temp);
          }
        }
      } else if (api_data[item].type === 'checkbox') {
        if (api_data[item].value === 'false') {
          api_data[item].value = '0';
        } else if (api_data[item].value === 'true') {
          api_data[item].value = '1';
        }
      }
    }
    const dataAllSet = createFormJsonData(api_data);
    const config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: JSON.stringify(dataAllSet),
    };
    setShowLoading(true);
    axios(config)
      .then((res) => {
        // showScore(res?.data?.data?.name);
        if (res.status === 200) {
          present({
            message: t('Survey.SurveyS'),
            duration: 2000,
          });
          setData(resetForm(data));
          history.push({
            pathname: '/survey-home',
            state: res?.data?.data?.name,
          });
        }
      })
      .catch(function (error) {
        const raw = JSON.parse(error?.response?.data?._server_messages);
        console.log(JSON.parse(raw[0])?.message);
        present({
          message: JSON.parse(raw[0])?.message,
          color: 'danger',
          duration: 2000,
        });
      })
      .finally(() => {
        setShowLoading(false);
        setShow({
          'Survey.identification': true,
        });
      });
  };

  // const showScore = async (name: any) => {
  //   try {
  //     var config: any = {
  //       method: 'get',
  //       url: `/method/preflet_asset.api.get_survey_score?id=${name}`,
  //       headers: {
  //         'Content-Type': 'application/json',
  //         Accept: 'application/json',
  //         Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //       },
  //     };
  //     const res = await axios(config);
  //     presentAlert({
  //       header: 'Survey Score',
  //       subHeader: 'Attempted Survey',
  //       message: 'Your survey score is ' + res?.data?.message,
  //       buttons: ['OK'],
  //     })
     
  //   } catch (error: any) {
  //     console.log(error);
  //   }
  // }

  const toggleCollapse = (title: any) => {
    const collabsible: any = { ...show };
    if (title in collabsible) {
      
      collabsible[title] = !collabsible[title];
    } else {
      
      collabsible[title] = true;
    }
    setShow(collabsible);
    
    return collabsible[title] || false;
  };

  /* Remove Image by cross click */
  const emptyImg = (index: number) => {
    const tempData = [...data];
    tempData[index].src = '';
    setData(tempData);
  };

  return (
    <IonPage>
      <Header enableBackButton='/survey-home' title={t('Survey.create')} />
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={5000}
        />

        <div className="ion-margin-top" style={{ textAlign: 'center' }}>
          {data.map((main_form_field: any, index: any) => {
            if (main_form_field.title) {
              return (
                <div key={`${main_form_field.title}-${index}`}>
                  <div
                    onClick={() => toggleCollapse(main_form_field.title)}
                    className="collapsible flex justify-between"
                  >
                    <p></p>
                    <p className="survey-title">{t(main_form_field.title)}</p>
                    <img
                      style={
                        !show[main_form_field.title] ? { transform: 'rotate(0deg)' } : { transform: 'rotate(180deg)' }
                      }
                      src={Down}
                      alt="down"
                    />
                  </div>
                  {' '}
                  <div hidden={!show[main_form_field.title]}>
                    {data.map((form_field: any, internalIndex: any) => {
                      if (internalIndex > index && form_field.section === main_form_field.section)
                        if (form_field.subtitle) {
                          return (
                            <p className="form-subtitle" key={`${form_field['title']}-${internalIndex}`}>
                              {t(form_field['subtitle'])}
                            </p>
                          );
                        } else if (form_field.type === 'text') {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">
                                {t(form_field['label'])}
                                {form_field.required ? '*' : null}
                              </label>
                              <input
                                required={form_field['required']}
                                className="basic-input"
                                id={form_field['field']}
                                placeholder={t(form_field['label'])}
                                onChange={handleChange}
                                value={form_field.value.toString()}
                              />
                            </div>
                          );
                        } else if (form_field.type === 'textarea') {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">{t(form_field['label'])}</label>
                              <textarea
                                className="textarea"
                                required={form_field['required']}
                                id={form_field['field']}
                                placeholder={t(form_field['label'])}
                                onChange={handleChange}
                                value={form_field.value.toString()}
                              />
                            </div>
                          );
                        } else if (form_field.type === 'select' && !form_field.multiple) {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">
                                {t(form_field['label'])}
                                {form_field.required ? '*' : null}
                              </label>
                              <Select
                                className="custom-select"
                                required={form_field['required']}
                                id={`${form_field['field']}_choice`}
                                name={form_field['field']}
                                onChange={handleSelectChange}
                                value={form_field.value.toString()}
                              >
                                {form_field['options']?.map(function (opt: any, indexSelect: any) {
                                    return (
                                      <MenuItem
                                        value={`${opt}`}
                                        key={`${opt}-${indexSelect}`}
                                        id={`${opt}_item_${indexSelect}`}
                                      >
                                        <section style={{ display: 'flex', height: '1rem' }}>
                                          <p
                                            style={{
                                              color: 'rgba(31, 87, 148, 1)',
                                              marginLeft: '0.2rem',
                                              fontSize: '12px',
                                              marginTop: '0px',
                                            }}
                                          >
                                            {t(`Survey.${opt}`)}
                                          </p>
                                        </section>
                                      </MenuItem>
                                    );
                                  })}
                              </Select>
                            </div>
                          );
                        } else if (form_field.type === 'select' && form_field.multiple === true) {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">
                                {t(form_field['label'])}
                                {form_field.required ? '*' : null}
                              </label>
                              <IonSelect
                                style={{ padding: '11px' }}
                                className="custom-select"
                                value={form_field.value}
                                name={form_field['field']}
                                okText={t('Utils.ok')}
                                cancelText={t('Utils.cancel')}
                                multiple={true}
                                
                                onIonChange={handleMultiSelectChange}
                              >
                                {form_field['options']?.map(function (opt: any, indexSelect: any) {
                                    return (
                                      <IonSelectOption
                                        key={`${opt}-${indexSelect}`}
                                        id={`${opt}_item_${indexSelect}`}
                                        value={`${opt}`}
                                      >
                                        {t(`Survey.${opt}`)}
                                      </IonSelectOption>
                                    );
                                  })}
                              </IonSelect>
                            </div>
                          );
                        } else if (form_field.type === 'date') {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">{t(form_field['label'])}</label>
                              <input
                                required={form_field['required']}
                                className="date"
                                id={form_field['field']}
                                placeholder={t(form_field['label'])}
                                onChange={handleChange}
                                value={form_field.value.toString()}
                                type="date"
                              />
                            </div>
                          );
                        } else if (form_field.type === 'checkbox') {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <input
                                required={form_field['required']}
                                className="check-box"
                                id={form_field['field']}
                                placeholder={t(form_field['label'])}
                                onChange={handleChangeCheck}
                                value={form_field.value.toString()}
                                type="checkbox"
                              />
                              <label className="checkbox">{t(form_field['label'])}</label>&nbsp;&nbsp;
                            </div>
                          );
                        } else if (form_field.type === 'file') {
                          return (
                            <div style={{ marginBottom: '1rem' }} key={`${form_field['field']}-${internalIndex}`}>
                              {form_field.showlabel ? (
                                <label className="label-survey">{t(`Survey.${form_field['field']}`)}</label>
                              ) : (
                                ''
                              )}
                              <br />
                              <IonButton size="small" color="light" onClick={() => takePicture(internalIndex)}>
                                {form_field['src'] ? t('Utils.change_image') : t('Utils.image')}
                              </IonButton>
                              <br />
                              {form_field['src'] ? (
                                <div className="flex justify-center items-center">
                                  <img className="preview-survey-img" src={form_field['src']} alt="" />
                                  <IonIcon className="close-icon" onClick={() => emptyImg(internalIndex)} src={close} />
                                </div>
                              ) : (
                                ''
                              )}
                            </div>
                          );
                        } else if (form_field.type === 'int') {
                          return (
                            <div className="input-container" key={`${form_field['field']}-${internalIndex}`}>
                              <label className="label-survey">
                                {t(form_field['label'])}
                                {form_field.required ? '*' : null}
                              </label>
                              <input
                                style={{ padding: '8px' }}
                                required={form_field['required']}
                                className="date"
                                id={form_field['field']}
                                placeholder={t(form_field['placeholder'] || '')}
                                onChange={handleChange}
                                value={form_field.value.toString()}
                                type="number"
                              />
                            </div>
                          );
                        } else {
                          return null;
                        }
                      else {
                        return null;
                      }
                    })}
                  </div>
                </div>
              );
            }
            return <div key={`${main_form_field.title}-${index}`}></div>;
          })}

          <button className="submit-button" onClick={(e) => Submit(e)}>
            {t('Survey.submit')}
          </button>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default CreateSurvey;
