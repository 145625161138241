
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonContent, IonToolbar, IonPage, IonItem, IonAvatar, IonIcon, IonLoading, IonLabel, IonAlert, useIonToast } from '@ionic/react';
import { businessOutline, camera, compassOutline, keyOutline, lockClosedOutline, shieldOutline } from 'ionicons/icons';
import React, { useState, useEffect, useContext } from 'react';
// import Profileselector from "../assets/Profileselector.svg"
import { useTranslation } from 'react-i18next';

import Icon1 from '../assets/Icon1.svg';
import Icon4 from '../assets/Icon4.svg';
import Man from '../assets/Man.svg';
import defaultavatar from '../assets/avatardefault.svg';
// import notifbell from "../assets/notifbell.svg"
// import { NavLink } from 'react-router-dom';
import axios from '../axios';
import Header from '../components/Header';
import { AppContext } from '../providers/AppContext';
import { constant } from '../utils';

const Profile: React.FC = () => {
  const [present] = useIonToast();

  const [showLoading, setShowLoading] = useState(false);
  const [passToggle, setPassToggle] = useState(false);
  const { dispatch } = useContext(AppContext);


  const [data, setData] = useState<any>({
    first_name: '',
    last_name: '',
    email: '',
    username: '',
    full_name: '',
    gender: '',
    phone: '',
    birth_date: '',
    user_image: '',
    department: [],
    entity: '',
    role: '',
    access: ''
  });

  const getProfileData = () => {
    const url = '/method/preflet_asset.api.get_profile_details';

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      axios(config).then((response) => {
        const Data = { ...data };
        Data.first_name = response.data.data.first_name;
        Data.last_name = response.data.data.last_name;
        Data.username = response.data.data.username;
        Data.email = response.data.data.email;
        Data.full_name = response.data.data.full_name;
        Data.gender = response.data.data.gender;
        Data.phone = response.data.data.phone;
        Data.birth_date = response.data.data.birth_date;
        Data.user_image = response.data.data.user_image;
        Data.department = response.data.data.department;
        Data.entity = response.data.data.entity;
        Data.role = response.data.data.role;
        Data.access = response.data.data?.access_level?.name1;

        setData(Data);
      });
    } catch (error: any) {
      console.log(error);
    }
  };

  useEffect(() => {
    getProfileData();
  }, []);

  const { t } = useTranslation();
  const takePicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.webPath;
      uploadDp(imageUrl, image.format);
    } catch (e: any) {
      console.log('Error: ', e);
      if (e.toString().includes('cancelled')) {
        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }
      else if (e.toString().includes('denied') || e.includes('access')) {
        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {
        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const uploadDp = async (imageUrl: any, format: any) => {
    const formData = new FormData();
    try {
      setShowLoading(true);
      const response = await fetch(imageUrl.toString());
      const blob = await response.blob();
      formData.append(
        'file',
        new File([blob], `${data.username}-${new Date().getTime()}.${format}`, {
          type: `image/${format}`,
        })
      );
      formData.append('is_private', '0');
      formData.append('folder', 'Home');
      formData.append('doctype', 'User');

      const config: any = {
        method: 'post',
        url: '/method/upload_file ',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
        data: formData,
      };
      const res = await axios(config);
      hitLinkUpdateApi(res?.data?.message?.file_url);
    } catch (error: any) {
      console.log(error);
      setShowLoading(false);
    }
  }

  const hitLinkUpdateApi = async (imageUrl: any) => {
    const data = JSON.stringify({
      file_url: imageUrl,
    });
    const url = '/method/preflet_asset.api.upload_profile_picture';
    const config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data,
    };
    try {
      await axios(config);
      localStorage.setItem(constant.PROFILE_PHOTO, imageUrl);
      getProfileData();
    } catch (error: any) {
      console.log(error);
    } finally {
      setShowLoading(false);
    }
  }

  const getImg = () => {
    if (data.user_image)
      return `${localStorage.getItem('baseUrl')?.replace('/api', '')}${data.user_image}`
    else return defaultavatar
  }

  const updatePassword = async (passwords: any) => {
    const url = '/method/preflet_asset.api.update_password';
    const data = JSON.stringify({
      old_password: passwords[0],
      new_password: passwords[1],
    });
    const config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data,
    };
    try {
      setShowLoading(true);
      await axios(config);
      present({
        message: t(`Utils.passwordChanged`),
        color: 'success',
        duration: 2000,
      });
      dispatch({ type: 'logOut' });
    } catch (error: any) {
      console.log(error?.response?.data);
      present({
        message: t(`Utils.` + error?.response?.data?.message?.data),
        color: 'danger',
        duration: 2000,
      });
    } finally {
      setShowLoading(false);
      setPassToggle(false);
    }

  }

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Header.Avatar.Profile')} />
      <IonContent>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
        />
        <IonAvatar style={{ position: 'relative', height: '30vh', width: '30vh', marginTop: '10px', marginRight: 'auto', marginLeft: 'auto', border: '2px solid lightgray', padding: '4px' }}>
          <img src={getImg()} alt="profile" />
          <IonIcon className="edit-icon-photo" src={camera} onClick={() => takePicture()}></IonIcon>
        </IonAvatar>
        <h3 style={{ textAlign: 'center', color: 'rgba(29, 80, 141, 1)', marginTop: '2.3rem' }}>{data.full_name || '-'}</h3>
        <IonItem className="heading data-list" style={{ marginTop: '2rem' }}>
          <p>{t('Service.Detail')}</p>
        </IonItem>
        <div className="data-list">
          <section style={{ display: 'flex', width: '50vw', marginLeft: '1rem' }}>
            <img src={Icon4} alt="1" />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>{t('Profile.ID')}</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
            }}
          >
            {data.username || '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ display: 'flex', alignItems: 'center', width: '50vw', marginLeft: '1rem' }}>
            <IonIcon src={lockClosedOutline} />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>{t('Profile.password')}</p>
          </section>
          <IonLabel
            onClick={() => setPassToggle(true)}
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              border: '1px solid rgba(29, 80, 141, 1)',
              padding: '0.2rem',
              borderRadius: '5px',
              marginRight: '1rem',
              cursor: 'pointer'
            }}
          >
            Change Password
          </IonLabel>
        </div>

        <IonItem className="data-list heading " style={{ marginTop: '1rem' }}>
          <p>{t('Profile.Personal')}</p>
        </IonItem>
        <div className="data-list">
          <section style={{ display: 'flex', width: '50vw', marginLeft: '1rem' }}>
            <img src={Icon1} alt="1" />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>{t('Profile.DOB')}</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
            }}
          >
            {data.birth_date ? data.birth_date : '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ display: 'flex', width: '50vw', marginLeft: '1rem' }}>
            <img src={Icon4} alt="1" />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>{t('Profile.C')}</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
            }}
          >
            {data.phone ? data.phone : '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ display: 'flex', width: '50vw', marginLeft: '1rem' }}>
            <img src={Man} alt="1" />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>Email</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              textAlign: 'right',
            }}
          >
            {data.email || '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ color: 'rgba(29, 80, 141, 1)', display: 'flex', width: '50vw', marginLeft: '1rem', alignItems: 'center' }}>
            <IonIcon src={compassOutline} />
            <p style={{ color: 'rgba(29, 80, 141, 1)', marginLeft: '5px', fontSize: 'smaller' }}>Entity</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              textAlign: 'right',
            }}
          >
            {data.entity || '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ color: 'rgba(29, 80, 141, 1)', display: 'flex', width: '50vw', marginLeft: '1rem', alignItems: 'center' }}>
            <IonIcon src={businessOutline} />
            <p style={{ marginLeft: '5px', fontSize: 'smaller' }}>Departments</p>
          </section>

          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              textAlign: 'right',
              maxWidth: '50%',
            }}
          >
            {data.department.length > 0 ?
              data.department && data.department.length === 1 ? data.department : data.department.join(' | ')
              : '-'}
          </p>
        </div>

        <div className="data-list">
          <section style={{ color: 'rgba(29, 80, 141, 1)', display: 'flex', width: '50vw', marginLeft: '1rem', alignItems: 'center' }}>
            <IonIcon src={shieldOutline} />
            <p style={{ marginLeft: '5px', fontSize: 'smaller' }}>Role</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              textAlign: 'right',
            }}
          >
            {data.role || '-'}
          </p>
        </div>
        <div className="data-list">
          <section style={{ color: 'rgba(29, 80, 141, 1)', display: 'flex', width: '50vw', marginLeft: '1rem', alignItems: 'center' }}>
            <IonIcon src={keyOutline} />
            <p style={{ marginLeft: '5px', fontSize: 'smaller' }}>Access</p>
          </section>
          <p
            style={{
              color: 'rgba(29, 80, 141, 1)',
              float: 'right',
              fontSize: 'smaller',
              flex: 'column',
              marginTop: '-1.85rem',
              paddingRight: '1.5rem',
              textAlign: 'right',
            }}
          >
            {data.access || '-'}
          </p>
        </div>
        <IonToolbar></IonToolbar>
        <IonAlert
          isOpen={passToggle}
          onDidDismiss={() => setPassToggle(false)}
          header="Enter Password"
          message={'Please ensure that your password is at least 10 characters long and contains a combination of symbols, numbers, and both uppercase and lowercase letters for enhanced security.'}
          inputs={[
            {
              type: 'password',
              placeholder: 'Old Password',
            },
            {
              type: 'password',
              placeholder: 'New Password',
            },
          ]}
          buttons={[
            {
              text: t('Utils.changePassword'),
              handler: (event) => {
                updatePassword(event);
              },
            },
          ]}
        ></IonAlert>
      </IonContent>
    </IonPage>
  );
};

export default Profile;
