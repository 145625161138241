// posthogService.js

import posthog from 'posthog-js';

export const initPostHog = () => {
  const posthogApiKey = 'phc_DjyNLh9wt4DlzDiP3N9MqMLMBUDtTwywQEM9ZWxX7Qc'; 

  if (posthogApiKey) {
    posthog.init(posthogApiKey, { api_host: 'https://eu.posthog.com' });
  }
};

export const identifyUser = (distinctId, properties) => {
  posthog.identify(distinctId, properties);
};

export const logout = () =>{
    posthog.reset(true)
  }