import { IonContent, IonPage, useIonToast, IonLoading, useIonRouter, IonCheckbox, IonLabel, IonAlert } from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import logo from '../assets/logo3.png';
import axios from '../axios';

const ResetPassword: React.FC = () => {
  const [present] = useIonToast();
  const [showLoading, setShowLoading] = useState(false);
  const { t } = useTranslation();
  const [data, setData] = useState<any>({
    new_password: '',
    confirm_password: '',
    logout_all_sessions: 1,
    key: '',
  });
  const [linkExpired, setLinkExpired] = useState(false);
  

  const handleChange = (event: any) => {
    const formentry: any = { ...data };
    formentry[event.target.id] = event.target.value.replace(/\s/g, '');
    setData(formentry);
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    if(data.new_password !== data.confirm_password) {
      present({
        message: t('Login.passNotMatch'),
        color: 'danger',
        duration: 2000,
      });
      return;
    } else {
      data.key = new URLSearchParams(window?.location?.search)?.get('key') || ''
      try {
        setShowLoading(true)
        const res = await axios.post(`/method/preflet_asset.api.update_password`,data);
        present({
          message: t('Login.resetPassSuccess'),
          duration: 2000,
        });
        window.location.href = '/login'
      } catch (error: any) {
        console.log('error', error?.response?.data?.message?.data);
        if(error?.response?.data?.message?.data === 'Link expired') {
          present({
            message: t('Login.linkExpired'),
            color: 'danger',
            duration: 2000,
          });
          setLinkExpired(true)
        } else {
          present({
            message: t('Login.resetPassFailed'),
            color: 'danger',
            duration: 2000,
          });
        }
        // if(error?.response?.data?.exc_type === 'DoesNotExistError') {
        //   present({
        //     message: t('Login.notexist'),
        //     color: 'danger',
        //     duration: 2000,
        //   });
        // }
      } finally {
        setShowLoading(false)
      }
    }
  }


  return (
    <IonPage>
      <IonContent className="ion-text-center">
        <img src={logo} alt="Logo" style={{maxWidth:'8rem', width: '25%',margin: 'auto', display: 'block', marginTop: '2rem' }}></img>
        <p
          style={{
            fontFamily: 'Nunito Sans Extra-bold, sans-serif',
            color: '#1D508D',
            fontSize: 'xx-large',
            marginTop: '0px',
            fontWeight: 'bolder',
          }}
        >
          <b>Hyprism</b>
        </p>
        <p style={{ color: '#1D508D', marginTop: '-10px', fontSize: 'small' }}>{t('Login.resetPassTitle')}</p>

        <IonLoading
          
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
        />
        {linkExpired && <IonAlert
          isOpen={true}
          onDidDismiss={() => setShowLoading(false)}
          header={t('Login.linkExpired')}
          message={t('Login.tryAgain')}
          buttons={['OK']}
        />}

        {linkExpired ? <div>
          <button className="submit-button" onClick={() => window.location.href = '/login'}>
            {t('Login.backToLogin')}
          </button>
        </div>
        : 
        <form onSubmit={Submit}>
          <div className="input-container">
            <input
              className="basic-input"
              style={{ padding: '15px' }}
              id="new_password"
              required
              placeholder={t('Login.new_password')}
              onChange={handleChange}
              value={data.new_password}
            />
            <input
              className="basic-input"
              style={{ padding: '15px' }}
              id="confirm_password"
              required
              type="password"
              placeholder={t('Login.confirm_password')}
              onChange={handleChange}
              value={data.confirm_password}
            />
          </div>
          <button className="submit-button" type="submit">
            {t('Login.resetPass')}
          </button>
        </form>
        }
      </IonContent>
    </IonPage>
  );
};

export default ResetPassword;
