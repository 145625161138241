import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonPage,
  IonRefresher,
  IonRefresherContent,
  IonGrid,
  IonRow,
  IonCard,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonIcon,
  IonSearchbar,
  IonSkeletonText,
  IonCardHeader,
  IonCardTitle,
} from '@ionic/react';
import { chevronDownCircleOutline, location } from 'ionicons/icons';
import React, { useState, useEffect, useRef , useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Red from '../../assets/Red.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import { AppContext } from '../../providers/AppContext';

const ViewAllProjects: React.FC = () => {
  const [data, setData] = useState<any>({
    dataSet: [],
    loading: false,
  });
  const isMounted = useRef(true);

  const { t } = useTranslation();

  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [searchText, setSearchText] = useState('');
  const [filter] = useState<string>();

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const getData = (context: any) => {
    isMounted.current = true;
    let url = `method/preflet_asset.apis.project.routes.project_list?limit=${state.maxLength}&start=${context?.isFiltered ? 0 : currentPage * 10 + 0
      }`;
     
    // add search data if any
    if ('search' in context || searchText) {
      if (isMounted.current)
      setSearchText(context?.search || '');
      if (context.search) url += `&search=${context.search || searchText}`;
    }
    if (isMounted.current)
    setIsLoading(true);

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res?.data?.data && res?.data?.data?.length > 0) {
          if (isMounted.current) {
          if (context.isFiltered) {
            const rawData = { dataSet: [], loading: false };
            rawData.dataSet = res.data.data;
            if (isMounted.current)
            setData(rawData);
          } else {
            const rawData = { ...data };
            
            rawData.dataSet.push(...res?.data?.data);
            if (isMounted.current)
            setData(rawData);
          }
          if (isMounted.current)
          setDisableInfiniteScroll(res.data.data.length < state.maxLength);
        }
        } else {
          const value = { ...data };
          if (isMounted.current) {
          setData(value);
          setDisableInfiniteScroll(true);
          }
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isMounted.current) {

        setIsLoading(false);
        setCurrentPage(currentPage + 1);
      }
      });
  };

  useEffect(() => {
    getData({});
     // Cleanup function
     return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData({});
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  async function searchNext($event: CustomEvent<void>) {
    getData({});
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }



  const handleSearch = (value: any) => {
    getData({ search: value, isFiltered: true });
  }

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={t('Project.projectsList')} />
      <IonSearchbar
        className="data-list"
        value={searchText}
        
        onIonChange={(e) => handleSearch(e.detail.value!)}
      ></IonSearchbar>

      <IonContent>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>

        {isLoading && data.dataSet.length ?
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          }) : ''}
        <IonGrid>
          {data.dataSet.map((item: any, key: any) => {
            return (
              <IonCard id="card-container" key={key}>
                <IonCardHeader>
                  <IonCardTitle className="card-heading">
                    <div className="flex justify-between items-center">
                      <Link className="underline-none" to={{ pathname: '/view-project', state: item.name }}>
                        <span>{item.project_name}</span>
                      </Link>
                    </div>
                  </IonCardTitle>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', alignItems: 'center', marginTop: '0.5rem' }}>
                      {item.status === 'Open' ? <img src={Red} alt="Red" style={{ marginRight: '5px' }} />
                        : <div style={{ marginRight: '5px', height: '0.64rem', width: '0.64rem', backgroundColor: 'green', borderRadius: '999px' }}></div>}
                      <span>{t(`Project.Status.${item.status}`)}</span>
                    </div>
                    {item.location && <span style={{ display: 'flex', alignItems: 'center' }}> <IonIcon style={{ marginLeft: '2px' }} src={location} />{item.location}</span>}
                  </div>
                  <div style={{ display: 'flex' }}>
                    {item.expected_start_date && <p style={{ marginRight: '5px' }}> Start: {item.expected_start_date} |   </p>}
                    {item.expected_end_date && <p> End: {item.expected_end_date}</p>}
                  </div>
                </IonCardHeader>
              </IonCard>
            );
          })}
          <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
          {!isLoading && data.dataSet.length === 0 ? (
            <IonRow style={{ margin: 'auto', display: 'block' }}>
              <NoRecords width="60%" smallText={false} />
            </IonRow>
          ) : null}
        </IonGrid>
      </IonContent>
    </IonPage>
  );
};

export default ViewAllProjects;
