import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonPage,
  IonItem,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonRow,
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import Clock from '../../assets/Clock.svg';
import CreateAsset from '../../assets/CreateAsset.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import { AppContext } from '../../providers/AppContext';

const SurveyHome: React.FC = () => {
  const { state } = useContext(AppContext);

  const isMounted = useRef(true);
  const [data, setData] = useState<any>({
    dataSet: [],
  });
  const [isLoading, setIsLoading] = useState(true);
  const { t } = useTranslation();
  const history = useHistory();

  const getData = () => {
    isMounted.current = true; // Set isMounted to true
    const url = '/resource/Survey?limit_page_length=5&limit_start=0&fields=["*"]';

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res) => {
        const Data = { ...data };
        Data.dataSet = res.data.data;
        if (isMounted.current)
        setData(Data);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isMounted.current)
        setIsLoading(false);
      });
  };
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  useEffect(() => {

    getData();

     // Cleanup function
     return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, [history.location.state]);

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '10vw', marginRight: '10vw' }}>
        {state.permissions.survey_create && 
          <Link to="/create-survey" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Survey.create')}
              </p>
            </section>
          </Link> || null }

          <Link to="/show-survey" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '25vw',
                height: '7rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={Clock} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '15px', fontSize: '11px' }}>
                {t('Survey.history')}
              </p>
            </section>
          </Link>
        </div>
        <IonItem className="heading data-list" style={{ marginTop: '1rem' }}>
          <p style={{ marginBottom: '0.5rem' }}>{t('Survey.recent')}</p>
        </IonItem>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        {isLoading &&
          [1, 2, 3, 4, 5].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        {data.dataSet.map((item: any, key: any) => {
          return (
            <div
              key={key}
              className="ion-margin data-list"
              style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
            >
              <section style={{ display: 'flex' }}>
                {/* <img
               src={defaultavatar}
              alt="Person1"
              style={{ height: '45px', width: '45px', marginLeft: '1rem', marginTop: '1rem' }}
            /> */}

                <section style={{ flex: 'column' }}>
                  <h4
                    style={{
                      marginLeft: '0.4rem',
                      marginTop: '1.3rem',
                      color: 'rgba(29, 80, 141, 1)',
                      fontSize: 'large',
                    }}
                  >
                    {item.name}
                  </h4>
                  <p
                    style={{
                      marginLeft: '0.5rem',
                      color: 'rgba(146, 155, 171, 1)',
                      marginTop: '-0.5rem',
                      fontSize: '11px',
                      maxWidth: '200px',
                      wordWrap: 'break-word',
                    }}
                  >
                    {t('Survey.code')} : {item.si_survey_code}
                  </p>
                </section>
              </section>

              <section style={{ flex: 'column' }}>
                <Link to={{ pathname: '/view-survey', state: item.name, search: 'back=survey-home' }}>
                  <p
                    style={{
                      color: 'rgba(29, 80, 141, 1)',
                      float: 'right',
                      fontSize: '13px',
                      flex: 'column',
                      marginTop: '-2.9rem',
                      paddingRight: '1rem',
                    }}
                  >
                    {t('Assets.Details.Details')}
                  </p>
                </Link>

                <section style={{ display: 'flex', float: 'right' }}>
                  {/* <img src={Red} alt="Red" style={{ marginTop: '-2.2rem', marginRight: '5px' }} /> */}
                  <p
                    style={{
                      color: 'rgba(146, 155, 171, 1)',
                      float: 'right',
                      marginTop: '-1.5rem',
                      fontSize: '10px',
                      paddingRight: '1rem',
                    }}
                  >
                    {item.men_status}
                  </p>
                </section>
              </section>
            </div>
          );
        })}
        {data && data.dataSet.length > 0 ? <Link to="/show-survey" style={{ margin: 'auto', display: 'block', marginBottom: '1rem' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}

        <IonToolbar></IonToolbar>
        {!isLoading && data.dataSet.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SurveyHome;
// withRouter();
