import axios from 'axios';
import CryptoJS from 'crypto-js';
import React, { useReducer } from 'react';

import { logout } from '../utils/posthogService';

const AppContext = React.createContext();
var initialState = {
  defaultBaseUrl: 'https://admin.hyprism.com/api',
  originalBaseUrl: 'https://admin.hyprism.com/api',
  isLoggedIn: false,
  maxLength: 10,
  permissions: {},
  loadSlide: false,
  slideOpts: {
    slidesPerView: 5.0,
    initialSlide: 1,
    speed: 10,
    effect: 'slide',
    freeModeMomentum: true,
    resistance: true,
  },
  dashboard: {
    total_assets: '',
    total_overdue_maintenance: '',
    maintenance_count: {},
    tasks_count: {},
    repair_req_count: {},
    pending_repairs: '',
    energy_consumption: '',
    need_installation_or_replacement_assets: ''
  },
  map: [],
  colors: ['#7AABE5', '#FF9088', '#FCB454', '#61DBA7', '#C2F9EE', '#D4EEFF', '#FCC98B'],
  showOfflineSaveMessage: false,
  assetStatus: [
    '-',
    'Installed',
    'In Progress',
    'Installed But Not Operational',
    'Operational',
    'Need Installation',
    'Replaced',
    'Need Replacement',
  ],
};


// get from localstorage
const token = localStorage.getItem('hyprism_login');
if (token) {
  initialState.isLoggedIn = true;
  initialState.token = token;
  const baseUrl = localStorage.getItem('baseUrl') || '';
  axios.defaults.baseURL = initialState.defaultBaseUrl = baseUrl;
}

// get permissions
try {
  initialState.permissions = JSON.parse(localStorage.getItem('permissions')) || {};
  initialState.slideOpts.slidesPerView = Number(localStorage.getItem('defaultCount') || 1);
} catch (e) { 
  console.log(e)
} finally {
  initialState.slideOpts.loadSlide = true;
}


const reducer = (state, action) => {
  switch (action.type) {
    case 'loggedIn':

      var keys = action.payload.message.key_details;
      localStorage.setItem('hyprism_login', btoa(`${keys.api_key}:${keys.api_secret}`));
      return { ...state, isLoggedIn: true, token: btoa(`${keys.api_key}:${keys.api_secret}`) };
    case 'logOut':
      // call frappe for logging out
      try {
        // eslint-disable-next-line no-unused-expressions
        async () => {
          await axios
            .get(`${localStorage.getItem('baseUrl')}/method/logout`, { withCredentials: true })
        }
      } catch (e) { /* empty */ }

      // Protect Users Choice
      var temp = localStorage.getItem('remember');
      try {
        // Decrypt the token
        var usr = CryptoJS.AES.decrypt(localStorage.getItem('username'), 'username').toString(CryptoJS.enc.Utf8);
        var pwd = CryptoJS.AES.decrypt(localStorage.getItem('password'), 'password').toString(CryptoJS.enc.Utf8);
      } catch (e) { /* empty */ }

      // posthog reset device id
      logout();

      // Perform the logout
      localStorage.clear();
      sessionStorage.clear();
      axios.defaults.baseURL = state.defaultBaseUrl;

      try {
        // Encrypt the token
        const username = CryptoJS.AES.encrypt(usr, 'username').toString();
        const password = CryptoJS.AES.encrypt(pwd, 'password').toString();
        localStorage.setItem('username', username);
        localStorage.setItem('password', password);

        // Set Users Choice
        localStorage.setItem('remember', temp);
      } catch (e) { /* empty */ }
      return { ...state, isLoggedIn: false, permissions: {}, token: '', defaultBaseUrl: initialState.defaultBaseUrl };
    case 'permissions':
      var payloadKeys = Object.keys(action.payload);
      payloadKeys.map((k) => {
        const sectionName = k.split('_')[0];
        // add by default 0 means no access if doesn't exist
        if (!action.payload[sectionName]) action.payload[sectionName] = 0;
        // check if any key has 1 or true
        if (action.payload[k] === 1) {
          action.payload[sectionName] = 1;
        }
        return null;
      });
      // check for permissions to set slide count
      var defaultCount = 0.0;
      if (action.payload.project) {
        defaultCount += 1;
      }
      if (action.payload.assets) {
        defaultCount += 1;
      }
      if (action.payload.maintenance) {
        defaultCount += 1;
      }
      if (action.payload.service) {
        defaultCount += 1;
      }
      if (action.payload.survey) {
        defaultCount += 1;
      }
      if (action.payload.home) {
        defaultCount += 1;
      }
      if (action.payload.model) {
        defaultCount += 1;
      }

      state.slideOpts.slidesPerView = defaultCount;
      localStorage.setItem('defaultCount', defaultCount);

      localStorage.setItem('permissions', JSON.stringify(action.payload));
      initialState.slideOpts.loadSlide = true;
      return { ...state, permissions: action.payload };
    case 'dashboard':
      state.dashboard.total_assets = action.payload.total_assets;
      state.dashboard.pending_repairs = action.payload.pending_repairs;
      state.dashboard.total_overdue_maintenance = action.payload.total_overdue_maintenance;
      state.dashboard.energy_consumption = action.payload.energy_consumption;
      state.dashboard.need_installation_or_replacement_assets = action.payload.need_installation_or_replacement_assets;
      state.dashboard.maintenance_count.labels = action.payload.maintenance_count.map((mc) => {
        return mc.status;
      });
      state.dashboard.maintenance_count.count = action.payload.maintenance_count.map((mc) => {
        return mc.count;
      });
      state.dashboard.tasks_count.labels = action.payload.tasks_count.map((mc) => {
        return mc.status;
      });
      state.dashboard.tasks_count.count = action.payload.tasks_count.map((mc) => {
        return mc.count;
      });

      state.dashboard.repair_req_count.y = action.payload.repair_req_count.map((rc) => {
        return rc.status;
      });
      state.dashboard.repair_req_count.x = action.payload.repair_req_count.map((rc) => {
        return rc.count;
      });

      return { ...state, 'dashboard.total_assets': action.payload.total_assets };
    case 'toggleOfflineSave':
      return { ...state, showOfflineSaveMessage: !state.showOfflineSaveMessage };
    case 'map':
      state.map = [];
      var availableStatus = new Set();
      Object.keys(action).length && action.payload.length && action.payload.map((elem) => {
        try {
          const point = elem.northing_easting_point.split(',') || [0, 0];
          state.map.push({
            lat: point[0] ? parseFloat(point[0].toString().trim()) : 0,
            lon: point[1] ? parseFloat(point[1].toString().trim()) : 0,
            status: elem?.status || '-',
            name: elem?.name,
            color: elem?.asset_category?.color || 'white',
            assetStatus: elem?.inner_color  || 'black'
          });
          availableStatus.add(elem.status);
        } catch (e) {
          console.log(e);
        }
        // now filter asset status
        state.assetStatus = state.assetStatus.filter((st) => availableStatus.has(st));
        return null;
      });
      return { ...state };
    case 'UPDATE_GPS' :
      return { ...state, location: action.payload };
      default:
      return { ...state };
  }
};

const AppContextProvider = (props) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const value = { state, dispatch };

  return <AppContext.Provider value={value}>{props.children}</AppContext.Provider>;
};

const AppContextConsumer = AppContext.Consumer;

export { AppContext, AppContextProvider, AppContextConsumer };
