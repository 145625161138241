import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Device } from '@capacitor/device';
import { IonFab, IonFabButton, useIonAlert, useIonToast } from '@ionic/react';
import { useRef, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import landbutton from '../assets/landbutton.png';
import axios from '../axios';
import { constant, requestPermissions } from '../utils';

interface ScanButtonProps {
  isAssetPage: boolean;
}

const ScanButton: React.FC<ScanButtonProps> = ({ isAssetPage }) => {

  const isMounted = useRef(true);

  const history = useHistory();
  const [presentAlert] = useIonAlert();

  const { t } = useTranslation();

  const [present] = useIonToast();

  const [showIcon, setShowIcon] = useState(true)


  useEffect(() => {
    localStorage.setItem(constant.QUICK_SCAN_ASSET_CODE, '');
    isMounted.current = true; // Set isMounted to true
    (async () => {
      const info = await Device.getInfo();
      if (info?.platform === 'web' && isMounted.current) {
        present({
          message: t('Utils.scannerNotSupported'),
          duration: 2000,
        });
        setShowIcon(false)
        return
      }
    })();

    // Cleanup function
    return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);


  const handleScan = (data: any) => {
    // setScannedCode(data)
    if (data) {
      try {
        checkForAsset(data);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
        console.log('failed sending id to the parent');
      }
    }
  };

  const openScanner = async () => {
    try {
      BarcodeScanner.isSupported().then((result) => {
        if (!result.supported) {
          present({
            message: t('Utils.unsupportDevice'),
            duration: 2000,
          });
        }
      });

      const granted = await requestPermissions();
      if (!granted) {
        present({
          message: t('Utils.noPermission'),
          duration: 2000,
        });
        return;
      }
      const check = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
      if (!check.available) {
        present({
          message: t('Utils.settingScanner'),
          duration: 2000,
        });
        await BarcodeScanner.installGoogleBarcodeScannerModule()
      }
      const { barcodes } = await BarcodeScanner.scan();
      const scannedValue = barcodes[0]?.displayValue
      handleScan(scannedValue);
    } catch (error: any) {
      console.log(error)
    }
  }

  const checkForAsset = async (id: any) => {
    const config: any = {
      method: 'get',
      url: `resource/Asset?fields=["*"]&filters=[["Asset","barcode_id","=","${id}"]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      // setShowLoading(true);
      const temp = await axios(config);
      if (temp?.data?.data?.length) {
        history.push({
          pathname: '/assetDetail',
          state: temp?.data?.data[0]?.name,
        });
      } else {
        present({
          message: t('Utils.AssetNotExist'),
          duration: 2000,
        });
        // backToHome();
        if (isAssetPage) {
          askAddAsset(id);
        }
      }
    } catch (error: any) {
      console.log('Error', error);
      if (error.response?.status) {
        checkService(id);
      } else {
        present({
          message: t('Utils.noScanFound'),
          duration: 2000,
          color: 'danger'
        });
      }
    } finally {
      // setShowLoading(false);
    }
  };

  const checkService = async (id: any) => {
    const config: any = {
      method: 'get',
      url: `/resource/Repair Request/${id}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      // setShowLoading(true);
      await axios(config);
      history.push({
        pathname: '/serviceDetail',
        state: id,
      });
    } catch (error: any) {
      console.log(error);
      if (error.response.status) {
        checkMaintenance(id);
      } else {
        present({
          message: t('Utils.noScanFound'),
          duration: 2000,
          color: 'danger'
        });
      }
    } finally {
      // setShowLoading(false);
    }
  };

  const checkMaintenance = async (id: any) => {
    const config: any = {
      method: 'get',
      url: `/resource/Maintenance Request/${id}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      // setShowLoading(true);
      await axios(config);
      history.push({
        pathname: '/maintainanceDetail',
        state: id,
      });
    } catch (error: any) {
      console.log(error);
      present({
        message: t('Utils.noScanFound'),
        duration: 2000,
      });
      history.push({
        pathname: '/home',
      });
    } finally {
      // setShowLoading(false);
    }
  };


  const askAddAsset = (id: any) => {
    presentAlert({
      header: t('Utils.alertAssetAdd'),
      buttons: [
        {
          text: t('Utils.cancel'),
          role: 'cancel',
          handler: () => {
            console.log('Alert canceled');
          },
        },
        {
          text: t('Utils.yesCreate'),
          role: 'confirm',
          handler: () => {
            history.push({
              pathname: '/createAsset',
              state: id,
            });
          },
        },
      ],
      onDidDismiss: (e: CustomEvent) => console.log(e),
    })
  }


  return (
    <>
      {showIcon &&
        <IonFab vertical="bottom" horizontal="end" slot="fixed">
          <IonFabButton onClick={() => openScanner()}>
            <img src={landbutton} alt="Land Button" />
          </IonFabButton>
        </IonFab>
      }
    </>
  );
};

export default ScanButton;

