import {
  IonHeader,
  IonList,
  IonPopover,
  IonGrid,
  IonCol,
  IonRow,
  IonAvatar,
  IonPage,
  IonContent,
  IonButtons,
  IonBackButton,
} from '@ionic/react';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, NavLink } from 'react-router-dom';

import Leftarrow from '../assets/Leftarrow.svg';
import LogOut from '../assets/Logout.svg';
import offline from '../assets/Offline.svg';
import P1 from '../assets/P1.svg';
import Profileselector from '../assets/Profileselector.svg';
import red from '../assets/Red.svg';
import RightArrow from '../assets/RightArrowBlue.svg';
import UnableToSync from '../assets/UnableToSync.svg';
import defaultavatar from '../assets/avatardefault.svg';
import notifbell from '../assets/notifbell.svg';
import MenuItems from '../components/MenuItem';


const avatarcss = {
  width: '35px',
  height: '34px',
  top: '3rem',
};

const Offline: React.FC = () => {
  const { t } = useTranslation();

  const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });

  return (
    <IonPage>
      <IonContent>
        <IonHeader
          style={{
            backgroundImage: 'radial-gradient(#0070BA, #1546A0)',
            boxShadow: '0px 16px 36px -16px rgba(21, 70, 160, 0.5)',
          }}
        >
          <IonGrid>
            <IonRow className="ion-justify-content-around">
              <IonCol size="4" style={{ margin: 'auto' }}>
                <MenuItems />
              </IonCol>

              <IonCol size="4" style={{ margin: 'auto' }}>
                <div style={{ textAlign: 'center', margin: 'auto' }}>
                  <p style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '1.2rem' }}>Hyprism</p>
                </div>
              </IonCol>
              <IonCol size="4">
                <div style={{ float: 'right', display: 'flex', marginRight: '-10px' }}>
                  <div className="dropdownnotif">
                    <img
                      src={notifbell}
                      style={{ marginRight: '0px', marginTop: '1.3rem' }}
                      className="ion-margin"
                      alt="notifs"
                    />

                    <div className="dropdownnotif-content">
                      <section style={{ display: 'flex' }}>
                        <p
                          style={{
                            fontFamily: 'Nunito sans, sans serif',
                            margin: 'auto',
                            color: 'rgba(29, 80, 141, 1)',
                            marginTop: '0.5rem',
                            marginLeft: '0.5rem',
                            fontSize: '15px',
                            textAlign: 'left',
                          }}
                        >
                          {t('Header.Notifs.Head')}
                        </p>
                        <p
                          style={{
                            fontFamily: 'inter',
                            margin: 'auto',
                            color: 'rgba(29, 80, 141, 1)',
                            marginTop: '0.6rem',
                            marginRight: '1rem',
                            float: 'right',
                            fontSize: '13px',
                            textAlign: 'left',
                          }}
                        >
                          {t('Header.Notifs.VA')}
                        </p>
                      </section>

                      <section style={{ display: 'flex', width: '100%' }}>
                        <img src={P1} alt="rocket" style={{ height: '38px', marginLeft: '5px', marginTop: '1rem' }} />
                        <section style={{ flex: 'column' }}>
                          <p
                            style={{
                              fontFamily: 'Nunito sans,sans serif',
                              marginLeft: '0.4rem',
                              marginTop: '1.3rem',
                              color: 'rgba(29, 80, 141, 1)',
                              fontSize: '13px',
                            }}
                          >
                            User1 {t('Header.Notifs.Request')}
                          </p>
                          <p
                            style={{
                              fontFamily: 'Inter',
                              marginLeft: '0.5rem',
                              color: 'rgba(146, 155, 171, 1)',
                              marginTop: '-0.9rem',
                              fontSize: '11px',
                            }}
                          >
                            {t('Header.Notifs.Desc')} Lorem Ipsum Hodor Niroa...
                          </p>
                        </section>
                      </section>
                      <p
                        style={{
                          fontFamily: 'Inter',
                          color: 'rgba(146, 155, 171, 1)',
                          float: 'right',
                          marginTop: '-0.5rem',
                          fontSize: '10px',
                          paddingRight: '1rem',
                        }}
                      >
                        7 {t('Header.Notifs.HO')}
                      </p>

                      <section style={{ display: 'flex', width: '100%' }}>
                        <img src={P1} alt="rocket" style={{ height: '38px', marginLeft: '5px', marginTop: '0.8rem' }} />
                        <section style={{ flex: 'column' }}>
                          <p
                            style={{
                              fontFamily: 'Nunito sans,sans serif',
                              marginLeft: '0.4rem',
                              marginTop: '1.3rem',
                              color: 'rgba(29, 80, 141, 1)',
                              fontSize: '13px',
                            }}
                          >
                            User1 {t('Header.Notifs.Request')}
                          </p>
                          <p
                            style={{
                              fontFamily: 'Inter',
                              marginLeft: '0.5rem',
                              color: 'rgba(146, 155, 171, 1)',
                              marginTop: '-0.9rem',
                              fontSize: '11px',
                            }}
                          >
                            {t('Header.Notifs.Desc')} Lorem Ipsum Hodor Niroa...
                          </p>
                        </section>
                      </section>
                      <p
                        style={{
                          fontFamily: 'Inter',
                          color: 'rgba(146, 155, 171, 1)',
                          float: 'right',
                          marginTop: '-0.5rem',
                          fontSize: '10px',
                          paddingRight: '1rem',
                        }}
                      >
                        7 {t('Header.Notifs.HO')}
                      </p>

                      <section style={{ display: 'flex', width: '100%' }}>
                        <img src={P1} alt="rocket" style={{ height: '38px', marginLeft: '5px', marginTop: '0.8rem' }} />
                        <section style={{ flex: 'column' }}>
                          <p
                            style={{
                              fontFamily: 'Nunito sans,sans serif',
                              marginLeft: '0.4rem',
                              marginTop: '1.3rem',
                              color: 'rgba(29, 80, 141, 1)',
                              fontSize: '13px',
                            }}
                          >
                            User1 {t('Header.Notifs.Request')}
                          </p>
                          <p
                            style={{
                              fontFamily: 'Inter',
                              marginLeft: '0.5rem',
                              color: 'rgba(146, 155, 171, 1)',
                              marginTop: '-0.9rem',
                              fontSize: '11px',
                            }}
                          >
                            {t('Header.Notifs.Desc')} Lorem Ipsum Hodor Niroa...
                          </p>
                        </section>
                      </section>
                      <p
                        style={{
                          fontFamily: 'Inter',
                          color: 'rgba(146, 155, 171, 1)',
                          float: 'right',
                          marginTop: '-0.8rem',
                          fontSize: '10px',
                          paddingRight: '1rem',
                        }}
                      >
                        7 {t('Header.Notifs.HO')}
                      </p>

                      <section style={{ display: 'flex', width: '100%' }}>
                        <img src={P1} alt="rocket" style={{ height: '38px', marginLeft: '5px', marginTop: '1rem' }} />
                        <section style={{ flex: 'column' }}>
                          <p
                            style={{
                              fontFamily: 'Nunito sans,sans serif',
                              marginLeft: '0.4rem',
                              marginTop: '1.3rem',
                              color: 'rgba(29, 80, 141, 1)',
                              fontSize: '13px',
                            }}
                          >
                            User1 {t('Header.Notifs.Request')}
                          </p>
                          <p
                            style={{
                              fontFamily: 'Inter',
                              marginLeft: '0.5rem',
                              color: 'rgba(146, 155, 171, 1)',
                              marginTop: '-0.9rem',
                              fontSize: '11px',
                            }}
                          >
                            {t('Header.Notifs.Desc')} Lorem Ipsum Hodor Niroa...
                          </p>
                        </section>
                      </section>
                      <p
                        style={{
                          fontFamily: 'Inter',
                          color: 'rgba(146, 155, 171, 1)',
                          float: 'right',
                          marginTop: '-0.5rem',
                          fontSize: '10px',
                          paddingRight: '1rem',
                        }}
                      >
                        7 {t('Header.Notifs.HO')}
                      </p>
                    </div>
                  </div>
                  <IonPopover
                    id="popover"
                    
                    event={popoverState.event}
                    isOpen={popoverState.showPopover}
                    onDidDismiss={() => setShowPopover({ showPopover: false, event: undefined })}
                  >
                    <IonList>
                      <NavLink
                        to="../profile"
                        className="popovercont"
                        style={{ color: '#1D508D' }}
                        onClick={() => setShowPopover({ showPopover: false, event: undefined })}
                      >
                        <img style={{ width: '2rem', marginLeft: '1.8rem' }} src={Profileselector} alt="Profile"></img>
                        <p style={{ marginLeft: '5px' }}>{t('Header.Avatar.Profile')}</p>
                      </NavLink>

                      <NavLink
                        to="/"
                        className="popovercont"
                        style={{ color: '#E24444' }}
                        onClick={() => setShowPopover({ showPopover: false, event: undefined })}
                      >
                        <img style={{ width: '2rem', marginLeft: '2rem' }} src={LogOut} alt="Profile"></img>
                        <p style={{ marginLeft: '5px' }}>{t('Header.Avatar.LogOut')}</p>
                      </NavLink>
                    </IonList>
                  </IonPopover>
                  <IonAvatar
                    onClick={(e: any) => {
                      e.persist();
                      setShowPopover({ showPopover: true, event: e });
                    }}
                  >
                    <img src={defaultavatar} alt="my account" style={avatarcss} className="ion-margin" />
                  </IonAvatar>
                </div>
              </IonCol>
            </IonRow>
          </IonGrid>

          <div className="ion-margin">
            <section style={{ display: 'flex', float: 'right' }}>
              <img src={red} alt="online" style={{ marginTop: '-0.85rem', marginRight: '-10px' }}></img>
              <p className="ion-margin" style={{ color: 'rgba(255, 255, 255, 1)', marginTop: '0px', float: 'right' }}>
                {t('Header.Offline')}
              </p>
            </section>

            <h6 style={{ color: 'rgba(255, 255, 255, 1)', opacity: '0.5', marginTop: '0px', marginLeft: '16px' }}>
              {t('Header.Greet')}, Tecxt!
            </h6>

            <button
              style={{
                display: 'flex',
                float: 'right',
                width: '120px',
                height: '28px',
                borderRadius: '30px',
                backgroundColor: 'rgba(246, 79, 76, 1)',
                marginRight: '-4.5rem',
              }}
            >
              <img src={UnableToSync} alt="sync" style={{ margin: 'auto' }} />
              <p style={{ margin: 'auto', color: '#FFFFFF' }}>{t('Header.Unable')}</p>
            </button>

            <h6
              className="ion-margin-top"
              style={{ color: 'rgba(255, 255, 255, 1)', marginLeft: '16px', opacity: '0.5', fontSize: 'small' }}
            >
              {t('Header.Update')} :
            </h6>
          </div>

          <IonList style={{ display: 'inline-block', backgroundImage: 'inherit', width: '100%', height: '3.5rem' }}>
            <IonButtons slot="start" style={{ marginTop: '0.3rem' }}>
              <IonBackButton text={t('Header.Back')} defaultHref="/" />
            </IonButtons>
            <IonBackButton text="buttonText" icon={Leftarrow} />
          </IonList>
        </IonHeader>

        <img src={offline} alt="offline" style={{ width: '100%', marginTop: '4vh' }} />

        <div style={{ marginTop: '-4rem', width: '100%', textAlign: 'center' }}>
          <p style={{ fontSize: '22px', color: 'rgba(29, 80, 141, 1)' }}>
            {t('Offline.Lost')}
            <br />
            {t('Offline.Connectivity')}
          </p>
          <p style={{ fontSize: '15px', color: 'rgba(29, 80, 141, 1)', fontFamily: 'Inter' }}>{t('Offline.Search')}</p>

          <Link to="./home">
            <section
              style={{
                height: '40px',
                width: '40px',
                borderRadius: '50%',
                border: '2px solid rgba(29, 80, 141, 1)',
                margin: 'auto',
              }}
            >
              <img src={RightArrow} alt="go" style={{ marginTop: '12px' }} />
            </section>
          </Link>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Offline;
