import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import TimeAgo from 'timeago-react';

import axios from '../axios';


const AssetTimeline: React.FC<{ id: string }> = ({ id = '' }) => {

  const [timeline,setTimeline] = useState([]);
  const { t } = useTranslation();

  React.useEffect(() => {
    getTimeline();
  }, []);



  const getTimeline = () => {
    const url = `/resource/Version?fields=["*"]&filters=[["Version","docname","=","${id}"]]&limit_start=0&order_by=creation%20desc`;
    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res) => {
        setTimeline(res.data.data);
      })
      .catch((err) => {
        console.log(err);
      })
    return <p className="preview-title">{t('Utils.history')}</p>
  }

  const returnTimeline = (item: any,index: number) => {
    if (JSON.parse(item.data).created_by?.length) {
      return (
        <div key={index} className="timeline-container">
          <div className="timeline-dot"></div>
          {/* <span>{name}</span> */}
          <span>
            <b className="text-black">{JSON.parse(item.data).created_by}</b>&nbsp; {t('Utils.created')}{' '}
            <b>{id}</b>
          </span>
          <span>
            {' '}
            -{' '}
            <TimeAgo
              datetime={new Date(JSON.parse(item.data).creation.split(' ').join('T') + 'Z').toLocaleString('lt-LT')}
            />
          </span>
        </div>
      );
    } else if (JSON.parse(item.data).added?.length) {
      return JSON.parse(item.data).added.map((element: any, index: number) => {
        return (
          <div key={index} className="timeline-container">
            <div className="timeline-dot"></div>
            <span>
              <b className="text-black">{element[1].owner}</b>&nbsp;{t('Utils.added')}&nbsp;
            </span>
            <span>
              <b className="text-black">{t(`Assets.CreateA.${element[0]}`)}</b>&nbsp;
            </span>
            <span>
              : <b className="text-black">{element[1].component_name}</b>&nbsp;
            </span>
            <span>
              {' '}
              -{' '}
              <b>
                {' '}
                <TimeAgo datetime={new Date(item.modified.split(' ').join('T') + 'Z').toLocaleString('lt-LT')} />
              </b>
            </span>
          </div>
        );
      });
    } else if (JSON.parse(item.data).changed?.length) {
      return JSON.parse(item.data).changed.map((element: any, key: number) => {
        return (
          <div key={key} className="timeline-container">
            <div className="timeline-dot"></div>
            <span>
              <b className="text-black">{element[1]?.owner}</b>&nbsp;{t('Utils.changed')}&nbsp;
            </span>
            <span>
              <b className="text-black">{t(`Assets.CreateA.${element[0]}`)}</b>&nbsp;{t('Utils.from')}&nbsp;
            </span>
            <span>
              <b className="text-black">{element[1] ? element[1] : '--'}</b>&nbsp;{t('Utils.to')}&nbsp;
            </span>
            <span>
              <b className="text-black"> {element[2]}</b>
            </span>
            -
            <TimeAgo datetime={new Date(item.modified.split(' ').join('T') + 'Z').toLocaleString('lt-LT')} />
          </div>
        );
      });
    }
  };

  return (
  <div>
    {/* {getTimeline()} */}
     {timeline.map((item: any, index: number) => {
          return returnTimeline(item,index);
        })}
  </div>
  );
};

export default AssetTimeline;
