
import { Camera, CameraResultType } from '@capacitor/camera';
import {
  IonContent,
  IonPage,
  IonLoading,
  useIonToast,
  useIonViewWillEnter,
  IonButton,
  IonIcon,
  useIonRouter,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonSearchbar,
  IonList,
  IonItem,
  IonLabel,
  IonCheckbox,
  IonSkeletonText,
} from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { informationCircle, } from 'ionicons/icons';
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import axios from '../../axios';
import Header from '../../components/Header';
import { createMaintenanceFields } from '../../models/createMaintenanceFields';
import { getAssetComponentName, getSetAssetComponentType, getSetAssetNameWithNoMaintenance, getSetProjectList } from '../../utils';


const CreateMainten1: React.FC = () => {
  const router = useIonRouter();
  const [present] = useIonToast();

  const { t } = useTranslation();

  // const [isScan, setIsScan] = useState(false);
  const [currentPage, setCurrentPage] = useState(0);


  // const [isOnline, setIsOnline] = useState(Boolean);
  const [showLoading, setShowLoading] = useState(false);

  const [data, setData] = useState<any>(createMaintenanceFields);
  // const [checklist, setChecklist] = useState({
  //   activity: '',
  //   yes_or_no: '',
  //   remarks: '',
  // });


  const [projects, setProjects] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState();
  const [projectModal, setProjectModal] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState(true);

  // const [chArray, setChArray] = useState([]);
  const [assetModalToggle, setSetAssetModalToggle] = useState(false);
  // const [activeChId, setActiveChId] = useState('');
  const [assets, setAssets] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [selectedAsset, setSelectedAsset] = useState();

  const handleChange = (event: any) => {
    const formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    const change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: any) => {
    const formentry: any = [...data];

    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    /* Hide Priority When Routine */
    if (select === 'maintenance_type' && event.target.value === 'Routine') {
      formentry[f_index].value = event.target.value;
      formentry[f_index + 1].hide = false;
      formentry[f_index + 1].required = true;
    } else {
      formentry[f_index].value = event.target.value;
      if (select === 'maintenance_type' && event.target.value !== 'Routine') {
        formentry[f_index + 1].hide = true;
        formentry[f_index + 1].required = false;
      }
    }

    if (formentry[f_index].name === 'checklist') {
      formentry[f_index].btnDisabled = false;
    }
    setData(formentry);
  };

  // /* Checklist handlers */
  // const handleChangeCheck = (event: any) => {
  //   const formentry: any = { ...checklist };
  //   formentry[event.target.id] = event.target.value;
  //   setChecklist(formentry);
  // };

  // const handleSelectChangeCheck = (event: any) => {
  //   const formentry: any = { ...checklist };

  //   const select = event.target.name.replaceAll('-', '_');
  //   formentry[select] = event.target.value;
  //   setChecklist(formentry);
  // };

  useIonViewWillEnter(async () => {
    const Data = [...data];
    /* Get Asset Component Name */
    try {
      const acat = Data.findIndex((d) => d.field === 'component_name');
      Data[acat].options = await getAssetComponentName();
    } catch (error: any) {
      console.log(error);
    }

    /* Get Component Type */
    const ctype = Data.findIndex((d) => d.field === 'component_type');
    Data[ctype].options = await getSetAssetComponentType();
    setShimmer(true);
    try {
      /* Get Asset name */
      const aname = Data.findIndex((d) => d.field === 'asset');
      Data[aname].options = await getSetAssetNameWithNoMaintenance('', '', currentPage); // Filter maintenance done assets
      const assetsList = Data[aname].options;

      setAssets(assetsList);

      /* Get Project by User */
      const pname = Data.findIndex((d) => d.field === 'project');
      const projectList = await getSetProjectList(0)
      // : localStorage.getItem('projectList') || [];
      Data[pname].options = projectList;
      if (projectList.length < 10) {
        setInfiniteScroll(false);
      }
      setProjects(projectList);


    } catch (error: any) {
      console.log(error);
    } finally {
      setShimmer(false);
    }


    /* Get CHECKLIST DETAILS ARRAY  */
    // const clist1 = Data.findIndex((d) => d.field === 'checklist1');
    // Data[clist1].options = await getCheckList();

    // const clist2 = Data.findIndex((d) => d.field === 'checklist2');
    // Data[clist2].options = Data[clist1].options;

    // const clist3 = Data.findIndex((d) => d.field === 'checklist3');
    // Data[clist3].options = Data[clist1].options;

    setData(Data);
  });

  useEffect(() => {
    // (async () => {
    //   const internetStatus = await Network.getStatus();
    //   setIsOnline(internetStatus.connected);

    //   Network.addListener('networkStatusChange', (status) => {
    //     setIsOnline(status.connected);
    //   });
    // })();
  }, []);


  async function searchNextProject($event: CustomEvent<void>) {
    try {
      const res = await getSetProjectList(currentPage + 1);
      if (res?.length) {

        setProjects([...projects, ...res]);
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setShimmer(false)
      setCurrentPage(currentPage + 1);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const Submit = (e: any) => {
    e.preventDefault();
    // const url = '/resource/Maintenance Request';
    for (const x in data) {
      if (data[x].required && !data[x].value) {
        present({
          message: t(`Utils.fieldMissing`),
          color: 'danger',
          duration: 2000,
        });
        return
      }
    }

    hitCreateApi();

    // if (valid) {
    //   uploadFiles();
    //   if (!isOnline) {
    //     const allData = JSON.parse(localStorage.getItem('allData') || '[]');
    //     const newData = { data, url };
    //     allData.push(newData);
    //     localStorage.setItem('allData', JSON.stringify(allData));
    //   }
    // }
  };

  // const uploadFiles = async () => {
  //   setShowLoading(true);
  //   for (const item in data) {
  //     if (data[item].type === 'file') {
  //       try {
  //         
  //         if (data[item].src?.length > 0) {
  //           const f_index = data.findIndex((v: any) => v.field === 'name1');
  //           
  //           const response = await fetch(data[item].src?.toString());
  //           const blob = await response.blob();
  //           const formData = new FormData();
  //           formData.append(
  //             'file',
  //             new File([blob], `${data[f_index].value}-${data[item].field}.${data[item].format}`, {
  //               type: `image/${data[item].format}`,
  //             })
  //           );

  //           formData.append('is_private', '0');
  //           formData.append('folder', 'Home');
  //           formData.append('doctype', 'Maintenance Request');
  //           var config: any = {
  //             method: 'post',
  //             url: '/method/upload_file',
  //             headers: {
  //               'Content-Type': 'application/json',
  //               Accept: 'application/json',
  //               Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
  //             },
  //             data: formData,
  //           };
  //           const res = await axios(config);
  //           data[item].value = res?.data?.message?.file_url;
  //           setData(data);
  //         }
  //       } catch (e: any) {
  //         data[item].value = '';
  //       }
  //     }
  //   }
  //   hitCreateApi();
  // };

  const hitCreateApi = async () => {
    const formData = new FormData();
    for (const item in data) {
      if (data[item].type === 'file') {
        try {

          if (data[item].src?.length > 0) {
            // const f_index = data.findIndex((v: any) => v.field === 'name1');

            const response = await fetch(data[item].src?.toString());
            const blob = await response.blob();
            formData.append(

              data[item]?.field,
              new File([blob], `${new Date()?.getTime()}-${data[item].field}.${data[item].format}`, {
                type: `image/${data[item].format}`,
              })
            );
          }
        } catch (e: any) {
          data[item].value = '';
        }
      } else {
        const field = data[item]?.field;
        const value = data[item]?.value;
        if (typeof field === 'string' && typeof value === 'string') {
          formData.append(field, value);
        }
      }
    }

    const config: any = {
      method: 'post',
      url: 'method/preflet_asset.api.create_maintenance_request',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData
    };

    setShowLoading(true);
    axios(config)
      .then(async (res) => {
        if (res.status === 200) {
          present({
            message: t('Maintenance.CreateM.successMRC'),
            duration: 2000,
          });
          reset();
          router.goBack();
        }
      })
      .catch(function (error) {
        console.log(error?.response?.data?.message);
        present({
          message: error?.response?.data?.message || "Something went wrong",
          color: 'danger',
          duration: 2000,
        });
      })
      .finally(() => {
        setShowLoading(false);
      });
  };

  const reset = () => {
    const formentry: any = [...data];
    let x: any;
    for (x in formentry) {
      if (data[x].type === 'select') {
        data[x].value = '';
      } else if (data[x].type === 'file') {
        data[x].value = '';
        data[x].src = '';
        data[x].format = '';
      } else {
        data[x].value = '';
      }
    }
    setData(formentry);
  };

  // const [showModal, setShowModal] = useState(false);

  // const edit = (temp: any) => {
  //   setActiveChId(temp);

  //   for (const x in data) {
  //     if (data[x].field === temp) {
  //       if (!data[x].value) {
  //         present({
  //           message: data[x].requiredText,
  //           color: 'danger',
  //           duration: 2000,
  //         });
  //       } else {
  //         setShowModal(true);
  //       }
  //     }
  //     // else if (data[x].name === temp) {
  //     //   
  //     //   setChArray(data[x].value);
  //     // }
  //   }
  // };

  /* Add button to add CHECKLIST in ARRAY */

  // const saveCheckList = (flag: any) => {
  //   if (checklist.activity && checklist.yes_or_no) {
  //     var tempList = [...chArray];
  //     
  //     tempList.push(checklist);
  //     setChArray(tempList);
  //     setChecklist({
  //       activity: '',
  //       yes_or_no: '',
  //       remarks: '',
  //     });
  //   } else {
  //     present({
  //       message: t('Utils.required'),
  //       color: 'danger',
  //       duration: 2000,
  //     });
  //   }
  // };

  /* Scan returns Asset Id to Map asset name */

  // const sendIdBackToPage = (id: any) => {
  //   try {
  //     mapAssetId(id);
  //   } catch (error: any) {
  //     console.log('failed while fetching data');
  //   }
  // };

  // const mapAssetId = (assetId: any) => {
  //   // eslint-disable-next-line array-callback-return
  //   createMaintenanceFields.map((element) => {
  //     if (element.field === 'asset') {
  //       element.value = assetId;
  //     }
  //   });
  //   setData(createMaintenanceFields);
  // };

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)
      const imageUrl = image.webPath;

      // Can be set to the src of an image now
      const formentry: any = [...data];
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      setData(formentry);
    } catch (e: any) {/*empty*/ }
  };

  // const deleteFunc = (item: any, index: any) => {
  //   var tempList = [...chArray];
  //   tempList = tempList.filter((item, i) => i !== index);
  //   setChArray(tempList);
  //   if (!tempList.length) {
  //     var tempData = [...data];
  //     // for (const item in tempData) {
  //     //   if (tempData[item].name === activeChId) {
  //     //     tempData[item].value = tempList;
  //     //   }
  //     // }
  //     setData(tempData);
  //     setChArray([]);
  //   }
  // };

  /* Save CHECKLIST  */
  // const saveCheckListData = () => {
  //   var tempData = [...data];
  //   for (const item in tempData) {
  //     if (tempData[item].name === activeChId) {
  //       tempData[item].value = chArray;
  //     }
  //   }
  //   setData(tempData);
  //   setChArray([]);
  //   setShowModal(false);
  // };

  async function searchNext($event: CustomEvent<void>) {
    const res = await getSetAssetNameWithNoMaintenance('', searchText, currentPage + 1);
    if (res.length) {

      setAssets([...assets, ...res]);
    }
    setCurrentPage(currentPage + 1);
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const setAssetInValue = (asset: any) => {
    const Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'asset');
    Data[aname].value = asset;
    setSelectedAsset(asset);
  }

  const handleSearch = async (text: any) => {
    setSearchText(text);
    const res = await getSetAssetNameWithNoMaintenance('', text, 1);
    if (res.length) {
      setAssets(res);
    } else {
      setAssets([])
    }
    setCurrentPage(1);
  }

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const setProjectInValue = (project: any) => {
    const Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'project');
    Data[aname].value = project;
    setSelectedProject(project);
  }

  return (
    <IonPage>
      <Header enableBackButton={'/maintenanceHome'} title={t('Maintenance.Create')} />
      <IonContent>
        <IonLoading

          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={t('Utils.pleasewait')}
          duration={5000}
        />

        {/* <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem' }}>
          <button onClick={() => setIsScan(true)} className="scan-button">
            <img src={ScanAsset} alt="scan" style={{ paddingTop: '1rem' }} />
            <p className="scan-text">{t('Assets.Scan')}</p>
          </button>
        </div> */}

        {/* <p className="fill-text">{t('Assets.CreateA.Fill')}</p> */}

        <form className="ion-margin-top" style={{ textAlign: 'center' }} onSubmit={(e) => Submit(e)}>
          <div className="responsive-form">
            {data.map((form_field: any, index: any) => {
              if (form_field.title) {
                return (
                  <p className="heading ion-margin form-title" key={`${form_field.title}-${index}`}>
                    {t(form_field.title)}
                  </p>
                );
              } else if (form_field.type === 'text') {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : ''}
                    </label>
                    <input
                      required={form_field.required}
                      className="basic-input"
                      placeholder={t(form_field.label)}
                      id={form_field.field}
                      style={{ marginTop: '0.5rem' }}
                      onChange={handleChange}
                      value={form_field.value}
                    />
                  </div>
                );
              }
              // else if (form_field.name === 'checklist') {
              //   return (
              //     <div key={`${form_field.field}-${index}`}>
              //       <div className="" style={{ padding: '10px' }}>
              //         <div className="input-container">
              //           <label className="label">{t(form_field.label)}</label>
              //           <Select
              //             className="custom-select"
              //             required={form_field.required}
              //             id={`${form_field.field}_choice`}
              //             name={form_field.field}
              //             onChange={handleSelectChange}
              //             value={form_field.value}
              //             disabled={form_field.disabled || false}
              //             key={`${form_field.field}-${index}`}
              //           >
              //             {form_field.options &&
              //               form_field.options.map(function (opt: any, indexSelect: any) {
              //                 return (
              //                   <MenuItem
              //                     value={`${opt}`}
              //                     key={`${form_field.field}-${indexSelect}`}
              //                     id={`${opt}_item_${indexSelect}`}
              //                   >
              //                     <section style={{ display: 'flex', height: '1rem' }}>
              //                       <p
              //                         style={{
              //                           color: 'rgba(31, 87, 148, 1)',
              //                           marginLeft: '0.2rem',
              //                           fontSize: '12px',
              //                           marginTop: '0px',
              //                         }}
              //                       >
              //                         {t(opt)}
              //                       </p>
              //                     </section>
              //                   </MenuItem>
              //                 );
              //               })}
              //           </Select>
              //         </div>
              //         <div>
              //           <div
              //             style={{ color: 'rgba(31, 87, 148, 1)', marginBottom: '1rem' }}
              //             className="flex justify-between input-container"
              //           >
              //             {data.map((e: any, item: any) => {
              //               if (e.name === form_field.field && e.value.length) {
              //                 return (
              //                   <div className="flex items-center">
              //                     <IonIcon
              //                       style={{ fontSize: '25px', marginLeft: '1rem', marginRight: '0.5rem' }}
              //                       src={listCircle}
              //                     />
              //                     <span>{e.value.length}</span>
              //                   </div>
              //                 );
              //               }
              //             })}
              //             <div className="flex justify-center items-center">
              //               <span
              //                 className="pointer"
              //                 onClick={() => {
              //                   edit(form_field.field);
              //                 }}
              //               >
              //                 <u>{t(form_field.label)}</u>
              //               </span>
              //               <IonIcon
              //                 className="pointer"
              //                 style={{ fontSize: '25px', marginLeft: '10px' }}
              //                 onClick={() => {
              //                   edit(form_field.field);
              //                 }}
              //                 src={addCircle}
              //               />
              //             </div>
              //           </div>
              //           {/* CheckList Detail Modal */}
              //           <IonModal isOpen={showModal}>
              //             <IonHeader>
              //               <IonToolbar color="primary">
              //                 <IonTitle>{t(`Assets.CreateA.edit`)} </IonTitle>
              //                 <IonButtons slot="end">
              //                   <IonButton
              //                     onClick={() => {
              //                       setShowModal(false);
              //                     }}
              //                   >
              //                     <IonIcon name="close" slot="icon-only"></IonIcon>
              //                   </IonButton>
              //                 </IonButtons>
              //               </IonToolbar>
              //             </IonHeader>
              //             <IonContent>
              //               <div>
              //                 {chArray.length ? (
              //                   <div>
              //                     <table style={{ margin: 'auto', marginTop: '2rem' }}>
              //                       <tr>
              //                         <th style={{ textAlign: 'center', padding: '10px' }}>
              //                           {t(`Maintenance.CreateM.activity`)}
              //                         </th>
              //                         <th style={{ textAlign: 'center', padding: '10px' }}>
              //                           {t('Maintenance.CreateM.yes_o')}
              //                         </th>
              //                         <th style={{ textAlign: 'center', padding: '10px' }}>
              //                           {t('Maintenance.CreateM.remarks')}
              //                         </th>
              //                         <th style={{ textAlign: 'center', padding: '10px' }}>Delete</th>
              //                       </tr>
              //                       {chArray &&
              //                         chArray.map((item: any, indexList: any) => {
              //                           return (
              //                             <tr>
              //                               <td style={{ textAlign: 'center' }}>{item.activity}</td>
              //                               <td style={{ textAlign: 'center' }}>{item.yes_or_no}</td>
              //                               <td style={{ textAlign: 'center' }}>{item.remarks}</td>
              //                               <span>
              //                                 <IonIcon
              //                                   className="pointer"
              //                                   style={{
              //                                     color: 'red',
              //                                     margin: 'auto',
              //                                     display: 'block',
              //                                     marginTop: '5px',
              //                                     padding: '5px',
              //                                   }}
              //                                   src={trash}
              //                                   onClick={() => deleteFunc(item, indexList)}
              //                                 />
              //                               </span>
              //                             </tr>
              //                           );
              //                         })}
              //                     </table>
              //                     <button
              //                       // onClick={() => saveCheckListData()}
              //                       style={{
              //                         margin: 'auto',
              //                         display: 'block',
              //                         marginTop: '2rem',
              //                         paddingLeft: '30px',
              //                         paddingRight: '30px',
              //                         paddingTop: '15px',
              //                         paddingBottom: '15px',
              //                         color: 'white',
              //                         background: '#1d508d',
              //                         borderRadius: '5px',
              //                         fontWeight: 'bold',
              //                         fontSize: '10px',
              //                       }}
              //                     >
              //                       {t('Utils.save')}
              //                     </button>
              //                   </div>
              //                 ) : (
              //                   ''
              //                 )}

              //                 <div className="responsive-form">
              //                   {createCheckList &&
              //                     createCheckList.map((element: any, index1: any) => {
              //                       if (element.title) {
              //                         return (
              //                           <p className="heading ion-margin form-title" key={`${element.title}-${index1}`}>
              //                             {t(element.title)}
              //                           </p>
              //                         );
              //                       } else if (element.type === 'text') {
              //                         return (
              //                           <div className="input-container" key={`${element.field}-${index1}`}>
              //                             <label className="label">{t(element.label)}</label>
              //                             <input
              //                               required={element.required}
              //                               className="basic-input"
              //                               placeholder={t(element.label)}
              //                               id={element.field}
              //                               style={{ marginTop: '0.5rem' }}
              //                               onChange={handleChangeCheck}
              //                               
              //                               value={checklist[element.field]}
              //                             />
              //                           </div>
              //                         );
              //                       } else if (element.type === 'select') {
              //                         return (
              //                           <div className="input-container" key={`${element.field}-${index}`}>
              //                             <label className="label">
              //                               {t(element.label)}
              //                               {element.required ? '*' : null}
              //                             </label>
              //                             <Select
              //                               className="custom-select"
              //                               required={element.required}
              //                               id={`${element.field}_choice`}
              //                               name={element.field}
              //                               onChange={handleSelectChangeCheck}
              //                               
              //                               value={checklist[element.field]}
              //                             >
              //                               {element.options &&
              //                                 element.options.map(function (opt: any, indexSelect: any) {
              //                                   return (
              //                                     <MenuItem
              //                                       value={`${opt}`}
              //                                       key={`${element.field}-${indexSelect}`}
              //                                       id={`${opt}_item_${indexSelect}`}
              //                                     >
              //                                       <section style={{ display: 'flex', height: '1rem' }}>
              //                                         <p
              //                                           style={{
              //                                             color: 'rgba(31, 87, 148, 1)',
              //                                             marginLeft: '0.2rem',
              //                                             fontSize: '12px',
              //                                             marginTop: '0px',
              //                                           }}
              //                                         >
              //                                           {t(opt)}
              //                                         </p>
              //                                       </section>
              //                                     </MenuItem>
              //                                   );
              //                                 })}
              //                             </Select>
              //                           </div>
              //                         );
              //                       }
              //                     })}
              //                   <button
              //                     className="submit-button"
              //                     onClick={() => {
              //                       saveCheckList(form_field.field);
              //                     }}
              //                   >
              //                     {t('Utils.addButton')}
              //                   </button>
              //                 </div>
              //               </div>
              //             </IonContent>
              //           </IonModal>
              //         </div>
              //       </div>
              //     </div>
              //   );
              // } 
              else if (form_field.field === 'asset') {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : null}
                    </label>
                    <p onClick={() => setSetAssetModalToggle(!assetModalToggle)} className='basic-input' style={{ marginTop: '0px', fontSize: '12px', color: '#a3a9b5' }}>{form_field.value || <span>{t('Utils.selectAsset')}</span>}</p>
                  </div>)
              }
              else if (form_field.field === 'project') {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(`Assets.CreateA.${form_field.field}`)}
                      {form_field.required ? '*' : null}
                    </label>
                    <p onClick={() => setProjectModal(!projectModal)} className='basic-input' style={{ marginTop: '0px', fontSize: '12px', color: '#a3a9b5' }}>{form_field.value || <span>{t('Utils.selectProject')}</span>}</p>
                  </div>)
              }

              else if (form_field.type === 'select' && !form_field.hide) {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : null}
                    </label>
                    {form_field.info ? (
                      <div className="tooltip">
                        <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                        <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                      </div>
                    ) : null}
                    <Select
                      className="custom-select"
                      required={form_field.required}
                      id={`${form_field.field}_choice`}
                      name={form_field.field}
                      onChange={handleSelectChange}
                      value={form_field.value}
                      disabled={form_field.disabled || false}
                    >
                      {form_field.options?.map(function (opt: any, indexSelect: any) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${indexSelect}`}
                            id={`${opt}_item_${indexSelect}`}
                          >
                            <section style={{ display: 'flex', height: '1rem' }}>
                              {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                              <p
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {t(opt)}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                );
              } else if (form_field.type === 'date' || form_field.type === 'datetime-local') {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : null}
                    </label>
                    <input
                      required={form_field.required}
                      className="date"
                      id={form_field.field}
                      placeholder={t(form_field.label)}
                      onChange={handleChange}
                      value={form_field.value}
                      type={form_field.type}
                    />
                  </div>
                );
              } else if (form_field.type === 'file') {
                return (
                  <div key={`${form_field.field}-${index}`}>
                    <IonButton size="small" color="light" onClick={() => takePicture(index)}>
                      {form_field.src ? t('Utils.change_image') : t('Utils.image')}
                    </IonButton>
                    <br />
                    {form_field.src ? <img className="preview-survey-img" src={form_field.src} alt="" /> : ''}
                  </div>
                );
              } else {
                return '';
              }
            })}
          </div>
          <button className="submit-button" type="submit">
            {t(`Maintenance.CreateM.CR`)}{' '}
          </button>
          <div style={{ marginBottom: '3rem' }}></div>
        </form>
        <IonModal
          isOpen={assetModalToggle}
          onDidDismiss={() => setSetAssetModalToggle(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Assets</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            <IonSearchbar
              className="data-list"
              value={searchText}

              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              onIonChange={(e) => handleSearch(e.detail.value!)}
            ></IonSearchbar>
            {shimmer && <Shimmer />}
            <IonList>
              {assets?.length ? assets.map((asset: any, index: any) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setSetAssetModalToggle(false);
                    setAssetInValue(asset)
                  }}
                >
                  <IonLabel>{asset}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={asset}
                    checked={asset === selectedAsset} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                </div>}
            </IonList>

            <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
              <IonInfiniteScrollContent
                loadingSpinner="bubbles"
                loadingText="Loading more data..."
              ></IonInfiniteScrollContent>
            </IonInfiniteScroll>
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={projectModal}
          onDidDismiss={() => setProjectModal(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Projects</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setProjectModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {shimmer && <Shimmer />}
            <IonList>
              {projects.length ? projects.map((project: any, index: number) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setProjectModal(false);
                    setProjectInValue(project)
                  }}
                >
                  <IonLabel>{project}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={project}
                    checked={project === selectedProject} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
                </div>}
            </IonList>
            {infiniteScroll ?
              <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNextProject(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll> : ''}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default CreateMainten1;
