
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import {
  IonContent,
  IonPage,
  IonModal,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonButtons,
  IonButton,
  IonIcon,
  useIonToast,
  IonLoading,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonBadge,
  IonInfiniteScroll,
  IonCheckbox,
  IonItem,
  IonList,
  IonSearchbar,
  IonSkeletonText,
  IonInfiniteScrollContent,
} from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { barcodeOutline, checkmarkCircleOutline, close, createOutline, ellipseOutline, informationCircle, warningOutline } from 'ionicons/icons';
import { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';

import axios from '../../axios';
import Header from '../../components/Header';
import { createServiceFields } from '../../models/createServiceFields';
import { AppContext } from '../../providers/AppContext';
import { getFaultType, getSetAssetNameWithNoService, isFormDataEmpty, requestPermissions } from '../../utils';




const ServiceDetail: React.FC = () => {
  const { state } = useContext(AppContext);

  const location = useLocation();

  const { t } = useTranslation();
  const history = useHistory();
  const [data, setData] = useState<any>({
    name: '',
  });
  const [primaryData, setPrimaryData] = useState<any>({});

  let component_type: [] = [];
  let component_name: [] = [];

  const [present] = useIonToast();

  const [showModal, setShowModal] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [_data, set_Data] = useState<any>(createServiceFields);

  const [selectedTab, setSelectedTab] = useState('details');

  const [complaints, setComplaints] = useState([]);
  const [showComplaintsData] = useState([
    {
      title: 'generalInfo'
    },
    {
      field: 'asset'
    },
    {
      field: 'status'
    },
    {
      title: 'streetLightInfo'
    },
    {
      field: 'location'
    },
    {
      title: 'problemDescription'
    },
    {
      field: 'light_doesnt_come_on'
    },
    {
      field: 'light_doesnt_turn_off'
    },
    {
      field: 'damaged_or_exposed_wires'
    },
    {
      field: 'broken_lights_or_pole'
    },
    {
      field: 'other'
    },
    {
      field: 'comments'
    },
    {
      title: 'contactInfo'
    },
    {
      field: 'full_name'
    },
    {
      field: 'phone_number'
    },
    {
      field: 'email_address'
    },
    {
      field: 'email_sent_at'
    },
    {
      title: 'operations'
    },
    {
      field: 'repair_request'
    }
  ]);

  // const [scanToggle, setScanToggle] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  const [assets, setAssets] = useState<any>([]);
  const [searchText, setSearchText] = useState('');
  const [assetModalToggle, setSetAssetModalToggle] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState();

  const [shimmer, setShimmer] = useState(false);




  const getAssetIndividual = async () => {
    const res: any = history?.location?.state;
    const config: any = {
      method: 'get',
      url: `/resource/Repair Request/${res}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      const res = await axios(config);
      setData(res.data.data);
    } catch (error: any) {
      console.log(error);
    }

    /* Fetch Public Complaint */
    const configuration: any = {
      method: 'get',
      url: `resource/Public Complaints?limit_page_length=10&limit_start=0&fields=["*"]&filters=[["status","!=","Completed"],["repair_request","=","${res}"]]`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    try {
      const response = await axios(configuration);
      setComplaints(response?.data?.data);
    } catch (error: any) {
      console.log(error)
    }
  };

  useEffect(() => {
    if (history?.location?.state) {
      getAssetIndividual();
    }
  }, [history.location.state]);

  const edit = () => {
    setShowLoading(true);
    setShowModal(true);
    callAssetById();
  };


  const handleChange = (event: any) => {
    const formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    const change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    set_Data(formentry);
  };

  const handleSelectChange = (event: any) => {
    const formentry: any = [..._data];

    const select = event?.target?.name?.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    set_Data(formentry);
  };

  // Mapping fields after scan
  const callAssetById = async () => {
    const fOptions = await getFaultType();
    let assetOptions: any = [];
    try {
      setShimmer(true)
      assetOptions = await getSetAssetNameWithNoService(data.name, '', currentPage); // Filter maintenance done assets
      setAssets(assetOptions);
    } catch (error: any) {
      console.log(error);
    } finally {
      setShimmer(false)
    }

    axios({
      method: 'get',
      url: `/resource/Repair Request/${data.name}`,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        const temp = res?.data?.data;
        setPrimaryData(temp);
        for (const x in createServiceFields) {
          const f = createServiceFields[x].field;
          if (f === 'project') {
            createServiceFields[x].value = temp['project'];
            createServiceFields[x].type = 'text';
            createServiceFields[x].disabled = true;
          }
          if (f === 'asset') {
            createServiceFields[x].value = temp[f];
            createServiceFields[x].options = assetOptions;
          }
          if (f === 'fault_type') {
            createServiceFields[x].value = temp[f];
            createServiceFields[x].options = fOptions;
          } else if (f) {
            createServiceFields[x].value = temp[f];
          }
        }
        set_Data(createServiceFields);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setShowLoading(false);
        set_Data(createServiceFields);
      });

    /* Get Component Category */
    axios({
      method: 'get',
      url: '/method/preflet_asset.api.get_component_category',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        const Data = [..._data];
        component_name = res.data.data.map((ct: any) => ct.name);
        const cname = Data.findIndex((d) => d.field === 'component_name');
        Data[cname].options = component_name;
        set_Data(Data);
      })
      .catch((err) => {
        console.log(err);
      });
    /* Get Component Type */
    axios({
      method: 'get',
      url: '/method/preflet_asset.api.get_component_type',
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    })
      .then((res) => {
        const Data = [..._data];
        component_type = res.data.data.map((ct: any) => ct.name);
        const ctype = Data.findIndex((d) => d.field === 'component_type');
        Data[ctype].options = component_type;
        set_Data(Data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const Submit = async (e: any) => {
    e.preventDefault();
    const formData = new FormData();
    for (const item in _data) {
      if (_data[item]?.required && !_data[item]?.value) {
        present({
          message: t(`Utils.fieldMissing`),
          color: 'danger',
          duration: 2000,
        });
        return
      }
      const field = _data[item]?.field;
      const value = _data[item]?.value;
      if (_data[item]?.type === 'file' && _data[item]?.src) {
        try {

          const response = await fetch(_data[item]?.src?.toString());
          const blob = await response.blob();

          formData.append(field, new File([blob], `${data.name}-attachment.${_data[item].format}`, {

            type: `image/${_data[item].format}`,
          })
          );
        } catch (e: any) {
          console.log(e)
        }
      }

      if (field === 'replaced_by' || field === 'old_asset') {
        formData.append(field, value);
      } else if (_data[item].field === 'asset' && primaryData[field] !== data.asset) {
        formData.append('asset', data.asset);
      } else if (typeof field === 'string' && typeof value === 'string' && value !== '' && value !== undefined && value !== null && value !== 'null' && value !== 'undefined' && value !== 'NaN') {
        if (field && (primaryData[field] !== value))
          formData.append(field, value);
      }
    }


    const config: any = {
      method: 'put',
      url: `/method/preflet_asset.api.update_repair_request/${data.name}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };

    // setShowLoading(true);

    //   if (!isOnline) {
    //     console.log('not online');

    //     const allData = JSON.parse(localStorage.getItem('allData') || '[]');

    //     const newData = { data, url };
    //     allData.push(newData);

    //     localStorage.setItem('allData', JSON.stringify(allData));
    //   } else {
    if (!isFormDataEmpty(formData)) {
      setShowLoading(true);
      axios(config)
        .then(async () => {
          present({
            message: t('Service.serviceUpdate'),
            duration: 2000,
          });
          setShowModal(false);
          reset();
          getAssetIndividual();
        })
        .catch(function (error) {
          console.log(error?.response?.data);
          const x = error?.response?.data?._server_messages;
          console.log(x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')));
          present({
            message: x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')),
            color: 'danger',
            duration: 2000,
          });
        })
        .finally(() => {
          setShowLoading(false);
        });
    } else {
      setShowModal(false);
    }

  };

  const reset = () => {
    for (const x in _data) {
      if (_data[x].type === 'file') {
        _data[x].src = '';
        _data[x].format = '';
        _data[x].value = '';
      } else
        _data[x].value = '';
    }
    for (const x in createServiceFields) {
      const f = createServiceFields[x].field;
      if (f === 'asset') {
        createServiceFields[x].value = '';
        createServiceFields[x].options = [];
        createServiceFields[x].type = 'select';
        createServiceFields[x].disabled = false;
      }
    }
  };

  const closeModal = () => {
    setShowModal(false);
    for (const x in createServiceFields) {
      const f = createServiceFields[x].field;
      if (f === 'asset') {
        createServiceFields[x].value = '';
        createServiceFields[x].options = [];
        createServiceFields[x].type = 'select';
        createServiceFields[x].disabled = false;
      }
    }
  };

  const getValueFromData = (key: string) => {

    return data[key] || '';
  };

  const openMap = (coordinates: any) => {
    const url = 'https://www.google.com.sa/maps/search/' + coordinates;
    window.open(url, '_blank');
  };

  const handleScan = (code: any, key: any) => {
    if (code) {
      try {
        const formentry: any = [..._data];
        const f_index = formentry.findIndex((v: any) => v.field === key);
        const change_form_object = formentry[f_index];
        change_form_object.value = code;
        formentry[f_index] = change_form_object;
        set_Data(formentry);
      } catch (error: any) {
        console.log(error);
      }
    }
  }

  /* Remove Quick Status Image by cross click */
  const emptyQSImg = (field: any) => {
    const formentry: any = [..._data];
    const f_index = formentry.findIndex((v: any) => v.field === field);
    formentry[f_index].value = null;
    formentry[f_index].src = null;
    formentry[f_index].format = null;
    set_Data(formentry);
  };

  async function searchNext($event: CustomEvent<void>) {
    try {
      const res = await getSetAssetNameWithNoService('', searchText, currentPage + 1);
      if (res.length) {

        setAssets([...assets, ...res]);
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setShimmer(false)
      setCurrentPage(currentPage + 1);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const handleSearch = async (text: string | undefined) => {
    if (text !== undefined) {
      setSearchText(text);
      setShimmer(true)
      try {
        const res = await getSetAssetNameWithNoService('', text, 1);
        if (res.length) {
          setAssets(res);
        }
      } catch (error: any) {
        console.log(error)
      } finally {
        setShimmer(false)
        setCurrentPage(1);
      }
    }
  }

  const setAssetInValue = (asset: any) => {

    data['asset'] = asset;
    setSelectedAsset(asset);
  }

  const takeQSPicture = async (field: any) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      const imageUrl = image.webPath;

      // Can be set to the src of an image now
      const formentry: any = [..._data];
      const f_index = formentry.findIndex((v: any) => v.field === field);
      formentry[f_index].value = null;
      formentry[f_index].src = imageUrl;
      formentry[f_index].format = image.format;
      set_Data(formentry);
    } catch (e: any) {

      console.log('Error: ', e);

      if (e?.toString()?.includes('cancelled')) {

        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }

      else if (e?.toString()?.includes('denied') || e?.includes('access')) {

        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {

        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  const getScan = async (key: any) => {
    const info = await Device.getInfo();
    if (info?.platform === 'web') {
      present({
        message: t('Utils.scannerNotSupported'),
        duration: 2000,
      });
      return
    }
    try {
      BarcodeScanner.isSupported().then((result) => {
        if (!result.supported) {
          present({
            message: t('Utils.unsupportDevice'),
            duration: 2000,
          });
        }
      });

      const granted = await requestPermissions();
      if (!granted) {
        present({
          message: t('Utils.noPermission'),
          duration: 2000,
        });
        return;
      }
      const check = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
      if (!check.available)
        BarcodeScanner.installGoogleBarcodeScannerModule()
      const { barcodes } = await BarcodeScanner.scan();
      const scannedValue = barcodes[0]?.displayValue
      handleScan(scannedValue, key);
    } catch (error: any) {
      console.log(error)
    }
  };


  return (
    <IonPage>
      <Header enableBackButton={location.search ? 'goBack' : '/serviceHistory'} title={t('Service.Detail')} />
      <IonModal isOpen={showModal} onDidDismiss={() => closeModal()}>
        <IonLoading
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
        <IonHeader>
          <IonToolbar color="primary">
            <IonTitle>{t('Service.edit')} {data.name} </IonTitle>
            <IonButtons slot="end">
              <IonButton
                onClick={() => {
                  setShowModal(false);
                }}
              >
                <IonIcon icon={close} slot="icon-only"></IonIcon>
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent className="ion-padding">
          <form onSubmit={(e) => Submit(e)}>
            {_data.map((form_field: any, index: any) => {
              if (form_field.title) {
                return (
                  <p className="form-title" key={`${form_field.title}-${index}`}>
                    {t(form_field.title)}
                  </p>
                );
              } else if (form_field.type === 'text') {
                return (
                  <div key={`${form_field.label}-${index}`} className="input-container">
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : ''}
                    </label>
                    <input
                      required={form_field.required}
                      className="basic-input"
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(form_field.label)}
                      onChange={handleChange}
                      value={form_field.value}
                      disabled={form_field.disabled}
                      style={{
                        background: form_field.disabled ? '#F1F7F5' : ''
                      }}
                    />
                  </div>
                );
              }
              else if (form_field.field === 'asset') {
                return (
                  <div className="input-container" key={`${form_field.field}-${index}`}>
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : null}
                    </label>
                    <p onClick={() => {

                      setSelectedAsset(data['asset'])
                      setSetAssetModalToggle(!assetModalToggle)
                    }} className='basic-input' style={{ marginTop: '0px' }}>{

                        data['asset'] || <span style={{ color: 'gray' }}>{t('Utils.selectAsset')}</span>}</p>
                  </div>)
              }
              else if (form_field.type === 'barcode') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                  >
                    <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                      {t(`${form_field.label}`)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <div className="flex items-center justify-between basic-input">
                      <input
                        required={form_field.required}
                        className="gps-input"
                        id={form_field.field}
                        placeholder={t(`${form_field.placeholder}`)}
                        value={form_field.value}
                        disabled={true}
                        style={{ width: '80%' }}
                      />
                      <IonIcon onClick={() => getScan(form_field.field)} className="gps-icon" src={barcodeOutline} />
                    </div>
                  </div>
                );
              }
              else if (form_field.type === 'textarea') {
                return (
                  <div key={`${form_field.label}-${index}`} className="input-container">
                    <label className="label">{t(form_field.label)}</label>
                    <textarea
                      className="textarea"
                      required={form_field.required}
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(form_field.label)}
                      onChange={handleChange}
                      value={form_field.value}
                    />
                  </div>
                );
              } else if (form_field.type === 'select') {
                return (
                  <div key={`${form_field.label}-${index}`} className="input-container">
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : ''}
                    </label>
                    {form_field.info ? (
                      <div className="tooltip">
                        <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                        <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                      </div>
                    ) : null}
                    <Select
                      className="custom-select"
                      required={form_field.required}
                      id={`${form_field.field}_choice`}
                      name={form_field.field}
                      onChange={handleSelectChange}
                      value={form_field.value}
                      disabled={form_field.disabled || false}
                      key={`${form_field.field}-${index}`}
                    >
                      {form_field.options?.map(function (opt: any, indexSelect: any) {
                        return (
                          <MenuItem
                            value={`${opt}`}
                            key={`${form_field.field}-${indexSelect}`}
                            id={`${opt}_item_${indexSelect}`}
                          >
                            <section style={{ display: 'flex', height: '1rem' }}>
                              {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                              <p
                                style={{
                                  color: 'rgba(31, 87, 148, 1)',
                                  marginLeft: '0.2rem',
                                  fontSize: '12px',
                                  marginTop: '0px',
                                }}
                              >
                                {t(opt)}
                              </p>
                            </section>
                          </MenuItem>
                        );
                      })}
                    </Select>
                  </div>
                );
              }
              else if (form_field.type === 'file') {
                return (
                  <div
                    id={`${form_field.field}-${index}`}
                    className="input-container"
                    key={`${form_field.field}-${index}`}
                    style={{ marginTop: '1.5rem' }}
                  >
                    <label id={`label-${form_field.field}`} className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : null}{' '}
                    </label>
                    <IonButton
                      style={{ marginTop: '0.5rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                      size="small"
                      color="light"
                      onClick={() => takeQSPicture(form_field.field)}
                    >
                      {
                        form_field.value || form_field['src'] ? t('Utils.change_image') : t('Utils.image')}
                    </IonButton>

                    <div className="flex justify-center items-center">
                      {
                        form_field['value'] ? (
                          <img
                            src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${form_field['value']}`}
                            alt=""
                            style={{
                              width: '60%',
                              margin: 'auto',
                              display: 'block',
                              border: '1px solid lightgray',
                              borderRadius: '12px',
                            }}
                          />
                        ) : (
                          ''
                        )}
                      {
                        form_field['src'] ? <img className="preview-survey-img" src={form_field['src']} alt="" /> : ''}
                      {
                        form_field['src'] ? (
                          <IonIcon className="close-icon" onClick={() => emptyQSImg(form_field.field)} src={close} />
                        ) : (
                          ''
                        )}
                    </div>
                  </div>
                );
              }
              else if (form_field.type === 'date' || form_field.type === 'datetime-local') {
                return (
                  <div key={`${form_field.label}-${index}`} className="input-container">
                    <label className="label">
                      {t(form_field.label)}
                      {form_field.required ? '*' : ''}
                    </label>
                    <input
                      required={form_field.required}
                      className="date"
                      key={`${form_field.field}-${index}`}
                      id={form_field.field}
                      placeholder={t(form_field.label)}
                      onChange={handleChange}
                      value={form_field.value}
                      type={form_field.type}
                    />
                  </div>
                );
              } else {
                return '';
              }
            })}
            <button className="submit-button" type="submit">
              {t(`Service.ServiceCreate.save`)}
            </button>
          </form>
        </IonContent>
      </IonModal>
      <IonModal
        isOpen={assetModalToggle}
        onDidDismiss={() => setSetAssetModalToggle(false)}
      ><IonHeader>
          <IonToolbar>
            <IonTitle>Assets</IonTitle>
            <IonButtons slot="end">
              <IonButton onClick={() => setSetAssetModalToggle(false)}>Close</IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent>
          <IonSearchbar
            className="data-list"
            value={searchText}
            onIonChange={(e) => handleSearch(e.detail.value)}
          ></IonSearchbar>
          {shimmer && <Shimmer />}
          <IonList>
            {assets?.length ? assets.map((asset: any, index: any) => (
              <IonItem
                key={index}
                onClick={() => {
                  setSetAssetModalToggle(false);
                  setAssetInValue(asset)
                }}
              >
                <IonLabel>{asset}</IonLabel>
                <IonCheckbox
                  slot="end"
                  value={asset}
                  checked={asset === selectedAsset} />
              </IonItem>
            )) :
              <div style={{ margin: 'auto', display: 'block' }}>
                <p className='text-center' style={{ color: 'gray' }}>No Asset found</p>
              </div>}
          </IonList>

          <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
            <IonInfiniteScrollContent
              loadingSpinner="bubbles"
              loadingText="Loading more data..."
            ></IonInfiniteScrollContent>
          </IonInfiniteScroll>
        </IonContent>
      </IonModal>
      <IonContent>

        <div className="preview-header">
          <p className="preview-title" style={{ maxWidth: '80%' }}>{data.name}</p>
          {selectedTab === 'details' && <div className="flex">
            {state.permissions.service_request_update &&
              <IonIcon
                className="action-icon"
                onClick={() => {
                  edit();
                }}
                src={createOutline}
              /> || null}
          </div>}
        </div>

        <IonSegment value={selectedTab}>
          <IonSegmentButton onClick={() => setSelectedTab('details')} value="details">
            <IonLabel>{t('Service.details')}</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton onClick={() => setSelectedTab('complaint')} value="complaint" >
            <IonLabel style={{ display: 'flex', alignItems: 'center' }}>{t('Service.pc')} {complaints.length ? <IonBadge style={{ margin: '5px' }} slot="end">{complaints.length}</IonBadge> : ''}
            </IonLabel>
          </IonSegmentButton>
        </IonSegment>
        {selectedTab === 'details' ?
          createServiceFields.map((item: any, index) => {
            if (item.title) {
              return (
                <p key={index} className="form-title">
                  {t(item.title)}
                </p>
              );
            }
            return (
              <div key={index} className="data-list">
                {item.field && item.type !== 'file' ? (
                  <div className="preview-container">
                    <p className="preview-key">{t(`Service.ServiceCreate.${item.field}`)}</p>
                    <p className="preview-value">
                      {

                        data[item.field] ? data[item.field] : '-'
                      }
                    </p>
                  </div>
                ) : (
                  ''
                )}
                {item.field && item.type === 'file' ? (
                  <div className="preview-container">
                    <p className="preview-key">{t(`Service.ServiceCreate.${item.field}`)}</p>
                    {getValueFromData(item.field) ?
                      <img
                        src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${getValueFromData(item.field)}`}
                        alt=""
                        style={{
                          width: '40%',
                          marginLeft: 'auto',
                          display: 'block',
                          border: '1px solid lightgray',
                          borderRadius: '12px',
                          marginBottom: '10px',
                        }}
                      /> : '-'}
                  </div>
                ) : (
                  ''
                )}
              </div>
            );
          })
          : complaints.length > 0 ?
            showComplaintsData.map((item: any, index) => {
              if (item.title) {
                return (
                  <p key={index} className="form-title">
                    {t(`Service.Complaint.${item.title}`)}
                  </p>
                );
              }
              return (
                <div key={index} className="data-list">
                  {item.field === 'broken_lights_or_pole' || item.field === 'damaged_or_exposed_wires' || item.field === 'light_doesnt_turn_off' || item.field === 'light_doesnt_come_on' || item.field === 'other' ? (
                    <div className="preview-container">
                      <p className="preview-key">{t(`Service.Complaint.${item.field}`)}</p>
                      <p className="preview-value">
                        {
                          complaints.length && complaints[0][item.field] ? <IonIcon src={checkmarkCircleOutline} slot="icon-only" style={{ fontSize: '1.5rem' }}></IonIcon> : <IonIcon src={ellipseOutline} slot="icon-only" style={{ fontSize: '1.5rem' }}></IonIcon>
                        }
                      </p>
                    </div>
                  ) : (
                    <div className="preview-container">
                      <p className="preview-key">{t(`Service.Complaint.${item.field}`)}</p>
                      <p className="preview-value">
                        {item.field === 'location' ?
                          <span onClick={() => openMap(complaints.length && complaints[0][item.field])} className="preview-value pointer underline">
                            {
                              complaints.length && complaints[0][item.field] ? complaints[0][item.field] : '-'
                            }
                          </span> :
                          complaints.length && complaints[0][item.field] ? complaints[0][item.field] : '-'
                        }
                      </p>
                    </div>
                  )}
                </div>
              )
            }) : <div style={{ color: 'rgba(31, 87, 148, 1)' }}>
              <IonIcon src={warningOutline} style={{ fontSize: '4rem', margin: 'auto', display: 'block', marginTop: '4rem', textAlign: 'center' }} />
              <p style={{ fontSize: '14px', textAlign: 'center', color: 'gray' }}>{t('Service.Complaint.noComplaint')}</p>
            </div>}
      </IonContent>
    </IonPage>
  );
};

export default ServiceDetail;
