import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import TranslationEn from './locale/en/translation.json';
import TranslationPt from './locale/pt/translation.json';

const resources = {
  en: {
    translation: TranslationEn,
  },
  pt: {
    translation: TranslationPt,
  },
};

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend

  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    fallbackLng: localStorage.getItem('lang') || 'en',
    resources,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  });

export default i18n;
