import { IonContent, IonToolbar, IonSearchbar, IonPage, IonItem } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Flag from '../../assets/Flag.svg';
import Horn from '../../assets/Horn.svg';
import Rocket from '../../assets/Rocket.svg';
import Sun from '../../assets/Sun.svg';
import Header from '../../components/Header';
import ScanButton from '../../components/ScanButton';


const CompCatalogue: React.FC = () => {
  /*const [data, setData] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    username: "",
    full_name: "",
    gender: "",
    phone: "",
    birth_date: ""
})


const url = '/preflet_asset.api.get_profile_details'


var config: any = {
    method: 'get',
    url,
    headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
    'Authorization': `Basic ${localStorage.getItem('hyprism_login')}`
    },

};

useEffect(() => {
    async function GetData() {
    await axios(config)
        .then((response) => {
        var Data = { ...data }
        Data.first_name = response.data.data.first_name
        Data.last_name = response.data.data.last_name
        Data.email = response.data.data.email
        Data.username = response.data.data.username
        Data.full_name = response.data.data.full_name
        Data.gender = response.data.data.gender
        Data.phone = response.data.data.phone
        Data.birth_date = response.data.data.birth_date
        setdata(Data)
        });

    }

    GetData()
})*/

  const { t } = useTranslation();

  const [searchText, setSearchText] = useState('');
  const [filteredSearch, setFilteredSearch] = useState([
    {
      icon: {},
      name: '',
      compID: '',
    },
  ]);

  const [SEARCH, setSEARCH] = useState([
    {
      icon: { Flag },
      name: 'Bulb',
      compID: ' X12F537',
    },
    {
      icon: { Rocket },
      name: 'Bulb',
      compID: ' X12F537',
    },
    {
      icon: { Sun },
      name: 'Bulb',
      compID: ' X12F537',
    },
    {
      icon: { Horn },
      name: 'Bulb',
      compID: ' X12F537',
    },
  ]);

  useEffect(() => {
    const tempSearchResult = SEARCH.filter((ele) => ele.name.includes(searchText));
    setFilteredSearch([...tempSearchResult]);
  }, [searchText]);

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>

        <IonItem className="heading" style={{ marginTop: '0.2rem' }}>
          <p className="ion-margin">{t('Component.Catalogue')}</p>
        </IonItem>

        <IonSearchbar
          value={searchText}
          style={{ height: '10vh', width: '90vw', margin: 'auto', marginTop: '1rem' }}
          onIonChange={(e) => setSearchText(e.detail.value!)}
          placeholder="Search Components"
        ></IonSearchbar>

        <div
          className="ion-margin"
          style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
        >
          <section style={{ display: 'flex' }}>
            <div
              style={{
                float: 'right',
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                marginLeft: '1rem',
                marginTop: '1rem',
                border: '3.14286px solid #F5F7FA',
              }}
              className="formsmcss"
            >
              <img src={Flag} alt="Flag" style={{ height: '30px', marginTop: '1vh', marginLeft: '-0.5rem' }} />
            </div>

            <section style={{ flex: 'column' }}>
              <h4
                style={{ marginLeft: '0.4rem', marginTop: '1.3rem', color: 'rgba(29, 80, 141, 1)', fontSize: 'large' }}
              >
                Bulb
              </h4>
              <p
                style={{
                  marginLeft: '0.5rem',
                  color: 'rgba(146, 155, 171, 1)',
                  marginTop: '-0.5rem',
                  fontSize: '11px',
                }}
              >
                {t('Assets.Details.CID')}: X12F537
              </p>
            </section>
          </section>
          <section style={{ flex: 'column' }}>
            <p
              style={{
                color: 'rgba(29, 80, 141, 1)',
                float: 'right',
                fontSize: '13px',
                flex: 'column',
                marginTop: '-2.9rem',
                paddingRight: '1rem',
              }}
            >
              {t('Assets.Details.Details')}
            </p>
            <p
              style={{
                color: 'rgba(146, 155, 171, 1)',
                float: 'right',
                marginTop: '-1.5rem',
                fontSize: '10px',
                paddingRight: '1rem',
              }}
            >
              7 {t('Header.Notifs.HO')}
            </p>
          </section>
        </div>

        <div
          className="ion-margin"
          style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
        >
          <section style={{ display: 'flex' }}>
            <div
              style={{
                float: 'right',
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                marginLeft: '1rem',
                marginTop: '1rem',
                border: '3.14286px solid #F5F7FA',
              }}
              className="formsmcss"
            >
              <img src={Rocket} alt="rocket" style={{ height: '30px', marginTop: '1vh', marginLeft: '-0.5rem' }} />
            </div>
            <section style={{ flex: 'column' }}>
              <h4
                style={{ marginLeft: '0.4rem', marginTop: '1.3rem', color: 'rgba(29, 80, 141, 1)', fontSize: 'large' }}
              >
                Component Name
              </h4>
              <p
                style={{
                  marginLeft: '0.5rem',
                  color: 'rgba(146, 155, 171, 1)',
                  marginTop: '-0.5rem',
                  fontSize: '11px',
                }}
              >
                {t('Assets.Details.CID')}: X12F537
              </p>
            </section>
          </section>
          <section style={{ flex: 'column' }}>
            <p
              style={{
                color: 'rgba(29, 80, 141, 1)',
                float: 'right',
                fontSize: '13px',
                flex: 'column',
                marginTop: '-2.9rem',
                paddingRight: '1rem',
              }}
            >
              {t('Assets.Details.Details')}
            </p>
            <p
              style={{
                color: 'rgba(146, 155, 171, 1)',
                float: 'right',
                marginTop: '-1.5rem',
                fontSize: '10px',
                paddingRight: '1rem',
              }}
            >
              7 {t('Header.Notifs.HO')}
            </p>
          </section>
        </div>

        <div
          className="ion-margin"
          style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
        >
          <section style={{ display: 'flex' }}>
            <div
              style={{
                float: 'right',
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                marginLeft: '1rem',
                marginTop: '1rem',
                border: '3.14286px solid #F5F7FA',
              }}
              className="formsmcss"
            >
              <img src={Sun} alt="Flag" style={{ height: '30px', marginTop: '1vh', marginLeft: '-0.5rem' }} />
            </div>
            <section style={{ flex: 'column' }}>
              <h4
                style={{ marginLeft: '0.4rem', marginTop: '1.3rem', color: 'rgba(29, 80, 141, 1)', fontSize: 'large' }}
              >
                Component Name
              </h4>
              <p
                style={{
                  marginLeft: '0.5rem',
                  color: 'rgba(146, 155, 171, 1)',
                  marginTop: '-0.5rem',
                  fontSize: '11px',
                }}
              >
                {t('Assets.Details.CID')}: X12F537
              </p>
            </section>
          </section>
          <section style={{ flex: 'column' }}>
            <p
              style={{
                color: 'rgba(29, 80, 141, 1)',
                float: 'right',
                fontSize: '13px',
                flex: 'column',
                marginTop: '-2.9rem',
                paddingRight: '1rem',
              }}
            >
              {t('Assets.Details.Details')}
            </p>
            <p
              style={{
                color: 'rgba(146, 155, 171, 1)',
                float: 'right',
                marginTop: '-1.5rem',
                fontSize: '10px',
                paddingRight: '1rem',
              }}
            >
              7 {t('Header.Notifs.HO')}
            </p>
          </section>
        </div>

        <div
          className="ion-margin"
          style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
        >
          <section style={{ display: 'flex' }}>
            <div
              style={{
                float: 'right',
                height: '45px',
                width: '45px',
                borderRadius: '50%',
                marginLeft: '1rem',
                marginTop: '1rem',
                border: '3.14286px solid #F5F7FA',
              }}
              className="formsmcss"
            >
              <img src={Horn} alt="Flag" style={{ height: '30px', marginTop: '1vh', marginLeft: '-0.5rem' }} />
            </div>
            <section style={{ flex: 'column' }}>
              <h4
                style={{ marginLeft: '0.4rem', marginTop: '1.3rem', color: 'rgba(29, 80, 141, 1)', fontSize: 'large' }}
              >
                Component Name
              </h4>
              <p
                style={{
                  marginLeft: '0.5rem',
                  color: 'rgba(146, 155, 171, 1)',
                  marginTop: '-0.5rem',
                  fontSize: '11px',
                }}
              >
                {t('Assets.Details.CID')}: X12F537
              </p>
            </section>
          </section>
          <section style={{ flex: 'column' }}>
            <p
              style={{
                color: 'rgba(29, 80, 141, 1)',
                float: 'right',
                fontSize: '13px',
                flex: 'column',
                marginTop: '-2.9rem',
                paddingRight: '1rem',
              }}
            >
              {t('Assets.Details.Details')}
            </p>
            <p
              style={{
                color: 'rgba(146, 155, 171, 1)',
                float: 'right',
                marginTop: '-1.5rem',
                fontSize: '10px',
                paddingRight: '1rem',
              }}
            >
              7 {t('Header.Notifs.HO')}
            </p>
          </section>
        </div>

        <IonToolbar></IonToolbar>
        <ScanButton isAssetPage={false} />
      </IonContent>
    </IonPage>
  );
};

export default CompCatalogue;
