import { IonText } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import Image from '../assets/no-records.png';

const NoRecords: React.FC<{ width: string; smallText: boolean }> = ({ width = '80%', smallText = false }) => {
  const { t } = useTranslation();
  return (
      <div  style={{margin: 'auto'}} className="ion-text-center">
        <img src={Image} alt={t('Utils.no_records')} width={width.toString()} />
        <IonText color="primary">
          {smallText ? <h6>{t('Utils.no_records')}</h6> : <h2>{t('Utils.no_records')}</h2>}
        </IonText>
      </div>
  );
};

export default NoRecords;
