
import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import { Network } from '@capacitor/network';
import { IonContent, IonPage, useIonToast, IonLoading, useIonViewWillEnter, useIonRouter, IonIcon, IonButton, IonModal, IonHeader, IonToolbar, IonButtons, IonInfiniteScroll, IonInfiniteScrollContent, IonList, IonItem, IonLabel, IonCheckbox, IonTitle, IonSkeletonText, IonSpinner } from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { barcodeOutline, close, informationCircle } from 'ionicons/icons';
import { useState, useEffect, } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';

import Gps from '../../assets/gps.png';
import axios from '../../axios';
import Header from '../../components/Header';
import { createAssetFields } from '../../models/createAssetFields';
import {
  getSetAssetCategory,
  getSetAssetType,
  getSetProjectList,
  // getAssetComponentByLabel,
  offlineSaveToTable,
  requestPermissions,
  // constant,
} from '../../utils';


const CreateAsset: React.FC = () => {

  // const location = useLocation() as Location<{ barcode?: string }>;

  const router = useIonRouter();
  // const location = useLocation();
  const history = useHistory();
  const [present] = useIonToast();

  const { t } = useTranslation();

  const [showLoading, setShowLoading] = useState(false);
  const [loaderGps, setLoaderGps] = useState(false);
  const [isOnline, setIsOnline] = useState(Boolean);

  const [data, setData] = useState<any>(createAssetFields);

  // const [scanToggle, setScanToggle] = useState(false);

  const [currentPage, setCurrentPage] = useState(0);
  // const [disableNext, setDisableNext] = useState(false);

  const [projects, setProjects] = useState<any>([]);
  const [selectedProject, setSelectedProject] = useState();
  const [projectModal, setProjectModal] = useState(false);
  const [shimmer, setShimmer] = useState(false);
  const [infiniteScroll, setInfiniteScroll] = useState(true);



  const handleChange = (event: any) => {
    const formentry: any = [...data];
    const f_index = formentry.findIndex((v: any) => v.field === event.target.id);
    const change_form_object = formentry[f_index];
    change_form_object.value = event.target.value;
    formentry[f_index] = change_form_object;
    setData(formentry);
  };

  const handleSelectChange = (event: any) => {
    const formentry: any = [...data];

    const select = event.target.name.replaceAll('-', '_');
    const f_index = formentry.findIndex((v: any) => v.field === select);
    formentry[f_index].value = event.target.value;
    setData(formentry);
  };

  // const handleMultiSelectChange = (event: any) => {
  //   const temp: any = event.target.value;
  //   const hasUndefined = temp.some((element: any) => element === undefined || element === null || element === '');
  //   if (hasUndefined) {
  //     return;
  //   }
  //   const deduplicatedArr = removeDuplicates(temp);

  //   const formentry: any = [...data];

  //   const select = event.target.name.replaceAll('-', '_');
  //   const f_index = formentry.findIndex((v: any) => v.field === select);
  //   // remove undefined from array of objects in event.target.value
  //   const filtered = deduplicatedArr.filter((v: any) => v !== undefined);
  //   formentry[f_index].value = filtered;
  //   setData(formentry);
  // };

  // function removeDuplicates(arr: { id: string, label: string }[]): { id: string, label: string }[] {
  //   const uniqueIds: Set<string> = new Set();
  //   const duplicates: Set<string> = new Set();

  //   return arr.filter((obj) => {
  //     if (!uniqueIds.has(obj.id)) {
  //       uniqueIds.add(obj.id);
  //       return true;
  //     } else {
  //       duplicates.add(obj.id);
  //       return false;
  //     }
  //   }).filter((obj) => !duplicates.has(obj.id));
  // }


  const handleScan = (code: any,field: any) => {
    if (code) {
      try {
        const formentry: any = [...data];
        const f_index = formentry.findIndex((v: any) => v.field === field);
        const change_form_object = formentry[f_index];
        change_form_object.value = code;
        formentry[f_index] = change_form_object;
        if(field === 'barcode_id') {
        // copy value of barcode to equipment
        const equipment_numberIndex = formentry.findIndex((v: any) => v.field === 'equipment_number');
        console.log(equipment_numberIndex)
        const c_f_o = formentry[equipment_numberIndex];
        c_f_o.value = code;
        formentry[equipment_numberIndex] = c_f_o;
        }

        setData(formentry);
      } catch (error: any) {
        console.log(error);
      }
      finally {
        // setScanToggle(false);
      }
    }
  }


  const url = '/method/preflet_asset.api.create_asset';

  useIonViewWillEnter(async () => {
    try {

      const internetStatus = await Network.getStatus();
      const isOnline = internetStatus.connected;
      const Data = [...data];
      // Set Barcode
      const bc = Data.findIndex((b) => b.field === 'barcode_id');

      const barcode: any = history.location.state || '';
      Data[bc].value = barcode
      if (barcode) {
        getGps('northing_easting_point');
      }
      /* Get Installation Datetime */
      const installationDateTime = Data.findIndex((b) => b.field === 'installation_datetime');
      const currentTime: any = new Date()
      Data[installationDateTime].value = convertToDateTimeLocalString(currentTime)

      /* Get Asset Category */
      const cname = Data.findIndex((d) => d.field === 'asset_category');
      const assetCategory = isOnline ? await getSetAssetCategory() : localStorage.getItem('assetCategory') || [];
      Data[cname].options = assetCategory

      /* Get Asset name */
      // const aname = Data.findIndex((d) => d.field === 'asset_name');
      // const assetName = isOnline ? await getSetAssetName() : localStorage.getItem('assetName') || [];
      // Data[aname].options = assetName;

      /* Get Asset Type */
      const atype = Data.findIndex((d) => d.field === 'asset_type');
      const assetType = isOnline ? await getSetAssetType() : localStorage.getItem('assetType') || [];
      Data[atype].options = assetType;

      /* Get Project by User */
      const pname = Data.findIndex((d) => d.field === 'project');
      const projectList = isOnline ? await getSetProjectList(0) : localStorage.getItem('projectList') || [];
      Data[pname].options = projectList;
      if (projectList.length < 10) {
        setInfiniteScroll(false);
      }
      setProjects(projectList);

      // /* Get Asset Component to link Asset */
      // const acname = Data.findIndex((d) => d.field === 'asset_component');
      // const assetComponent = isOnline ? await getAssetComponentByLabel('', 0) : localStorage.getItem('assetComponent') || [];
      // Data[acname].options = assetComponent;

      if (isOnline) {
        localStorage.setItem('assetCategory', assetCategory);
        // localStorage.setItem('assetName', assetName);
        localStorage.setItem('assetType', assetType);
        localStorage.setItem('projectList', projectList);
        // localStorage.setItem('assetComponent', assetComponent);
      }
      setData(Data);

    } catch (error) {
      console.log(error)
    }
  });

  const convertToDateTimeLocalString = (date: any) => {
    const year = date.getFullYear();
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const day = date.getDate().toString().padStart(2, "0");
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }



  useEffect(() => {
    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, [isOnline]);

  const Submit = (e: any) => {
    e.preventDefault();
    hitCreateAsset();
  };

  const hitCreateAsset = async () => {
    setShowLoading(true);
    // let assetComponent: any = []
    const formData = new FormData();
    for (const item in data) {
      if (data[item].field === 'project' && !data[item].value) {
        present({
          message: t(`Utils.fieldMissing`),
          color: 'danger',
          duration: 2000,
        });
        setShowLoading(false);
        return
      }
      if (data[item].type === 'file') {
        try {

          if (data[item].src?.length > 0) {
            // const f_index = data.findIndex((v: any) => v.field === 'asset_name');

            const response = await fetch(data[item].src?.toString());
            const blob = await response.blob();
            formData.append(
              'attachment',
              new File([blob], `${new Date()?.getTime()}.${data[item].format}`, {
                type: `image/${data[item].format}`,
              })
            );
          }
        } catch (e: any) {
          data[item].value = '';
        }
      }
      // else if (data[item].field === 'asset_component') {
      //   const newValue = []
      //   const temp = data[item].value;

      //   for (const x in temp) {
      //     newValue.push(
      //       {
      //         asset_component: data[item].value[x].id
      //       }
      //     )
      //   }

      //   data[item].value = newValue;
      //   assetComponent = newValue;
      //   if (assetComponent.length) {
      //     data[item].value = '';
      //     formData.append('asset_component', JSON.stringify(assetComponent));
      //   }
      // }
      else {
        const field = data[item]?.field;
        const value = data[item]?.value;
        if (typeof field === 'string' && typeof value === 'string') {
          formData.append(field, value);
        }
      }
    }


    // for (const item in data) {
    //   if (data[item].field === 'asset_component') {
    //     const newValue = []
    //     const temp = data[item].value;

    //     for (const x in temp) {
    //       newValue.push(
    //         {
    //           asset_component: data[item].value[x].id
    //         }
    //       )
    //     }

    //     data[item].value = newValue;
    //     assetComponent = newValue;
    //     data[item].value = '';
    //   }
    // }

    const api_data: any = {};
    api_data.creator_name = localStorage.getItem('email');
    data.map((_entry: any) => {
      // if (_entry.field === 'asset_component') {
      //   api_data.asset_component = assetComponent;
      // }
      // else 
      if (_entry.field === 'attachment' && !isOnline) {
        api_data.attachment = {
          src: _entry.src,
          format: _entry.format,
        };
      } else
        api_data[_entry.field] = _entry.value;
      return null;
    });

    const config: any = {
      method: 'post',
      url,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };

    if (!isOnline) {
      const newData = { data: api_data, url, name: 'createAsset', id: new Date().getTime() };
      // save data in storage
      offlineSaveToTable(newData, url, 'createAsset');

      present({
        message: t('Assets.CreateA.offlineSave'),
        color: 'primary',
        duration: 2000,
      });
      reset();
      router.goBack();
    } else {
      setShowLoading(true);
      axios(config)
        .then(() => {
          present({
            message: t(`Assets.CreateA.createAssetSuccess`),
            duration: 2000,
          });
          reset();
          router.goBack();
        })
        .catch(function (error) {
          console.log(error?.response)
          const x = error?.response?.data?._server_messages;
          console.log(x?.slice(18)?.slice(0, x?.slice(18)?.indexOf('\\')));
          if (error?.response?.status === 400) {
            present({
              message: t(`Assets.CreateA.${error.response?.data?.message}`),
              color: 'danger',
              duration: 2000,
            });
          } else {
            present({
              message: t('Assets.CreateA.createAssetError'),
              color: 'danger',
              duration: 2000,
            });
          }
        })
        .finally(() => {
          setShowLoading(false);
        });
    }
  }

  const reset = () => {
    for (const x in data) {
      if (data[x]?.src) { data[x].src = '' }
      data[x].value = '';
    }
  };

  const getGps = (param: any) => {
    try {
      const location = window.navigator?.geolocation;
      if (location) {
        setLoaderGps(true);
        location.getCurrentPosition(
          async (position) => {
            const Data = [...data];
            const latitude = position?.coords?.latitude?.toFixed(3)?.toString();
            const longitude = position?.coords?.longitude?.toFixed(3)?.toString();
            const latLng = Data.findIndex((d) => d.field === param);
            Data[latLng].value = latitude + ', ' + longitude;
            // find address
            const formData = new FormData();
            formData.append('lat', latitude);
            formData.append('long', longitude);
            const res = await axios({
              method: 'get',
              // url: `/method/preflet_asset.api.get_address_from_location`,
              url: `https://atlas.microsoft.com/search/address/reverse/json?api-version=1.0&query=${latitude},${longitude}&subscription-key=BF6EpQxIO1UpBpbGm8dVDlNAnQkJ0NgUth4A7g0igrej6ZsIoGmaJQQJ99AEAC5RqLJQDokpAAAgAZMPn2ri`,
              // headers: {
              //   Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
              // },
              // data: formData,
            });
            const latLngAddress = Data.findIndex((d) => d.field === 'northing_easting_address');
            console.log(res)
            try {
              Data[latLngAddress].value = res?.data?.addresses[0].address.freeformAddress;
              present({
                message: t(`Assets.CreateA.addressFetchedSuccess`),
                duration: 2000,
              });
            } catch (e) {
              Data[latLngAddress].value = ""
            }
            setData(Data);
          },
          (error) => {
            console.log(error);
          }
        );
      }
    } catch (e: any) {
      console.log(e)
    } finally {
      setLoaderGps(false);
    }
  };

  const emptyImg = (index: number) => {
    const tempData = [...data];
    tempData[index].src = '';
    setData(tempData);
  };

  const takePicture = async (imageElementIndex: number) => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      const imageUrl = image.webPath;
      // Can be set to the src of an image now
      const formentry: any = [...data];
      formentry[imageElementIndex].src = imageUrl;
      formentry[imageElementIndex].format = image.format;
      setData(formentry);
    } catch (e: any) {

      if (e.toString().includes('cancelled')) {

        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }

      else if (e.toString().includes('denied') || e.includes('access')) {

        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {

        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  const getScan = async (field:any) => {
    const info = await Device.getInfo();
    if (info?.platform === 'web') {
      present({
        message: t('Utils.scannerNotSupported'),
        duration: 2000,
      });
      return
    }
    try {
      BarcodeScanner.isSupported().then((result) => {
        if (!result.supported) {
          present({
            message: t('Utils.unsupportDevice'),
            duration: 2000,
          });
        }
      });

      const granted = await requestPermissions();
      if (!granted) {
        present({
          message: t('Utils.noPermission'),
          duration: 2000,
        });
        return;
      }
      const check = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
      if (!check.available)
        BarcodeScanner.installGoogleBarcodeScannerModule()
      const { barcodes } = await BarcodeScanner.scan();
      const scannedValue = barcodes[0]?.displayValue
      handleScan(scannedValue,field);
    } catch (error: any) {
      console.log(error)
    }
  };


  // const nextPage = async (fieldName: any) => {
  //   try {
  //     const Data = [...data];
  //     const acname = Data.findIndex((d) => d.field === fieldName);
  //     if (disableNext) return;
  //     const res = [];
  //     // if (fieldName === 'asset_component') {
  //     //   res = await getAssetComponentByLabel('', currentPage + 1)
  //     // }
  //     // else if (fieldName === 'asset_category') {
  //     //   res = await getSetAssetCategory('', currentPage + 1)
  //     // }
  //     if (res.length) {
  //       Data[acname].options = res;
  //       setData(Data);
  //       setDisableNext(false);
  //     } else {
  //       setDisableNext(true);
  //     }
  //   } catch (error: any) {
  //     console.log(error)
  //   } finally {
  //     if (!disableNext)
  //       setCurrentPage(currentPage + 1);
  //   }
  // }

  // const previousPage = async (fieldName: any) => {
  //   try {
  //     if (currentPage > 0) {
  //       const Data = [...data];
  //       const acname = Data.findIndex((d) => d.field === fieldName);
  //       let res = [];
  //       if (fieldName === 'asset_component') {
  //         res = await getAssetComponentByLabel('', disableNext ? currentPage - 2 : currentPage - 1)
  //       }
  //       // else if (fieldName === 'asset_category') {
  //       //   res = await getSetAssetCategory('', disableNext ? currentPage - 2 : currentPage - 1)
  //       // }
  //       if (res.length) {
  //         Data[acname].options = res;
  //         setData(Data);
  //         setDisableNext(false);
  //       }
  //     }
  //   } catch (error: any) {
  //     console.log(error)
  //   } finally {
  //     if (currentPage > 0)
  //       setCurrentPage(currentPage - 1);
  //   }
  // }

  async function searchNextProject($event: CustomEvent<void>) {
    try {
      const res = await getSetProjectList(currentPage + 1);
      if (res?.length) {
        setProjects([...projects, ...res]);
      }
    } catch (error: any) {
      console.log(error)
    } finally {
      setShimmer(false)
      setCurrentPage(currentPage + 1);
    }
    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const setProjectInValue = (project: any) => {
    const Data = [...data];
    const aname = Data.findIndex((d) => d.field === 'project');
    Data[aname].value = project;
    setSelectedProject(project);
  }

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })
        }
      </div>
    );
  };

  return (
    <IonPage>
      <Header enableBackButton='/assets' title={t('Assets.Create')} />
      <IonContent>
        <IonLoading
          cssClass="my-custom-class"
          isOpen={showLoading}
          onDidDismiss={() => setShowLoading(false)}
          message={'Please wait...'}
        />
        {/* <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem' }}>
          <button onClick={() => setIsScan(true)} className="scan-button">
            <img src={ScanAsset} alt="scan" style={{ paddingTop: '1rem' }} />
            <p className="scan-text">{t('Assets.Scan')}</p>
          </button>
        </div> */}

        {/* <p className="fill-text">{t('Assets.CreateA.Fill')}</p> */}
        <form onSubmit={(e) => Submit(e)}>
          {data.map((form_field: any, index: any) => {
            if (form_field.title) {
              return (
                <p className="form-title" key={`${form_field.title}-${index}`}>
                  {t(form_field.title)}
                </p>
              );
            } else if (form_field.type === 'text') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  {form_field.field === 'northing_easting_point' ? (
                    <div className="flex items-center justify-between basic-input">
                      <input
                        required={form_field.required}
                        className="gps-input"
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChange}
                        value={form_field.value}
                      />
                      {loaderGps && <IonSpinner name="crescent"></IonSpinner>}
                      <img onClick={() => getGps(form_field.field)} className="gps-icon" src={Gps} alt="gps" />
                    </div>
                  ) : <div style={{ display: 'flex', alignItems: 'center', position: 'relative' }}>
                    <input
                      required={form_field.required}
                      className="basic-input"
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      onChange={handleChange}
                      value={form_field.value}
                    />
                    {form_field.field === 'northing_easting_address' && loaderGps && <IonSpinner style={{ position: 'absolute', right: '1rem', top: '0.35rem' }} name="crescent"></IonSpinner>}
                  </div>}
                </div>
              );
            } else if (form_field.type === 'text' || form_field.type === 'number') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    type={form_field.type}
                    className="basic-input"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            }
            else if (form_field.type === 'textarea') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <textarea
                    className="textarea"
                    required={form_field.required}
                    id={form_field.field}
                    placeholder={t(`Assets.CreateA.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                  />
                </div>
              );
            } else if (form_field.field === 'project') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <p onClick={() => setProjectModal(!projectModal)} className='basic-input' style={{ marginTop: '0px', fontSize: '12px', color: '#a3a9b5' }}>{form_field.value || <span>{t('Utils.selectProject')}</span>}</p>
                </div>)
            }
            else if (form_field.type === 'select') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  {form_field.info ? (
                    <div className="tooltip">
                      <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
                      <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
                    </div>
                  ) : null}

                  <Select
                    className="custom-select"
                    required={form_field.required}
                    id={`${form_field.field}_choice`}
                    name={form_field.field}
                    onChange={handleSelectChange}
                    value={
                      form_field.field === 'asset_component' && form_field.value.length
                        ?
                        form_field.value[0]['asset_component']
                        : form_field.value
                    }
                    disabled={form_field.disabled || false}
                  >
                    {form_field.options?.map(function (opt: any, indexSelect: any) {
                      return (
                        <MenuItem
                          value={`${opt}`}
                          key={`${form_field.field}-${indexSelect}`}
                          id={`${opt}_item_${indexSelect}`}
                        >
                          <section
                            id={`section-${form_field.field}-${index}`}
                            style={{ display: 'flex', height: '1rem' }}
                          >
                            <p
                              id={`value-${form_field.field}-${index}`}
                              style={{
                                color: 'rgba(31, 87, 148, 1)',
                                marginLeft: '0.2rem',
                                fontSize: '12px',
                                marginTop: '0px',
                              }}
                            >
                              {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                            </p>
                          </section>
                        </MenuItem>
                      );
                    })}
                    {/* {form_field.options && form_field.options.length &&
                      <div className='pagination-container'>
                        <button className={`pagination-button ${currentPage === 0 ? 'disabled-pagination-button' : ''}`} onClick={() => previousPage(form_field.field)}>
                          <IonIcon src={chevronBack} />
                        </button>
                        <button className={`pagination-button ${disableNext ? 'disabled-pagination-button' : ''}`} onClick={() => nextPage(form_field.field)}>
                          <IonIcon src={chevronForward} />
                        </button>
                      </div>
                    } */}
                  </Select>
                </div>
              );
            }
            //  else if (form_field.type === 'multi-select') {
            //   return (
            //     <div
            //       id={`${form_field.field}-${index}`}
            //       className="input-container"
            //       key={`${form_field.field}-${index}`}
            //     >
            //       <label id={`label-${form_field.field}`} className="label">
            //         {t(`Assets.CreateA.${form_field.field}`)}
            //         {form_field.required ? '*' : null}{' '}
            //       </label>
            //       {form_field.info ? (
            //         <div className="tooltip">
            //           <IonIcon style={{ marginBottom: '-4px' }} src={informationCircle} />
            //           <span className="tooltiptext">{t(`Utils.${form_field.info}`)}</span>
            //         </div>
            //       ) : null}
            //       <div style={{ display: 'flex', flexWrap: 'wrap', padding: '2px', marginLeft: '14px' }}>
            //         {/* {Object.values(form_field.value).map((val:any,key) => {
            //           return (
            //             <p key={key} style={{fontSize:'10px',color:'blue',textDecoration:'underline',cursor:'pointer',marginRight:'0.6rem'}}>
            //               <Link to={{ pathname: '/comDetail', state: val.id }}>{val.label}</Link>
            //               </p>
            //           )
            //         })} */}
            //       </div>
            //       <Select
            //         className="custom-select"
            //         required={form_field.required}
            //         id={`${form_field.field}_choice`}
            //         name={form_field.field}
            //         multiple
            //         onChange={handleMultiSelectChange}
            //         value={Object.values(form_field.value) || []}
            //         disabled={form_field.disabled || false}
            //         input={<Input className="basic-input" id="select-multiple-chip" />}
            //         renderValue={() => (
            //           <Box style={{ borderRadius: '33rem' }} sx={{ display: 'flex', flexWrap: 'wrap' }}>
            //             {Object.values(form_field.value).map((value: any, index) => (
            //               <Chip
            //                 style={{ fontSize: '10px', marginTop: '1px', marginRight: '1px' }} key={index} label={

            //                   value?.label} />
            //             ))}
            //           </Box>
            //         )}
            //       >
            //         {form_field.options?.map(function (opt: any, indexSelect: any) {
            //           return (
            //             <MenuItem
            //               value={opt}
            //               key={`${form_field.field}-${indexSelect}`}
            //               id={
            //                 `${opt.id}_item_${indexSelect}`}
            //             >
            //               <Checkbox checked={
            //                 form_field?.value?.length ? form_field.value.filter((itemInArray: any) => itemInArray?.id === opt.id)[0]?.id === opt.id : false} />
            //               <ListItemText primary={

            //                 opt.label} />
            //             </MenuItem>
            //           );
            //         })}
            //         {form_field.options?.length &&
            //           <div className='pagination-container'>
            //             <button className={`pagination-button ${currentPage === 0 ? 'disabled-pagination-button' : ''}`} onClick={() => previousPage(form_field.field)}>
            //               <IonIcon src={chevronBack} />
            //             </button>
            //             <button className={`pagination-button ${disableNext ? 'disabled-pagination-button' : ''}`} onClick={() => nextPage(form_field.field)}>
            //               <IonIcon src={chevronForward} />
            //             </button>
            //           </div>
            //         }
            //       </Select>
            //     </div>
            //   );
            // }
            else if (form_field.type === 'file') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  <IonButton size="small" color="light" onClick={() => takePicture(index)}>
                    {form_field['src'] ? t('Utils.change_image') : t('Utils.image')}
                  </IonButton>
                  <br />
                  {form_field['src'] ? (
                    <div className="flex justify-center items-center">
                      <img className="preview-survey-img" src={form_field['src']} alt="" />
                      <IonIcon className="close-icon" onClick={() => emptyImg(index)} src={close} />
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              );
            }
            else if (form_field.type === 'barcode') {
              return (
                <div
                  id={`${form_field.field}-${index}`}
                  className="input-container"
                  key={`${form_field.field}-${index}`}
                >
                  <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}{' '}
                  </label>
                  <div className="flex items-center justify-between basic-input">
                    <input
                      required={form_field.required}
                      className="gps-input"
                      id={form_field.field}
                      placeholder={t(`Assets.CreateA.${form_field.field}`)}
                      value={form_field.value}
                      disabled={true}
                      style={{ width: '80%' }}
                    />
                    <IonIcon onClick={() => getScan(form_field.field)} className="gps-icon" src={barcodeOutline} />
                  </div>
                </div>
              );
            }

            else if (form_field.type === 'datetime-local') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(form_field.label)}
                    onChange={handleChange}
                    value={form_field.value}
                    type={form_field.type}
                  />
                </div>
              );
            }
            else if (form_field.type === 'date') {
              return (
                <div className="input-container" key={`${form_field.field}-${index}`}>
                  <label className="label">
                    {t(`Assets.CreateA.${form_field.field}`)}
                    {form_field.required ? '*' : null}
                  </label>
                  <input
                    required={form_field.required}
                    className="date"
                    id={form_field.field}
                    placeholder={t(`Assets.CreateA.${form_field.field}`)}
                    onChange={handleChange}
                    value={form_field.value}
                    type="date"
                  />
                </div>
              );
            } else {
              return '';
            }
          })}
          <button className="submit-button" type="submit">
            {t('Assets.Create')}
          </button>
        </form>
        <div style={{ marginBottom: '1rem' }}></div>
        <IonModal
          isOpen={projectModal}
          onDidDismiss={() => setProjectModal(false)}
        ><IonHeader>
            <IonToolbar>
              <IonTitle>Projects</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setProjectModal(false)}>Close</IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent>
            {shimmer && <Shimmer />}
            <IonList>
              {projects?.length ? projects.map((project: any, index: number) => (
                <IonItem
                  key={index}
                  onClick={() => {
                    setProjectModal(false);
                    setProjectInValue(project)
                  }}
                >
                  <IonLabel>{project}</IonLabel>
                  <IonCheckbox
                    slot="end"
                    value={project}
                    checked={project === selectedProject} />
                </IonItem>
              )) :
                <div style={{ margin: 'auto', display: 'block' }}>
                  <p className='text-center' style={{ color: 'gray' }}>{t('Utils.noProjectFound')}</p>
                </div>}
            </IonList>
            {infiniteScroll ?
              <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNextProject(e)}>
                <IonInfiniteScrollContent
                  loadingSpinner="bubbles"
                  loadingText="Loading more data..."
                ></IonInfiniteScrollContent>
              </IonInfiniteScroll> : ''}
          </IonContent>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default CreateAsset;
