import { IonContent, IonPage } from '@ionic/react';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import axios from '../../axios';
import Header from '../../components/Header';
import { createAssetomponent } from '../../models/createAssetComponent';

const ComponentDetail: React.FC = () => {
  const [data, setData] = useState<any>({
    asset: '',
    component_name: '',
    component_serial_number: '',
  });
  const location = useLocation();
  const getAssetIndividual = () => {
    const res: any = location.state;
    if (typeof res === 'string') {
      axios({
        method: 'get',
        url: `/resource/Asset Components/${res}`,
        headers: {
          Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
        },
      })
        .then((resp) => {
          setData(resp.data.data);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      setData(res);
    }
  };

  useEffect(() => {
    getAssetIndividual();
  }, []);

  const { t } = useTranslation();

  return (
    <IonPage>
      <Header enableBackButton='goBack' title={data.component_name} />
      <IonContent>
        <div className="preview-header">
          <div>
            <p className="preview-title">{data.component_name}</p>{' '}
            {/* <p className="preview-title-subheading">
              {t('CreateAC.component_serial_number')}: {data.component_serial_number}
            </p> */}
          </div>
          <p></p>
        </div>

        {createAssetomponent.map((item: any, index) => {
          return (
            <div className="data-list" key={`comp-detail-${index}`}>
              {item.field ? (
                <div className="preview-container">
                  <p className="preview-key">{t(`CreateAC.${item.field}`)}</p>
                  <p className="preview-value">
                    { 
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      
                      item.field === 'external_component' ? t(data[item.field])
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      
                      : data[item.field] ? data[item.field] : '-' 
                    }
                  </p>
                </div>
              ) : (
                ''
              )}
            </div>
          );
        })}
      </IonContent>
    </IonPage>
  );
};

export default ComponentDetail;
