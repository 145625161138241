import { BarcodeScanner } from '@capacitor-mlkit/barcode-scanning';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Device } from '@capacitor/device';
import {
  IonContent,
  IonPage,
  IonItem,
  IonCard,
  IonCardHeader,
  IonCardSubtitle,
  IonCardContent,
  IonCardTitle,
  IonIcon,
  IonModal,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonButton,
  useIonToast,
  IonLoading,
  IonSkeletonText,
  IonRow,
  useIonViewWillLeave,
  useIonRouter,
} from '@ionic/react';
import { MenuItem, Select } from '@material-ui/core';
import { barcodeOutline, close, constructOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Clock from '../../assets/Clock.svg';
import CreateAsset from '../../assets/CreateAsset.svg';
import CreateComp from '../../assets/CreateComp.svg';
import ViewComp from '../../assets/ViewComp.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import ScanButton from '../../components/ScanButton';
import { createAssetFields } from '../../models/createAssetFields';
import { AppContext } from '../../providers/AppContext';
import { constant, getStatusColor, isFormDataEmpty, requestPermissions } from '../../utils';

const Assets: React.FC = () => {
  const isMounted = useRef(true);
  const { state } = useContext(AppContext);

  const { t } = useTranslation();
  const [present] = useIonToast();
  const router = useIonRouter();

  const [highlights, setHighlights] = useState<any>([]);
  const [pname, setPname] = useState('');
  const [fabModal, setFabModal] = useState(false);
  const [loaderM, setLoaderM] = useState(false);
  const [loader, setLoader] = useState(false);

  const [_data] = useState<any>(createAssetFields);
  // const [scanToggle, setScanToggle] = useState(false);

  const [primaryData, setPrimaryData] = useState<any>({});

  const [update, setUpdate] = useState<any>({
    status: '',
    asset_state: '',
    brand: '',
    model: '',
    year: '',
    comment: '',
    // asset_component: [],
    attachment: {},
    barcode_id: '',
  });

  const handleUpdateStatus = (event: any) => {
    const formentry: any = { ...update };

    if (event.target.name in formentry) {

      formentry[event.target.name] = event.target.value;
    }
    setUpdate(formentry);
  };

  // const handleMultiSelectChange = (event: string) => {
  //   const formentry: any = { ...update };
  //   formentry['asset_component'] = event
  //   setUpdate(formentry);
  // };

  const handleChangeQuickUpdate = (event: any) => {
    const formentry: any = { ...update };
    if (event.target.id in formentry) {
      formentry[event.target.id] = event.target.value;
    }
    setUpdate(formentry);
  };

  const getData = () => {
    isMounted.current = true; // Set isMounted to true

    const url = `method/preflet_asset.api.get_assets_active_tasks?limit_page_length=5&limit_start=0`
    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    if (isMounted.current)
      setLoader(true);
    axios(config)
      .then((res: any) => {
        if (isMounted.current)
          setHighlights(res?.data?.message);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isMounted.current)
          setLoader(false);
      });
  };

  useEffect(() => {
    getData();
    // Cleanup function
    return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);


  useIonViewWillLeave(() => {
    if (fabModal) {
      setFabModal(false);
    }
  });

  const modalFunc = async (param: any) => {
    setFabModal(true);
    // const Data = [..._data];
    // const acname = Data.findIndex((d) => d.field === 'asset_component');
    // Data[acname].options = await getAssetComponentByLabel();
    // set_Data(Data);

    const config: any = {
      method: 'get',
      url: `resource/Asset/${param}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      const statusData = await axios(config)
      // const assetComponent: { asset_component: any; }[] = []
      // statusData?.data?.data?.asset_component.map((item: any) => {
      //   assetComponent.push(item?.asset_component)
      // })
      setPrimaryData(statusData?.data?.data);

      for (const x in highlights) {

        if (highlights[x].name === param) {
          const formentry: any = { ...update };
          formentry.status = statusData?.data?.data?.status
          formentry.asset_state = statusData?.data?.data?.asset_state
          formentry.brand = statusData?.data?.data?.brand
          formentry.model = statusData?.data?.data?.model
          formentry.year = statusData?.data?.data?.year
          formentry.comment = statusData?.data?.data?.comment
          formentry.attachment.value = statusData?.data?.data?.attachment
          formentry.barcode_id = statusData?.data?.data?.barcode_id
          // formentry.asset_component = assetComponent
          setUpdate(formentry);
        }
      }
    } catch (error: any) {
      console.log(error)
    }
  }

  const SubmitStatusUpdate = async (e: any) => {
    e.preventDefault();
    hitQSupdate();
  };

  const hitQSupdate = async () => {
    const formData = new FormData();
    const temp: any = { ...update }
    for (const item in temp) {

      if (item === 'attachment' && temp['attachment']?.src) {
        try {

          const response = await fetch(temp['attachment']?.src?.toString());
          const blob = await response.blob();
          formData.append(
            'attachment',

            new File([blob], `${pname}-attachment.${temp['attachment'].format}`, {

              type: `image/${temp['attachment'].format}`,
            })
          );
        } catch (e: any) {
          console.log(e)
        }
      }
      // else if (item === 'asset_component') {
      //   const assComp: any = [] as any;
      //   if (temp[item].length > 0) {
      //     for (const element in temp['asset_component']) {
      //       assComp.push({
      //         asset_component: temp['asset_component'][element]
      //       })
      //     }
      //   }

      //   if (!checkIfArraysAreSame(primaryData[item], assComp))
      //     formData.append('asset_component', JSON.stringify(assComp));
      // }
      else if (item === 'attachment' && (primaryData[item] !== temp[item].value)) {

        formData.append('attachment', temp[item].value);

      } else if (temp[item] !== '' && temp[item] !== null && temp[item] !== undefined && temp[item] !== 'undefined') {

        if ((primaryData[item] !== temp[item]) && item !== 'attachment')

          formData.append(item, temp[item]);
      }
    }

    const config: any = {
      method: 'put',
      url: `method/preflet_asset.api.update_asset/${pname}`,
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: formData,
    };
    if (!isFormDataEmpty(formData)) {
      // Update status api
      try {
        setLoaderM(true);
        await axios(config);
      } catch (error: any) {
        console.log(error);
      }

      // Update comment
      try {
        setLoaderM(true);
        const config_comment: any = {
          method: 'post',
          url: 'resource/Comment',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: {
            comment_type: 'Comment',
            reference_doctype: 'Asset',
            reference_name: pname,
            content: update.comment,
          },
        };
        await axios(config_comment);
      } catch (error: any) {
        console.log(error);
      } finally {
        setLoaderM(false);
        setFabModal(false);
        present({
          message: t('Assets.CreateA.assetUpdateSuccesss'),
          duration: 2000,
        });
        // reset modal
        setUpdate({
          status: '',
          asset_state: '',
          brand: '',
          model: '',
          year: '',
          comment: '',
          // asset_component: [],
          attachment: {},
          barcode_id: '',
        });
        getData();
      }
    } else {
      setFabModal(false);
    }
  }



  const takeQSPicture = async () => {
    try {
      const image = await Camera.getPhoto({
        quality: 70,
        allowEditing: true,
        resultType: CameraResultType.Uri,
        source: CameraSource.Camera,
      });
      const imageUrl = image.webPath;

      // Can be set to the src of an image now
      const formentry: any = { ...update }
      formentry['attachment'].value = null;
      formentry['attachment'].src = imageUrl;
      formentry['attachment'].format = image.format;
      setUpdate(formentry);
    } catch (e: any) {

      console.log('Error: ', e);

      if (e.toString().includes('cancelled')) {

        present({
          message: t('Utils.cameraCancel'),
          color: 'danger',
          duration: 2000,
        });
      }

      else if (e.toString().includes('denied') || e.includes('access')) {

        present({
          message: t('Utils.cameraErrorPermission'),
          color: 'danger',
          duration: 2000,
        });
      } else {

        present({
          message: e,
          color: 'danger',
          duration: 2000,
        });
      }
    }
  };

  /* Remove Quick Status Image by cross click */
  const emptyQSImg = () => {
    const tempData = { ...update };
    tempData.attachment = {};
    setUpdate(tempData);
  };

  const getScanner = async (field:any) => {
    const info = await Device.getInfo();
    if (info?.platform === 'web') {
      present({
        message: t('Utils.scannerNotSupported'),
        duration: 2000,
      });
      return
    }
    try {
      BarcodeScanner.isSupported().then((result) => {
        if (!result.supported) {
          present({
            message: t('Utils.unsupportDevice'),
            duration: 2000,
          });
        }
      });

      const granted = await requestPermissions();
      if (!granted) {
        present({
          message: t('Utils.noPermission'),
          duration: 2000,
        });
        return;
      }
      const check = await BarcodeScanner.isGoogleBarcodeScannerModuleAvailable()
      if (!check.available)
        BarcodeScanner.installGoogleBarcodeScannerModule()
      const { barcodes } = await BarcodeScanner.scan();
      const scannedValue = barcodes[0]?.displayValue
      handleScan(scannedValue,field);
    } catch (error: any) {
      console.log(error)
    }
  };

  const handleScan = (data: any, field:any) => {
    if (data) {
      try {
        const tempData = { ...update };
        tempData[field] = data;
        // tempData.barcode_id = data;
        setUpdate(tempData);
      } catch {
        present({
          message: t('Utils.fetchFail'),
          color: 'danger',
          duration: 4000,
        });
        console.log('failed sending id to the parent');
      } finally {
        // setScanToggle(false);
      }
    }
  };

  const Shimmer = () => {
    return (
      <div>
        {
          [1, 2, 3, 4, 5, 6, 7, 8, 9].map((element) => {
            return (
              <div
                key={element}
                id="shimmer-body"
                style={{ padding: '1rem' }}
                className="w-50 data-list shimmer-container"
              >
                <div className="flex justify-between">
                  <IonSkeletonText animated style={{ width: '50%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <IonSkeletonText animated style={{ width: '30%' }} />
              </div>
            );
          })
        }
      </div>
    );
  };

  const Highlights = ({ highlights }: { highlights: any }) => {
    return (
      <div>
        {highlights?.map((element: any, key: number) => {
          return (
            <IonCard id="card-container" key={key}>
              <IonCardHeader>
                <IonCardTitle className="card-heading">
                  <div className="flex justify-between items-center">
                    <Link className="underline capitalize" to={{ pathname: '/assetDetail', state: element.name, search: 'back=assets' }}>
                      <span>{element.name}</span>
                    </Link>
                    {state.permissions.assets_update &&
                      <div
                        onClick={() => {
                          modalFunc(element.name);
                          setPname(element.name);
                        }}
                        style={{ cursor: 'pointer' }}
                      >
                        <IonIcon className="view-detail-highight-icon" src={constructOutline} />
                      </div> || null}
                  </div>
                </IonCardTitle>
                <IonCardSubtitle className="flex items-center">
                  <div style={{ width: '12px', height: '12px', borderRadius: '1rem', backgroundColor: getStatusColor(element.status), margin: '4px' }}></div>
                  {t(`Utils.${element.status}`)}
                </IonCardSubtitle>
              </IonCardHeader>
            </IonCard>
          );
        })}
        {highlights.length > 0 ? <Link to="/all" style={{ margin: 'auto', display: 'block' }} className="text-center underline pointer">{t('Utils.viewAll')}</Link> : ''}
      </div>
    );
  };

  const openCreateAsset = () => {
    localStorage.setItem(constant.QUICK_SCAN_ASSET_CODE, '')
    router.push('/createAsset')
  }

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <div style={{ display: 'flex', margin: 'auto', paddingTop: '2rem', marginLeft: '12vw', marginRight: '12vw' }}>
          {state.permissions.assets_create &&
            <section
              onClick={() => openCreateAsset()}
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={CreateAsset} alt="scan" style={{ paddingTop: '1rem' }} />
              <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '2px', fontSize: '11px' }}>
                {t('Assets.Create')}
              </p>
            </section>
            || null}
          {state.permissions.assets_create &&
            <Link to="/createComp" style={{ textDecoration: 'none' }}>
              <section
                style={{
                  width: '23vw',
                  height: '6rem',
                  marginLeft: '5px',
                  marginRight: '5px',
                  textAlign: 'center',
                  boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
                }}
              >
                <img src={CreateComp} alt="scan" style={{ paddingTop: '1rem', width: '2rem' }} />
                <p style={{ margin: 'auto', color: 'rgba(29, 80, 141, 1)', marginTop: '12px', fontSize: '11px' }}>
                  {t('Component.Create')}
                </p>
              </section>
            </Link> || null}
          <Link to="/compDetails" style={{ textDecoration: 'none' }}>
            <section
              style={{
                width: '23vw',
                height: '6rem',
                marginLeft: '5px',
                marginRight: '5px',
                textAlign: 'center',
                boxShadow: '1.75499px 7.01994px 42.1197px -14.0399px rgba(0, 0, 0, 0.1)',
              }}
            >
              <img src={ViewComp} alt="scan" style={{ paddingTop: '1rem', width: '2rem' }} />
              <p
                style={{
                  margin: 'auto',
                  color: 'rgba(29, 80, 141, 1)',
                  marginTop: '12px',
                  fontSize: '11px',
                  padding: '1px',
                }}
              >
                {t('Component.View')}
              </p>
            </section>
          </Link>
        </div>
        <div className="flex justify-between w-50 data-list" style={{ marginTop: '1rem' }}>
          <IonItem lines="none" className="heading">
            <p>{t('Assets.highlights')}</p>
          </IonItem>
          <Link to="/all" style={{ textDecoration: 'underline' }}>
            <IonItem lines="none" className="heading">
              <img src={Clock} alt="scan" style={{ width: '0.8rem', marginRight: '4px' }} />
              <p style={{ color: 'rgba(29, 80, 141, 1)' }}> {t('Assets.history')}</p>
            </IonItem>
          </Link>
        </div>
        {/* {loader &&
          )} */}

        <div className="w-50 data-list">
          {loader && <Shimmer />}
          {highlights && <Highlights highlights={highlights} />}
        </div>
        <IonModal isOpen={fabModal} onDidDismiss={() => setFabModal(false)}>
          <IonHeader>
            <IonToolbar color="primary">
              &nbsp; {pname}
              <IonButtons slot="end">
                <IonButton
                  className="line-button"
                  onClick={() => {
                    setFabModal(false);
                  }}
                >
                  <IonIcon icon={close} slot="icon-only"></IonIcon>
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonCardContent style={{ overflowY: 'scroll' }}>
            <IonLoading
              isOpen={loaderM}
              onDidDismiss={() => setLoaderM(false)}
              message={t('Utils.pleasewait')}
            />
            <form onSubmit={(e) => SubmitStatusUpdate(e)}>
              {_data.map((form_field: any, index: any) => {
                if (form_field.field === 'status' || form_field.field === 'asset_state') {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">{t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}</label>

                      <Select
                        className="custom-select"
                        required={form_field.required}
                        id={`${form_field.field}_choice`}
                        name={form_field.field}
                        onChange={handleUpdateStatus}
                        value={update[form_field.field]}
                        disabled={form_field.disabled || false}
                        key={`${form_field.field}-${index}`}
                      >
                        {form_field.options?.map(function (opt: any, index: any) {
                          return (
                            <MenuItem value={`${opt}`} key={`${form_field.field}-${index}`}>
                              <section
                                key={`${form_field.field}-${index}`}
                                style={{ display: 'flex', height: '1rem' }}
                              >
                                {/* <img src={Man} alt="Users" style={{ width: '15px' }} /> */}
                                <p
                                  key={`${form_field.field}-${index}`}
                                  style={{
                                    color: 'rgba(31, 87, 148, 1)',
                                    marginLeft: '0.2rem',
                                    fontSize: '12px',
                                    marginTop: '0px',
                                  }}
                                >
                                  {form_field.translate ? t(`Assets.CreateA.${opt}`) : opt}
                                </p>
                              </section>
                            </MenuItem>
                          );
                        })}
                      </Select>
                    </div>
                  );
                }
                //  else if (form_field.field === 'asset_component') {
                //   return (
                //     <div key={index} className="input-container">
                //       <label className="label">{t(`Assets.CreateA.${form_field.field}`)}</label>
                //       <IonSelect
                //         id={`${form_field.field}_choice`}
                //         className="custom-ion-select"
                //         multiple
                //         interface='popover' style={{ color: '#1d508d', fontSize: '14px' }} placeholder='Change Status'
                //         onIonChange={(e) => handleMultiSelectChange(e.detail.value)}
                //         value={update[form_field.field]}
                //       >
                //         {form_field.options?.map(function (opt: any, indexSelect: any) {
                //           return (
                //             <IonSelectOption value={

                //               opt.id}
                //               key={`${form_field.field}-${indexSelect}`}
                //               id={
                //                 `${opt.id}_item_${indexSelect}`}>{opt.label}</IonSelectOption>
                //           );
                //         })}
                //       </IonSelect>
                //     </div>
                //   );
                // }
                else if (
                  form_field.field === 'brand' ||
                  form_field.field === 'model' ||
                  form_field.field === 'year'
                ) {
                  return (
                    <div key={index} className="input-container">
                      <label className="label">{t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}</label>
                      <input
                        className="basic-input"
                        required={false}
                        key={`${form_field.field}-${index}`}
                        id={form_field.field}
                        placeholder={t(`Assets.CreateA.${form_field.field}`)}
                        onChange={handleChangeQuickUpdate}
                        value={update[form_field.field]}
                      />
                    </div>
                  );
                }
                else if (form_field.type === 'file') {
                  return (
                    <div
                      id={`${form_field.field}-${index}`}
                      className="input-container"
                      key={`${form_field.field}-${index}`}
                    >
                      <label id={`label-${form_field.field}`} className="label">
                        {t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}{' '}
                      </label>
                      <IonButton
                        style={{ marginTop: '1rem', margin: 'auto', width: '40%', color: 'black', marginBottom: '1rem' }}
                        size="small"
                        color="light"
                        onClick={() => takeQSPicture()}
                      >
                        {
                          update[form_field.field]['value'] ? t('Utils.change_image') : t('Utils.image')}
                      </IonButton>
                      <div className="flex justify-center items-center">
                        {
                          update[form_field.field]['value'] ? (
                            <img
                              src={`${localStorage.getItem('baseUrl')?.replace('/api', '')}${update[form_field.field]['value']}`}
                              alt=""
                              style={{
                                width: '60%',
                                margin: 'auto',
                                display: 'block',
                                border: '1px solid lightgray',
                                borderRadius: '12px',
                              }}
                            />
                          ) : (
                            ''
                          )}
                        {
                          update['attachment']['src'] ? <img className="preview-survey-img" src={update['attachment']['src']} alt="" /> : ''}
                        {
                          update['attachment']['src'] ? (
                            <IonIcon className="close-icon" onClick={() => emptyQSImg()} src={close} />
                          ) : (
                            ''
                          )}
                      </div>
                    </div>
                  );
                } else if (form_field.type === 'barcode') {
                  return (
                    <div
                      id={`${form_field.field}-${index}`}
                      className="input-container"
                      key={`${form_field.field}-${index}`}
                    >
                      <label id={`label-${form_field.field}`} style={{ marginTop: '1rem' }} className="label">
                        {t(`Assets.CreateA.${form_field.field}`)}
                        {form_field.required ? '*' : null}
                      </label>
                      <div className="flex items-center justify-between basic-input">
                        <input
                          required={form_field.required}
                          className="gps-input"
                          id={form_field.field}
                          placeholder={t(`Assets.CreateA.${form_field.field}`)}
                          value={update[form_field.field]}
                          disabled={true}
                          style={{ width: '80%' }}
                        />
                        <IonIcon onClick={() => getScanner(form_field.field)} className="gps-icon" src={barcodeOutline} />
                      </div>
                    </div>
                  );
                }
                else {
                  return '';
                }
              })}
              <div className="input-container">
                <label className="label">{t(`Utils.Comment`)}</label>
                <textarea
                  className="textarea"
                  required={false}
                  key="comment"
                  id="comment"
                  placeholder={t('Utils.Add your comment')}
                  onChange={handleChangeQuickUpdate}
                  value={update.comment}
                />
              </div>
              <button className="submit-button" type="submit">
                {t(`Assets.CreateA.update`)}
              </button>
            </form>
          </IonCardContent>
        </IonModal>
        <br />
        <br />
        {!loader && highlights.length === 0 ? (
          <IonRow>
            <NoRecords width="30%" smallText={true} />
          </IonRow>
        ) : (
          <div></div>
        )}
        <ScanButton isAssetPage={true} />
      </IonContent>
    </IonPage>
  );
};

export default Assets;
