import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonPage,
  IonToolbar,
  IonSkeletonText,
  IonRefresher,
  IonRefresherContent,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  IonRow,
} from '@ionic/react';
import { chevronDownCircleOutline } from 'ionicons/icons';
import { useState, useEffect, useRef , useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import Red from '../../assets/Red.svg';
import axios from '../../axios';
import Header from '../../components/Header';
import NoRecords from '../../components/NoRecords';
import { AppContext } from '../../providers/AppContext';

const SurveyShow: React.FC = () => {
  const isMounted = useRef(true);

  const [data, setData] = useState<any>({
    dataSet: [],
  });

  const { state } = useContext(AppContext);

  const [isLoading, setIsLoading] = useState(true);

  const [currentPage, setCurrentPage] = useState(0);

  const [disableInfiniteScroll, setDisableInfiniteScroll] = useState<boolean>(false);

  const getData = () => {
    isMounted.current = true; // Set isMounted to true
    const url = `/resource/Survey?limit_page_length=${state.maxLength}&limit_start=${
      currentPage * 5 + 0
    }&fields=["name", "si_survey_code"]&order_by=creation%20desc`;

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((res: any) => {
        if (res?.data.data && res.data.data.length > 0) {
          const Data = { ...data };
          
          Data.dataSet.push(...res.data.data);
          if (isMounted.current) {
          setData(Data);
          setDisableInfiniteScroll(res.data.data.length < state.maxLength);
        }
        } else {
          if (isMounted.current)
          setDisableInfiniteScroll(true);
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        if (isMounted.current) {
        setIsLoading(false);
        setCurrentPage(currentPage + 1);
      }
      });
  };

  useEffect(() => {
    getData();
     // Cleanup function
     return () => {
      isMounted.current = false; // Mark the component as unmounted when it's being cleaned up
      // You can also cancel any subscriptions or clean up other resources here
    };
  }, []);

  // ion-refersher
  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getData();
    setTimeout(() => {
      console.log('Async operation has ended');
      event.detail.complete();
    }, 2000);
  }

  async function searchNext($event: CustomEvent<void>) {
    await getData();

    ($event.target as HTMLIonInfiniteScrollElement).complete();
  }

  const { t } = useTranslation();

  return (
    <IonPage>
      <Header enableBackButton='/survey-home' title={t('Survey.history')} />
      <IonContent>
        {isLoading &&
          [1, 2, 3, 4, 5, 6, 7, 8].map((element, key) => {
            return (
              <div key={key} style={{ padding: '1rem' }}>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '60%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                  <IonSkeletonText animated style={{ width: '30%' }} />
                  <IonSkeletonText animated style={{ width: '20%' }} />
                </div>
              </div>
            );
          })}
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        {data.dataSet?.map((item: any, key: any) => {
            return (
              <div
                key={key}
                className="ion-margin data-list"
                style={{ boxShadow: '2px 8px 48px -16px rgba(21, 70, 160, 0.1)', borderRadius: '20px' }}
              >
                <section style={{ display: 'flex' }}>
                  <section style={{ flex: 'column' }}>
                    <h4
                      style={{
                        marginLeft: '0.4rem',
                        marginTop: '1.3rem',
                        color: 'rgba(29, 80, 141, 1)',
                        fontSize: 'large',
                      }}
                    >
                      {item.name}
                    </h4>
                    <p
                      style={{
                        marginLeft: '0.5rem',
                        color: 'rgba(146, 155, 171, 1)',
                        marginTop: '-0.5rem',
                        fontSize: '11px',
                        maxWidth: '200px',
                        wordWrap: 'break-word',
                      }}
                    >
                      {t('Survey.code')} : {item.si_survey_code}
                    </p>
                  </section>
                </section>

                <section style={{ flex: 'column' }}>
                  <Link to={{ pathname: '/view-survey', state: item.name }}>
                    <p
                      style={{
                        color: 'rgba(29, 80, 141, 1)',
                        float: 'right',
                        fontSize: '13px',
                        flex: 'column',
                        marginTop: '-2.9rem',
                        paddingRight: '1rem',
                        textTransform: 'capitalize',
                        textDecoration: 'underline'
                      }}
                    >
                      {t('Assets.Details.Details')}
                    </p>
                  </Link>
                  <section style={{ display: 'flex', float: 'right' }}>
                    {/* <img src={Red} alt="Red" style={{ marginTop: '-2.2rem', marginRight: '5px' }} /> */}
                    <p
                      style={{
                        color: 'rgba(146, 155, 171, 1)',
                        float: 'right',
                        marginTop: '-1.5rem',
                        fontSize: '10px',
                        paddingRight: '1rem',
                      }}
                    >
                      {item.men_status}
                    </p>
                  </section>
                </section>
              </div>
            );
          })}

        <IonInfiniteScroll onIonInfinite={(e: CustomEvent<void>) => searchNext(e)}>
          <IonInfiniteScrollContent
            loadingSpinner="crescent"
            loadingText="Loading more data..."
          ></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonToolbar></IonToolbar>
        {!isLoading && data.dataSet.length === 0 ? (
          <IonRow>
            <NoRecords width="60%" smallText={false} />
          </IonRow>
        ) : (
          <div></div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default SurveyShow;
