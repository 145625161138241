import { Capacitor } from '@capacitor/core';
import { Geolocation } from '@capacitor/geolocation';
import type { RefresherEventDetail } from '@ionic/core/components';
import {
  IonContent,
  IonCard,
  IonPage,
  IonItem,
  IonGrid,
  IonRow,
  IonCol,
  IonRefresher,
  IonRefresherContent,
  useIonRouter,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon,
  useIonToast,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { analytics, chevronDownCircleOutline, filterOutline } from 'ionicons/icons';
import { useContext, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Map, { FullscreenControl, GeolocateControl, Marker, NavigationControl } from 'react-map-gl';
import Plot from 'react-plotly.js';

import AssetIconBulb from '../assets/icons/mapAssetIcon.png';
import Satellite from '../assets/icons/satellite.png';
import Street from '../assets/icons/street.png';
import urgent from '../assets/tabler_urgent.svg';
import axios from '../axios';
import AssetOnMapModal from '../components/AssetOnMapModal';
import Header from '../components/Header';
import ScanButton from '../components/ScanButton';
import { AppContext } from '../providers/AppContext';
import { constant, getDashboard, } from '../utils';
import LocationService from '../utils/locationService';


import 'mapbox-gl/dist/mapbox-gl.css';
import 'leaflet/dist/leaflet.css';



const Home: React.FC = () => {

  const { t } = useTranslation();
  const router = useIonRouter();
  const [present] = useIonToast();

  let mapRef: any = useRef();

  const [watchId, setWatchId] = useState<any>();

  const { state, dispatch } = useContext(AppContext);

  // const [gpsHome, setGpsHome] = useState({
  //   lat: 0,
  //   lon: 0
  // })
  const [style, setStyle] = useState(localStorage.getItem('style') || 'mapbox://styles/mapbox/satellite-v9');
  const [styleToggleVar, setStyleToggleVar] = useState(localStorage.getItem('style') === 'mapbox://styles/mapbox/satellite-v9' || localStorage.getItem('style') === null ? true : false);

  const [filter, setFilter] = useState('');
  const [filterList, setFilterList] = useState([]);
  const goTo = (route: string) => {
    router.push(route);
  };

  const [full, setFull] = useState(false);

  const [assetId, setAssetId] = useState('')
  const [assetLayoutModal, setAssetLayoutModal] = useState(false)


  const getDashboardData = async (status: any) => {
    const res: any = await getDashboard(status, '');
    dispatch({ type: 'dashboard', payload: res || {} });
    dispatch({ type: 'map', payload: res?.geo_map_data || [] });
  }

  useEffect(() => {
    getDashboardData('');
    checkPermissions();
    getProfilePhoto();
    getFilterDropdown();
    if (localStorage.getItem('style') === 'mapbox://styles/mapbox/satellite-v9') {
      setStyleToggleVar(true);
    }

    ["fullscreenchange", "webkitfullscreenchange", "mozfullscreenchange", "msfullscreenchange"].forEach(
      eventType => document.addEventListener(eventType, checkExpand, true)
    );
  }, []);

  const checkExpand = () => {
    setAssetLayoutModal(false);
    if (document.fullscreenElement) {
      setFull(true);
    }
    else {
      setFull(false);
    }
  }

  // Handle the back button press
  useEffect(() => {
    const handleBackButton = (event: any) => {
      if (event?.target?.fullscreen) {
        document.exitFullscreen();
      }
    };
    document.addEventListener('ionBackButton', handleBackButton);
    return () => {
      document.removeEventListener('ionBackButton', handleBackButton);
    };
  }, []);

  function doRefresh(event: CustomEvent<RefresherEventDetail>) {
    getDashboardData('');
    setTimeout(() => {
      event.detail.complete();
    }, 2000);
  }

  const getFilterDropdown = async () => {
    const url = '/method/preflet_asset.api.get_control_location_dropdown';
    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };
    axios(config)
      .then((response: any) => {
        setFilterList(response?.data?.message);
      })
      .catch((e: any) => {
        console.log('error', e);
      });
  };


  /* Check if Object in empty */

  const isEmpty = (obj: any) => {
    // null and undefined are "empty"
    if (obj == null) return true;

    // Assume if it has a length property with a non-zero value
    // that that property is correct.
    if (obj.length > 0) return false;
    if (obj.length === 0) return true;

    // If it isn't an object at this point
    // it is empty, but it can't be anything *but* empty
    // Is it empty?  Depends on your application.
    if (typeof obj !== 'object') return true;

    // Otherwise, does it have any properties of its own?
    // Note that this doesn't handle
    // toString and valueOf enumeration bugs in IE < 9
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) return false;
    }

    return true;
  };

  const getProfilePhoto = () => {
    const url = '/method/preflet_asset.api.get_profile_details';

    const config: any = {
      method: 'get',
      url,
      headers: {
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
    };

    try {
      axios(config).then((response) => {
        if (response.data.data.user_image)
          localStorage.setItem(constant.PROFILE_PHOTO, response.data.data.user_image);
      });
    } catch (error: any) {
      console.log(error);
    }
  }

  const openAssets = (id: any) => {
    if (id) {
      setAssetId(id);
      setAssetLayoutModal(!assetLayoutModal);
      setAssetLayoutModal(true)
    }
  }

  const colors = ['#7AABE5', '#FF9088', '#FCB454', '#61DBA7'];

  const toggleStyle = (val: boolean) => {
    setStyleToggleVar(val);
    setStyle(val ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/streets-v12');
    localStorage.setItem('style', val ? 'mapbox://styles/mapbox/satellite-v9' : 'mapbox://styles/mapbox/streets-v12');
  }

  const checkPermissions = async () => {
    const hasPermission = await LocationService.checkGPSPermission();
    if (hasPermission) {
      if (Capacitor.isNativePlatform()) {
        const canUseGPS = await LocationService.askToTurnOnGPS();
        postGPSPermission(canUseGPS);
      }
      else {
        postGPSPermission(true);
      }
    }
    else {
      const permission = await LocationService.requestGPSPermission();
      if (permission === 'CAN_REQUEST' || permission === 'GOT_PERMISSION') {
        if (Capacitor.isNativePlatform()) {
          const canUseGPS = await LocationService.askToTurnOnGPS();
          if (canUseGPS)
            postGPSPermission(canUseGPS);
          else
            present({
              message: t('Utils.gpsDenied'),
              color: 'danger',
              duration: 4000,
            });
        }
        else {
          postGPSPermission(true);
        }
      }
      else {
        present({
          message: t('Utils.gpsDenied'),
          color: 'danger',
          duration: 4000,
        });
      }
    }
  }

  const postGPSPermission = async (canUseGPS: boolean) => {
    if (canUseGPS) {
      watchPosition();
    }
  }

  const watchPosition = async () => {
    try {
      const w_id = await Geolocation.watchPosition({}, (position: any, err: any) => {
        if (err) {
          present({
            message: t('Utils.gpsError'),
            color: 'danger',
            duration: 4000,
          });
          return;
        }
        // const temp = {
        //   lat: position.coords.latitude.toFixed(3),
        //   lon: position.coords.longitude.toFixed(3)
        // }
        // setGpsHome(temp)
      })
      setWatchId(w_id);
      clearWatch();
    }
    catch (err) { console.log('err', err) }
  }

  const clearWatch = () => {
    try {
      if (watchId != null) {
        Geolocation.clearWatch({ id: watchId });
      }
    } catch (error: any) {
      console.log('error', error);
    }
  }

  const filterByCl = (status: any) => {   /* Filter by control location */
    setFilter(status);
    if (status) getDashboardData(status);
  }

  const FlyToLocation = () => {
    mapRef.flyTo({
      center: [state.map[0].lon, state.map[0].lat],
      zoom: 10,
      essential: true // this animation is considered essential with respect to prefers-reduced-motion
    });

  }

  return (
    <IonPage>
      <Header enableBackButton="" title="" />
      <IonContent>
        <IonItem className="heading" style={{ marginTop: '0.2rem' }}>
          <p className="ion-margin">{t('Home.Heading.Dashboard')}</p>
        </IonItem>
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent
            pullingIcon={chevronDownCircleOutline}
            pullingText={t('Utils.pullRefresh')}
            refreshingSpinner="circles"
            refreshingText={t('Utils.refreshing')}
          ></IonRefresherContent>
        </IonRefresher>
        <div className='data-list' style={{ display: 'flex', justifyContent: 'space-between' }}>
          {filter ? <p slot='end' style={{ fontSize: '17px', marginLeft: '15px', color: 'blueviolet', textDecoration: 'underline' }} onClick={() => filterByCl('')}>Clear</p> : <p></p>}
          <IonItem lines='none'>
            <IonIcon slot='end' style={{ fontSize: '15px' }} src={filterOutline} />
            <IonSelect
              slot='end'
              value={filter}
              placeholder={t('Home.Heading.Filter')}
              onIonChange={(e: any) => filterByCl(e.detail.value)}
            >{filterList.map((item: any, index) => (
              <IonSelectOption key={index} value={item.control_location}>{item.control_location}</IonSelectOption>
            ))}
            </IonSelect>
          </IonItem>
        </div>
        <IonGrid className='home-metric-grid'>
          <IonRow style={{ marginBottom: '1rem' }}>
            <IonCol size="6">
              <div
                onClick={() => goTo('assets')}
                className="home-metric-cards"
                id="green"
                style={{ border: '2px solid rgba(22, 201, 141, 1)' }}
              >
                <div className='metric-container'>
                  <h1>
                    {state.dashboard.need_installation_or_replacement_assets
                      ? state.dashboard.need_installation_or_replacement_assets
                      : '0'}
                  </h1>
                  <p className="home-metric-title">{t('Home.Card1.Cont')}</p>
                </div>
              </div>
            </IonCol>
            <IonCol size="6">
              <div
                onClick={() => goTo('serviceHistory')}
                className="home-metric-cards"
                id="red"
                style={{ border: '2px solid #FD7689' }}
              >
                <div className='metric-container'>
                  <h1>{state.dashboard.pending_repairs ? state.dashboard.pending_repairs : '0'}</h1>
                  <p className="home-metric-title">{t('Home.pending_repairs')}</p>
                </div>
              </div>
            </IonCol>
          </IonRow>
          <IonRow style={{ marginBottom: '1rem' }}>
            <div
              onClick={() => goTo('maintenanceHistory')}
              className="home-metric-cards"
              id="green"
              style={{ border: '2px solid rgba(15, 84, 169, 1)' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='metric-container'>
                  <h1>{state.dashboard.total_overdue_maintenance ? state.dashboard.total_overdue_maintenance : '0'}</h1>
                  <p className="home-metric-title">{t('Home.total_overdue_maintenance')}</p>
                </div>
                <div style={{ marginRight: '1rem' }} >
                  <img style={{ width: '45px', margin: 'auto', display: 'block' }} src={urgent} alt="Logo"></img>
                  <p className="home-metric-title">{t('Home.Card4.SC')}</p>
                </div>
              </div>
            </div>
          </IonRow>
          <IonRow style={{ marginBottom: '1rem' }}>
            <div
              className="home-metric-cards"
              id="red"
              style={{ border: '2px solid rgba(15, 84, 169, 1)' }}
            >
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div className='metric-container'>
                  <h1>{state.dashboard.energy_consumption ? state.dashboard.energy_consumption : '0'}</h1>
                  <p className="home-metric-title">{t('Home.energy_consumption')}</p>
                </div>
                <div style={{ marginRight: '1rem' }} className="energy-label">
                  <p className="energy-text">{t('Utils.energy')}</p>
                </div>
              </div>
            </div>
          </IonRow>
          <IonRow>
            <IonCard className="ion-text-center" style={{ width: '100%', height: '300px' }}>
              <IonCardHeader>
                <IonCardTitle className="cardtitle">{t('Home.map.map')}</IonCardTitle>
              </IonCardHeader>
              <IonCardContent className='ion-no-padding'>
                {styleToggleVar ?
                  <img src={Street} alt='street-icon' className="street-icon" onClick={() => toggleStyle(!styleToggleVar)} /> :
                  <img src={Satellite} alt='satellite-icon' className="street-icon" onClick={() => toggleStyle(!styleToggleVar)} />
                }
                <Map
                  ref={(e: any) => {
                    mapRef = e;
                  }}
                  onError={(error: any) => {
                    console.log(error)
                  }}
                  initialViewState={{
                    latitude: 38.76246465794007,
                    longitude: -9.11688254220811,
                    zoom: 6,
                  }}
                  onRender={(event) => event.target.resize()}
                  fadeDuration={0}
                  mapStyle={style}
                  id="map"
                  mapboxAccessToken='pk.eyJ1IjoicHJlZmxldCIsImEiOiJjazdld3VqbGgyZXV5M2RvZ2VvZGFsb2YwIn0.KGf08mMx5U-RW45b7ROq8Q'
                >
                  <GeolocateControl
                    position="top-left"
                    onError={(error: any) => {
                      console.log(error)
                    }}
                    trackUserLocation={true}
                    showAccuracyCircle={true}
                    showUserHeading={true}
                    showUserLocation={true}
                    positionOptions={{ enableHighAccuracy: true }}
                  // onGeolocate={
                  //   (position: any) => {
                  //     setGpsHome({
                  //       lat: position.coords.latitude,
                  //       lon: position.coords.longitude
                  //     })
                  //   }} 
                  />
                  <FullscreenControl containerId='full-screen' key={'id-full'} position="top-left" />
                  <NavigationControl position="top-left" showCompass={false}
                  ></NavigationControl>

                  {state.map.length && state.map.map((m: any, index: number) => {
                    return (
                      <div key={index + '-map'}>
                        <Marker
                          key={index + 1}
                          latitude={Number(m.lat)}
                          longitude={Number(m.lon)}
                          color={m.color}
                          style={{ cursor: 'pointer' }}
                          scale={0.5}
                          onClick={() => {
                            openAssets(m.name)
                          }}
                        >
                          <div style={{ width: '1.55rem', height: '1.55rem', backgroundColor: `${m.color}`, borderRadius: '2rem', padding: '6.5px', boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px' }}>
                            <div style={{ width: '.75rem', height: '.75rem', backgroundColor: `${m.assetStatus}`, borderRadius: '2rem' }}></div>
                          </div>
                        </Marker>
                      </div>
                    )
                  })}
                  <img src={AssetIconBulb} onClick={() => FlyToLocation()} className="fly-to-button-full-screen"
                    alt="icon" />
                  {assetLayoutModal && full ?
                    <AssetOnMapModal
                      fullScreen={true}
                      id={assetId} key={'modal-map-' + assetId} /> : ''}
                </Map>
              </IonCardContent>
            </IonCard>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonCard className="ion-text-center">
                <IonCardHeader>
                  <IonCardTitle className="cardtitle">{t('Home.map.donut')}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {isEmpty(state.dashboard.maintenance_count.count) ? (
                    <div>
                      <IonIcon style={{ fontSize: '5rem' }} src={analytics} />
                      <p>{t('Utils.noGraph')}</p>
                    </div>
                  ) : (
                    <Plot
                      style={{ display: 'flex', justifyContent: 'center' }}
                      data={[
                        {
                          labels: state.dashboard.maintenance_count.labels,
                          values: state.dashboard.maintenance_count.count,
                          type: 'pie',
                          hole: 0.5,
                          marker: { colors: ['#FF9088', '#7AABE5', '#FCB454'] },
                        },
                      ]}
                      layout={{
                        yaxis: { fixedrange: true },
                        xaxis: { fixedrange: true },

                        margin: { r: 0, t: 0, l: 0, b: 0 },
                        font: {
                          family: 'Nunito Sans',
                        },
                        width: 300,
                        autosize: true,
                      }}
                      config={{
                        displayModeBar: false,
                      }}
                    />
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonCard className="ion-text-center">
                <IonCardHeader>
                  <IonCardTitle className="cardtitle">{t('Home.map.bar')}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {isEmpty(state.dashboard.repair_req_count.x) ? (
                    <div>
                      <IonIcon style={{ fontSize: '5rem' }} src={analytics} />
                      <p>{t('Utils.noGraph')}</p>
                    </div>
                  ) : (
                    <Plot
                      data={[
                        {
                          y: state.dashboard.repair_req_count.x,
                          x: state.dashboard.repair_req_count.y,
                          type: 'bar',
                          // text: state.dashboard.repair_req_count.y,
                          textposition: 'auto',
                          marker: {
                            color: colors,
                          },
                        },
                      ]}
                      layout={{
                        yaxis: {
                          fixedrange: true,
                          automargin: true,
                        },
                        xaxis: { fixedrange: true, automargin: true },
                        margin: { r: 5, t: 10, l: 5, b: 10 },
                        font: {
                          family: 'Nunito Sans',
                        },
                        width: 300,
                        autosize: true,
                      }}
                      config={{
                        displayModeBar: false,
                      }}
                    />
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
          <IonRow>
            <IonCol size="12">
              <IonCard className="ion-text-center">
                <IonCardHeader>
                  <IonCardTitle className="cardtitle">{t('Home.map.task')}</IonCardTitle>
                </IonCardHeader>
                <IonCardContent>
                  {isEmpty(state?.dashboard?.tasks_count?.count) ? (
                    <div>
                      <IonIcon style={{ fontSize: '5rem' }} src={analytics} />
                      <p>{t('Utils.noGraph')}</p>
                    </div>
                  ) : (
                    <Plot
                      data={[
                        {
                          labels: state.dashboard.tasks_count.labels,
                          values: state.dashboard.tasks_count.count,
                          type: 'pie',
                          marker: { colors: colors.reverse() },
                        },
                      ]}
                      layout={{
                        yaxis: { fixedrange: true },
                        xaxis: { fixedrange: true },
                        legend: {
                          x: 0.1,
                          y: -0.2,
                          xanchor: 'center',
                          orientation: 'h',
                        },
                        margin: { r: 0, t: 0, l: 0, b: 0 },
                        font: {
                          family: 'Nunito Sans',
                        },
                        width: 300,
                        autosize: true,
                      }}
                      config={{
                        displayModeBar: false,
                      }}
                    />
                  )}
                </IonCardContent>
              </IonCard>
            </IonCol>
          </IonRow>
        </IonGrid>

        <ScanButton isAssetPage={true} />
        <br />
      </IonContent>
      {assetLayoutModal && !full ?
        <AssetOnMapModal fullScreen={false} id={assetId} key={'modal-map-' + assetId} /> : ''}
    </IonPage>
  );
};

export default Home;
