import { Network } from '@capacitor/network';
import {
  IonHeader,
  IonItem,
  IonGrid,
  IonCol,
  IonRow,
  IonButton,
  IonButtons,
  IonMenuButton,
  IonToolbar,
  IonTitle,
  IonAlert,
  useIonRouter,
  IonIcon,
  useIonToast,
  IonSpinner,
  useIonAlert,
} from '@ionic/react';
import { arrowBack, prism } from 'ionicons/icons';
import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation } from 'react-router-dom';
import TimeAgo from 'timeago-react';

import packageJson from '../../package.json';
import LogOut from '../assets/Logout.svg';
import MenuIcon from '../assets/Menu.svg';
import RedDot from '../assets/Red.svg';
import Sync from '../assets/Sync.svg';
import defaultavatar from '../assets/avatardefault.svg';
import calender from '../assets/calender.svg';
import GreenDot from '../assets/green.svg';
import Homeicon from '../assets/homeicon.svg';
import bulb from '../assets/icons/bulb.svg';
import LogoWhite from '../assets/icons/logo-white.png';
import map from '../assets/icons/map.svg';
import model937 from '../assets/model937.svg';
import toolkit from '../assets/toolkit.svg';
import axios from '../axios';
import i18n from '../i18n';
import { createSurveyFields } from '../models/createSurveyFields';
import { AppContext } from '../providers/AppContext';
import { constant, deleteStorage, getStorage, setStorage } from '../utils';



const Header: React.FC<{ enableBackButton: string; title: string }> = ({ enableBackButton = '', title = '' }) => {
  const { dispatch, state } = useContext(AppContext);
  const [isOnline, setIsOnline] = useState(Boolean);
  const location = useLocation();


  const router = useIonRouter();

  const [offlineDataCount, setOfflineDataCount] = useState(0);
  const [failed, setFailed] = useState(0);
  const [isSync, setIsSync] = useState(false);
  const [successful, setSuccessful] = useState(0);
  const [requestCount, setRequestCount] = useState(0);
  const [isShowSyncMessage, setIsShowSyncMessage] = useState(false);
  const [showOfflineAlert, setShowOfflineAlert] = useState(false);

  const [showHeadLoader, setShowHeadLoader] = useState(false);

  const [currentPath, setCurrentPath] = useState('');

  const { t } = useTranslation();
  const [present] = useIonToast();
  const [presentAlert] = useIonAlert();

  // const [popoverState, setShowPopover] = useState({ showPopover: false, event: undefined });
  const [showPopover, setShowPopover] = useState(false);

  useEffect(() => {
    setCurrentPath(location.pathname);
    setIsOnline(true);

    (async () => {
      const internetStatus = await Network.getStatus();
      setIsOnline(internetStatus.connected);
      uploadOfflineData(internetStatus.connected);

      Network.addListener('networkStatusChange', (status) => {
        setIsOnline(status.connected);
      });
    })();
  }, [location]);



  const createLogs = async (data: any) => {
    const body = JSON.stringify({
      user_id: localStorage.getItem('email'),
      meta1: JSON.stringify(data),
      version: packageJson?.version
    });

    const configuration: any = {
      method: 'post',
      url: '/resource/Offline sync logs',
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
      },
      data: body,
    };
    try {
      await axios(configuration);
    } catch (error: any) {
      console.log(error);
    }
  };

  const getLastUpdated = (): any => {
    try {
      const last: any = localStorage.getItem(constant.LAST_UPDATED_TIME);
      return last;
    } catch (e: any) {
      console.log(e);
      const raw = JSON.parse(e?.response?.data?._server_messages);
      console.log(JSON.parse(raw[0])?.message);
      createLogs({
        message: JSON.stringify(JSON.parse(raw[0])?.message || e),
        type: 'error in getLastUpdated',
        data: JSON.stringify(localStorage.getItem(constant.LAST_UPDATED_TIME)),
      })
      return null;
    }
  };

  const setLastUpdated = (): any => {
    localStorage.setItem(constant.LAST_UPDATED_TIME, new Date().toJSON());
  };


  const uploadOfflineData = async (online: boolean = isOnline) => {
    if (!online) {
      setShowOfflineAlert(true);
      return;
    }

    let dataLength = 0;
    let data: any[] = [];
    setOfflineDataCount(0);
    setRequestCount(0);
    setFailed(0);
    setSuccessful(0);
    const completedIndexes = [];
    try {
      data = await getStorage(constant.ALL_DATA_SYNC_TABLE, []);
      dataLength = data.length || 0;
      if (dataLength > 0) {
        setOfflineDataCount(dataLength);
        setIsSync(true);
      }

      // now start each object from the key data
      for (let index = 0; index < dataLength; index++) {
        const currentData = await getStorage(data[index]);
        if (!currentData) {
          completedIndexes.push(data[index]);
        }

        if (currentData && currentData['doc_type'] === 'Survey') {
          const formData = new FormData();
          for (const item in createSurveyFields) {
            const temp: any = createSurveyFields[item];
            if (temp.type === 'file') {
              try {
                setShowHeadLoader(true);
                const tempKey = await getStorage(temp.field + '-' + currentData['si_survey_code']);
                if (tempKey) {
                  const response = await fetch(tempKey);
                  const blob = await response.blob();
                  formData.append(
                    'file',
                    new File(
                      [blob],
                      `${currentData['si_survey_code']}-${new Date().getTime()}.${blob.type.slice(
                        blob.type.indexOf('/') + 1
                      )}`,
                      {
                        type: blob.type,
                      }
                    )
                  );
                  formData.append('is_private', '0');
                  formData.append('folder', 'Home');
                  formData.append('doctype', 'Survey');
                  const configfile: any = {
                    method: 'post',
                    url: '/method/upload_file',
                    headers: {
                      'Content-Type': 'application/json',
                      Accept: 'application/json',
                      Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
                    },
                    data: formData,
                  };
                  const res = await axios(configfile);
                  currentData[temp.field] = res.data.message.file_url;
                  formData.delete('file');
                  deleteStorage(temp.field + '-' + currentData['si_survey_code']);
                }
              } catch (error: any) {
                currentData[temp.field].value = '';
                console.log(error);
                const raw = JSON.parse(error?.response?.data?._server_messages);
                console.log(JSON.parse(raw[0])?.message);
                present({
                  message: JSON.parse(raw[0])?.message,
                  color: 'danger',
                  duration: 2000,
                });

                createLogs({
                  message: JSON.stringify(JSON.parse(raw[0])?.message || error),
                  type: 'error in uploadOfflineData',
                  data: currentData,
                })
              } finally {
                setShowHeadLoader(false);
              }
            }
          }
        } else if (currentData && currentData['doc_type'] === 'createAsset' && currentData?.data?.attachment?.src) {
          const formData = new FormData();
          try {
            const response = await fetch(currentData?.data.attachment?.src);
            const blob = await response.blob();
            formData.append(
              'file',
              new File([blob], `${new Date().getTime()}.${currentData?.data?.attachment?.format}`, {
                type: `image/${currentData?.data?.attachment?.format}`,
              })
            );
            formData.append('is_private', '0');
            formData.append('folder', 'Home');
            formData.append('doctype', 'Asset');
            const configuration: any = {
              method: 'post',
              url: '/method/upload_file',
              headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
              },
              data: formData,
            };
            const res = await axios(configuration);
            currentData.data.attachment = res.data.message.file_url;
            formData.delete('file');
          } catch (error: any) {
            console.log(error);
          }
        }

        // upload rest data
        const config: any = {
          method: 'post',
          url: currentData.base_url_offline_sync,
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Basic ${localStorage.getItem('hyprism_login')}`,
          },
          data: JSON.stringify(currentData),
        };
        try {
          setShowHeadLoader(true);
          const response = await axios(config);
          if (currentData['doc_type'] === 'createAsset') {
            present({
              message: 'Asset uploaded!',
            })
          } else {
            present({
              message:
                response?.data?.data?.doctype +
                (response?.data?.data?.si_survey_code ? ' ' + response.data.data.si_survey_code : '') +
                ' uploaded!',
              color: 'secondary',
              duration: 2000,
            });
          }
          setSuccessful((prevSuccessful) => prevSuccessful + 1);
          // setOfflineDataCount(dataLength - 1);
          // now delete the data
          await deleteStorage(data[index]);
          // now delete the key from main array
          completedIndexes.push(data[index]);
        } catch (e: any) {
          setFailed((prevFailure) => prevFailure + 1);
          const raw = JSON.parse(e?.response?.data?._server_messages);
          console.log(JSON.parse(raw[0])?.message);
          createLogs({
            message: JSON.stringify(JSON.parse(raw[0])?.message || e),
            type: 'error in uploadOfflineData',
            data: currentData,
          })
        }
        setRequestCount((prevRequestCount) => prevRequestCount + 1);
      }
    } catch (e: any) {
      console.log(e);
      const raw = JSON.parse(e?.response?.data?._server_messages);
      console.log(JSON.parse(raw[0])?.message);
      createLogs({
        message: JSON.stringify(JSON.parse(raw[0])?.message || e),
        type: 'error in uploadOfflineData',
        data: await getStorage(constant.ALL_DATA_SYNC_TABLE, [])
      })
    } finally {
      setShowHeadLoader(false);
      // set the last updated time if there is data in storage
      if (dataLength !== 0) {
        setIsShowSyncMessage(true);
        setLastUpdated();
        // delete all extra keys
        completedIndexes.forEach((comp) => {
          data = data.splice(comp, 1);
          return null;
        });
        // set the last main list
        setStorage(constant.ALL_DATA_SYNC_TABLE, data);
      }
    }
  };


  const getImg = () => {
    if (localStorage.getItem(constant.PROFILE_PHOTO))
      return `${localStorage.getItem('baseUrl')?.replace('/api', '')}${localStorage.getItem(constant.PROFILE_PHOTO)}`;
    else return defaultavatar
  }

  const navigationFunc = (path: string) => {
    router.push(path);
  };

  // Check if the current tab is active
  const isActiveTab = (tabPath: any) => {
    return tabPath === currentPath;
  };

  const navigateBack = (enableBackButton: any) => {
    if (enableBackButton === 'goBack') return router.goBack();
    else return router.push(enableBackButton);
  };

  return (
    <IonHeader
      style={{
        background: 'radial-gradient(122.27% 198.92% at -22.27% -27.38%, rgb(14 85 169) 0%, rgb(21, 70, 160) 100%)',
        boxShadow: '0px 16px 36px -16px rgba(21, 70, 160, 0.5)',
      }}
    >
      {enableBackButton ? (
        <IonToolbar color="radial-gradient(122.27% 198.92% at -22.27% -27.38%, rgb(14 85 169) 0%, rgb(21, 70, 160) 100%)">
          <IonButtons slot="start" style={{ zIndex: '1' }} onClick={() => navigateBack(enableBackButton)}>
            <IonIcon
              src={arrowBack}
              className="pointer"
              style={{ paddingLeft: '10px', fontSize: '25px', color: 'white' }}
            ></IonIcon>
          </IonButtons>
          <IonTitle className="preview-nav-title" style={{ color: 'white' }}>
            {title}
          </IonTitle>
        </IonToolbar>
      ) : (
        <div>
          <div
            className="zindex"
            style={{
              background: 'radial-gradient(122.27% 198.92% at -22.27% -27.38%, #0a5eaf 0%, #134ba3 100%)',
              position: 'fixed',
              width: '100%',
              top: '0',
            }}
          >
            <IonGrid style={{ padding: '0px' }}>
              <IonRow className="ion-justify-content-around">
                <IonCol size="4">
                  <IonMenuButton className="side-menu-button">
                    <img style={{ marginRight: 'auto' }} src={MenuIcon} alt="menu" />
                  </IonMenuButton>
                </IonCol>

                <IonCol size="4" style={{ margin: 'auto' }}>
                  <div style={{ textAlign: 'center', margin: 'auto' }}>
                    {/* <p style={{ color: 'rgba(255, 255, 255, 1)', fontSize: '1.2rem' }}>dfs</p> */}
                    <img className="w-3" src={LogoWhite} alt="hyprism" />
                  </div>
                </IonCol>

                <IonCol size="4">
                  <div style={{ float: 'right', display: 'flex', marginRight: '10px' }}>
                    {showPopover && <div className='modal-popover-profile'>
                      <div className='list-items-popover'>
                        <IonItem className="ion-no-padding">
                          <NavLink
                            to="../profile"
                            className="popovercont"
                            style={{ color: '#1D508D', display: 'flex', alignItems: 'center', justifyContent: 'flex-start' }}
                            onClick={() => setShowPopover(false)}
                          >
                            <img
                              style={{ width: '2rem', marginLeft: '1rem', marginRight: '.5rem', marginTop: '2px', borderRadius: '999px', height: '2rem', objectFit: 'cover' }}
                              src={getImg()}
                              alt="Profile"
                            />
                            <p style={{ marginLeft: '5px' }}>{t('Header.Avatar.Profile')}</p>
                          </NavLink>
                        </IonItem>
                        <IonItem lines='none' className="ion-no-padding">
                          <div
                            className="popovercont pointer"
                            style={{ color: '#1D508D' }}
                          >
                            <img
                              style={{ width: '2rem', marginLeft: '1rem', marginRight: '.5rem' }}
                              src={LogOut}
                              alt="Profile"
                            ></img>
                            <p
                              onClick={() => {
                                setShowPopover(false);
                                const message = t('Utils.are_you_sure');
                                presentAlert(message, [
                                  {
                                    text: t('Utils.no'),
                                    role: 'cancel',
                                    cssClass: 'secondary'
                                  },
                                  {
                                    text: t('Utils.yes'),
                                    cssClass: 'danger',
                                    handler: () => {
                                      dispatch({ type: 'logOut' });
                                    },
                                  },
                                ]);
                              }}
                              style={{ marginLeft: '5px' }}
                            >
                              {t('Header.Avatar.LogOut')}
                            </p>
                          </div>
                        </IonItem>
                      </div>
                    </div>
                    }
                    {/* <IonPopover
                      id="popover"
                      className="my-custom-class1"
                      isOpen={showPopover}
                      onDidDismiss={() => setShowPopover(false)}
                    >
                      
                    </IonPopover> */}
                    <div
                      style={{ border: '2px solid white', borderRadius: '999px', padding: '3px', width: '3rem', height: '3rem' }}
                      onClick={() => {
                        setShowPopover(!showPopover);
                      }}
                    >
                      <img src={getImg()} alt="my account"
                        style={{ height: '100%', width: '100%', borderRadius: '999px', cursor: 'pointer', objectFit: 'cover' }}
                      />
                    </div>
                  </div>
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>

          <div className="ion-margin" style={{ paddingTop: '9vh' }}>
            <IonGrid>
              <IonRow>
                <IonCol size="6">
                  <h6
                    style={{ color: 'rgba(255, 255, 255, 1)', opacity: '0.5', marginTop: '0px', marginLeft: '0.5rem' }}
                  >
                    {t('Header.Greet')},{' '}
                    {localStorage.getItem('fullName')
                      ? localStorage.getItem('fullName')
                      : localStorage.getItem('email')}
                    !
                  </h6>
                </IonCol>
                <IonCol
                  size="6"
                  style={{ display: 'flex', justifyContent: 'flex-end', float: 'right', marginRight: '0rem' }}
                >
                  <img
                    src={isOnline ? GreenDot : RedDot}
                    alt="network color"
                    style={{ marginTop: '-0.85rem', marginRight: '8px', width: '10px' }}
                  ></img>
                  <p
                    style={{
                      color: 'rgba(255, 255, 255, 1)',
                      marginTop: '0px',
                      marginRight: '5px',
                      fontWeight: 'lighter',
                      textAlign: 'right',
                    }}
                  >
                    {!isOnline ? t('Header.Offline') : t('Header.Online')}
                  </p>
                </IonCol>
              </IonRow>
              <IonRow style={{ marginTop: '-15px' }}>
                <IonCol size="9">
                  {!isOnline ? (
                    <h6
                      className=""
                      style={{
                        color: 'rgba(255, 255, 255, 1)',
                        marginLeft: '0.5rem',
                        opacity: '0.5',
                        fontSize: 'small',
                      }}
                    >
                      {getLastUpdated() ? t('Header.Update') : null}
                      {getLastUpdated() && (
                        <TimeAgo datetime={getLastUpdated()} locale={i18n.language.toLocaleUpperCase()} />
                      )}
                    </h6>
                  ) : (
                    ''
                  )}
                </IonCol>
                <IonCol
                  size="3"
                  onClick={() => {
                    uploadOfflineData();
                  }}
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    float: 'right',
                    marginRight: '0rem',
                  }}
                >
                  {/* {getOfflineRequestCount.length ? getOfflineRequestCount : null} */}
                  <IonButton
                    color="primary"
                    style={{
                      width: '75px',
                      height: '28px',
                      borderRadius: '30px',
                      backgroundColor: 'rgba(40, 138, 214, 1)',
                    }}
                  >
                    <img src={Sync} alt="sync" style={{ margin: 'auto', paddingRight: '5px' }} />
                    {isSync
                      ? requestCount === 0 && offlineDataCount === requestCount
                        ? t('Header.Sync')
                        : `${requestCount}/${offlineDataCount}`
                      : t('Header.Sync')}
                  </IonButton>
                  <IonAlert
                    isOpen={isShowSyncMessage}
                    onDidDismiss={() => setIsShowSyncMessage(false)}
                    header={t('Utils.sync')}
                    message={`${requestCount}/${offlineDataCount}<br/>${failed} ${t(
                      'Utils.failed'
                    )} <br/> ${successful} ${t('Utils.success')}`}
                    buttons={[
                      {
                        text: t('Utils.okay'),
                        handler: () => {
                          setIsShowSyncMessage(false);
                        },
                      },
                    ]}
                  />
                  <IonAlert
                    isOpen={showOfflineAlert}
                    onDidDismiss={() => setShowOfflineAlert(false)}
                    header={t('Utils.no_internet')}
                    message={t('Utils.no_net_message')}
                    buttons={[
                      {
                        text: t('Utils.okay'),
                        handler: () => {
                          setShowOfflineAlert(false);
                        },
                      },
                    ]}
                  />
                </IonCol>
              </IonRow>
            </IonGrid>
          </div>
          {state.slideOpts.loadSlide ? (
            <div className="slide-responsive-container" id="swiper">
              {state.permissions.home ? (
                <div className={`slider-item ${isActiveTab('/home') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('../home')}>
                  <img src={Homeicon} alt="home" className='slider-icon'></img>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.project ? (
                <div className={`slider-item ${isActiveTab('/project') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('/project')} >
                  <IonIcon icon={prism} className='slider-icon' style={{ color: 'white' }}></IonIcon>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.assets ? (
                <div onClick={() => navigationFunc('/assets')} className={`slider-item ${isActiveTab('/assets') ? 'active-slider-bg' : ''}`}>
                  <img src={bulb} alt="calender" className='slider-icon'></img>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.service ? (
                <div className={`slider-item  ${isActiveTab('/servicehome') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('../servicehome')} >
                  <img src={toolkit} alt="toolkit" className='slider-icon'></img>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.maintenance ? (
                <div className={`slider-item  ${isActiveTab('/maintenanceHome') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('../maintenanceHome')} >
                  <img src={calender} alt="map" className='slider-icon'></img>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.model ? (
                <div className={`slider-item  ${isActiveTab('/model-home') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('../model-home')} >
                  <img src={model937} alt="map" className='slider-icon'></img>
                </div>
              ) : null}
              <div className="bar-line-menu" />
              {state.permissions.survey ? (
                <div className={`slider-item  ${isActiveTab('/survey-home') ? 'active-slider-bg' : ''}`} onClick={() => navigationFunc('/survey-home')} >
                  <img src={map} alt="map" className='slider-icon'></img>
                </div>
              ) : null}
            </div>
          ) : null}
        </div>
      )}
      {showHeadLoader ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            background: 'lightgray',
            fontSize: '15px',
            color: 'darkblue',
          }}
        >
          <p style={{ margin: '0' }}>Uploading</p>{' '}
          <IonSpinner style={{ marginLeft: '0.4rem', margin: '5px' }} name="crescent" />
        </div>
      ) : (
        ''
      )}
    </IonHeader>
  );
};

export default Header;
